import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../../AppPool';
import { lng } from '../../ulanguages/language';
import { getImgAddress, getRouteParamAtIndex } from '../../util/text';
import { getIfIsSetElems, isSet } from '../../util/typeu';
//import LoginBox from '../view_component/LoginBox';
import { getCurrentUrl, isServer } from '../../util/windowUtil';
import SpinnerBox from '../../view_component/SpinnerBox';

import './home.css';
import ContentBannerSetter from '../../view_component/ContentBannerSetter';
import ThreadPreview from "../../view_component/ThreadPreview";
import InfiniteScrollBlock from "../../view_component/InfiniteScrollBlock";
import { serverRegisterFetch } from "../../util/updateManager";
import ThreadPinned from "../../view_component/ThreadPinned";
import ThreadTag from "../../view_component/ThreadTag";
import { getForumThreadPath } from "../../store/AppRoutes";
import { toJS } from "mobx";
import Erro404 from "../../view_component/Erro404";
import ThreadCard from "../../view_component/ThreadCard";
import ThreadMain from "../../view_component/ThreadMain";
import ThreadMain2 from "../../view_component/ThreadMain2";
import WYSIWYGWriter from "../../view_component/WYSIWYGWriter";
import ThreadReplyWriter from "../../view_component/ThreadReplyWriter";
import ThreadComment from "../../view_component/ThreadComment";

let pageSizeGlob = 5;
@observer
class ForumThread extends Component {

  constructor(props) {
    super(props);

    this.state = {currentPage:1,pageSize:pageSizeGlob, lastFetchSize:1};
    serverRegisterFetch(ForumThread.getInitialValues);
  }

  componentDidMount()
  {
    ForumThread.getInitialValues();
  }
  static async getInitialValues()
  {
    let parametersUrl = MyAppPool.cLocation.formatedUrlParameters;
    let highlightedReply=parametersUrl["cId"];
    let p3 = Promise.resolve();
    if (isSet(highlightedReply))
      p3 = MyAppPool.cNeoComu.getReply(highlightedReply);
    

    let threadId = getRouteParamAtIndex(getCurrentUrl(),3);
    if (isSet(threadId))
    {
        let p1 = MyAppPool.cNeoComu.getThread(threadId);
        let p2 = MyAppPool.cNeoComu.getThreadRepliesWithHotRanking(threadId,1,pageSizeGlob);
        return Promise.all([p1,p2]);
    }else
    {
    return Promise.resolve();
}
  }
  componentWillReceiveProps()
  {
    ForumThread.getInitialValues();
  }

  loadNextPage = ()=>{
    let threadId = getRouteParamAtIndex(getCurrentUrl(),3);
    if (isSet(threadId))
    {
        let {lastFetchSize,currentPage,pageSize} = this.state;
        if (lastFetchSize>0)
        {
            return MyAppPool.cNeoComu.getThreadRepliesWithHotRanking(threadId,currentPage+1,pageSize).then(data=>{
                this.setState({lastFetchSize:data.length,currentPage:currentPage+1});
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    }
  }

  render(){
    let parametersUrl = MyAppPool.cLocation.formatedUrlParameters;
    let highlightedReply=parametersUrl["cId"];
    let showHighlightedReply=false;
    if (isSet(highlightedReply))
    {
      if (isSet(MyAppPool.session.neocomu.replies[highlightedReply]))
        showHighlightedReply=true;
    }
    

    let {pageSize,currentPage} = this.state;
    let threadId = Number(getRouteParamAtIndex(getCurrentUrl(),3));
    if (isNaN(threadId))
    {
        console.log("Thread IS NaN");
        return <Erro404 />
    }
    let sectionThreadIDPages = getIfIsSetElems(MyAppPool.session.neocomu.repliesToThread,[""+threadId,""+pageSize]);
    
    let allPages=[];
    if (isSet(sectionThreadIDPages)) 
    {
        allPages = Object.values(sectionThreadIDPages).flat();
    }
    let threadData = getIfIsSetElems(MyAppPool.session.neocomu.threads,[""+threadId]);

    if (!isSet(threadData))
    {
        return <></>
    }
    const { title, content, datecreated, repliesAmount, views,isNSFW,isInappropriate,isSpoiler } = threadData.thread.properties;

    let meta = {title:lng('Forum')+" "+"Thread"+' | NANI',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    /*
    <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                {allPages.map((elemId,index)=>{
                let elem = MyAppPool.session.neocomu.threads[elemId];
                return  <ThreadPreview key={index} thread={elem} /> 
                })}
                </InfiniteScrollBlock>*/
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div >
            <div  className=' contentArea'>
            <ContentBannerSetter />
            <div className="threadsSection">
            
                <h2>/{title}</h2>
                
                <div style={{marginTop:"15px"}}>
                  <ThreadMain2
                  ready={true}
                  thread= {threadData}
                    currentUserId={0}
                    currentUserRoles={['guest']}
                  />
                </div>
                
                <ThreadReplyWriter threadId={threadId} pageSize={pageSizeGlob} />
                <div className="uk-card uk-card-default" style={{"padding":"16px","paddingTop":"8px"}}>
                
                {showHighlightedReply?
                <div style={{"marginBottom":'10px'}}>
                <h4 style={{"marginBottom":"0px"}}>{lng('Highlighted Comment')}</h4>
                <ThreadComment key={0} reply={MyAppPool.session.neocomu.replies[highlightedReply]} pageSize={pageSizeGlob}/> 
                <hr className="uk-divider-icon" />
                </div>:<></>
                }
                
                <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                {
                allPages.map((elemId,index)=>{
                let elem = MyAppPool.session.neocomu.replies[elemId];
                return  <ThreadComment key={index} reply={elem} pageSize={pageSizeGlob}/> 
                })}
                </InfiniteScrollBlock>
                </div>

            </div>
            </div>
          </div>
        </div>
      );
}

}

export default ForumThread;
