import React, { Component } from 'react';
import YoutubeVideo from './YoutubeVideo';
import HtmlToReact from 'html-to-react';
const HtmlToReactParser = HtmlToReact.Parser;

const isValidNode = function () {
  return true;
};

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions();

// Regular expression to extract the YouTube video ID from the iframe src URL
const youtubeIdRegex = /youtube\.com\/embed\/([\w-]+)/;

// Processing instructions
const processingInstructions = [
  {
    // Replace <iframe> elements with YouTube sources
    shouldProcessNode: function (node) {
      return (
        node.name === 'iframe' &&
        node.attribs &&
        node.attribs.src &&
        youtubeIdRegex.test(node.attribs.src)
      );
    },
    processNode: function (node, children, index) {
      const match = youtubeIdRegex.exec(node.attribs.src);
      const youtubeId = match ? match[1] : null;
      console.log("ID:",youtubeId);
      return youtubeId
        ? <YoutubeVideo key={index} video={{ytid:youtubeId,title:""}} ready={true}/>
        : null;
    },
  },
  {
    // Default processing for any other nodes
    shouldProcessNode: function (node) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

const htmlToReactParser = new HtmlToReactParser();


export class ThreadContent extends Component {
  render() {
    let {content} = this.props;
const reactComponent = htmlToReactParser.parseWithInstructions(
    content,
  isValidNode,
  processingInstructions
);

return reactComponent?reactComponent:null;
  }
}
