import HtmlToReact from 'html-to-react';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { getResourceRoute, getUserRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon, getUserAvatar } from '../util/icon';
import { lng } from '../ulanguages/language';
import { getImgAddress } from '../util/text';
import MyAppPool from './../AppPool';
import { contentFastTypeToType, contentTypeToFastType, isSet, mobToObj } from './../util/typeu';
import "./ResourceLinkBox.css";
import { getYoutubeThumbnail } from '../util/youtube';
import LinkV2 from './LinkV2';
import { isServer } from '../util/windowUtil';
import { serverRegisterFetch } from '../util/updateManager';

let fetchSample = async (resourceType,resourceId) =>{
  if (!isSet(MyAppPool.session.samples[''+resourceType][''+resourceId]))
    return MyAppPool.cSamples.getSamplesByArrayIdsList(resourceType,[resourceId]);
  return Promise.resolve();
}

@observer
class ResourceLinkBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    serverRegisterFetch(()=>fetchSample(props.resourceType,props.resourceId));
  }
  fetchSample = async () =>{
    return fetchSample(this.props.resourceType,this.props.resourceId);
  }
  componentDidMount()
  {
    this.fetchSample();
  }

  render(){
    let {resourceType,resourceId} = this.props;
    let item = MyAppPool.session.samples[''+resourceType][''+resourceId];
    let capaImg='';
    let title = '...';
    let icon = '';
    let iconText = '';
    let resourceUrl = '';
    if (isSet(item))
    {
      capaImg=item.mainimg;
      if (isSet(item.ytid))
        capaImg=getYoutubeThumbnail(item.ytid);
      title = item.title;
      icon = getRelatedTypeIcon(item.type);
      iconText = lng(item.type);
      resourceUrl = getResourceRoute(resourceType,resourceId,title,'#top');
    }
    let imageClass='listImage';
    let imageGatherParam='x25';
    let imageWidth='40px';
    return (
    <div className="comp_linkresourcebox">
        <div className="uk-flex uk-flex-row "> 
          <div >
            <LinkV2 aria-label={title}  style={{width:imageWidth,float:'right', marginRight:'10px',marginBottom:'3px'}} to={resourceUrl}>
                <LazyLoadImage alt={lng("Capa de")+" "+title} className={imageClass} src={getImgAddress(capaImg,'imgs/defaults/anime.webp',imageGatherParam)}/>
            </LinkV2>
            <div >
                <LinkV2 to={resourceUrl}> <span>{title}</span></LinkV2> 
                 {(icon)? <i className={icon + ' typeIcon'} data-uk-tooltip={iconText}></i> : <></>}
            </div>
          </div>
        </div>
    </div>);
}
}

ResourceLinkBox.propTypes = {
  reactionItem: PropTypes.shape({
  displaytext: PropTypes.string.isRequired,
  publishdate:PropTypes.string.isRequired,
  votes:PropTypes.number.isRequired,
  authorname:PropTypes.string.isRequired,
  authorimg:PropTypes.string.isRequired,
  author:PropTypes.number.isRequired
  }),
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  bigResourceImage:PropTypes.bool
};

ResourceLinkBox.defaultProps = {
  reactionItem: {
    displaytext: '',
    publishdate:'',
    votes:0,
    authorname:'Defaulter',
    authorimg:'',
    author:0,
    resourcetype:0,
    resourceid:0
    },
    currentUserId: 0,
    currentUserRoles: ['guest'],
    bigResourceImage:false
};


export default ResourceLinkBox;
