import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import "./ThreadTag.css";
import LinkV2 from './LinkV2';

class ThreadTag extends Component {
  componentDidMount()
  {

  }

  render(){
    let {name,url,color,backgroundColor} = this.props;
    let style = {};
    if (backgroundColor!=='')
      style.backgroundColor = backgroundColor;
    if (color!=='')
      style.color = color;
    
    return (
      <LinkV2 to={url} > <span style={style} className="uk-badge threadtag" >{name}</span> </LinkV2>
        );
}
}


ThreadTag.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    color:PropTypes.string,
    backgroundColor:PropTypes.string
  };
  ThreadTag.defaultProps = {
    name: "",
    url:"",
    color:"",
    backgroundColor:""
  };


export default ThreadTag;
