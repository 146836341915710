
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { getForumCreateThreadPage, getObraRoute } from '../../store/AppRoutes';
import { getRelatedTypeIcon } from '../../util/icon';
import { lng } from '../../ulanguages/language';
import { contentTypeToFastType, mobToObj } from '../../util/typeu';
import { getCurrentUrl, isServer } from '../../util/windowUtil';
import ChartVerticalBar from '../../view_component/ChartVerticalBar';
import GradeBox from '../../view_component/GradeBox';
import ReactionsCard from '../../view_component/ReactionsCard';
import MyAppPool from './../../AppPool';
import { isMobile } from './../../util/mobile';
import { getAnchorName, getImgAddress, getRouteParamAtIndex } from './../../util/text';
import { isSet } from './../../util/typeu';
import RelatedRowCard from './../../view_component/RelatedRowCard';
import ScrollToTopButtom from './../../view_component/ScrollToTopButton';
import SkeletonBlock from './../../view_component/SkeletonBlock';
import './MusicGeralView.css';
import ColumnCard from '../../view_component/ColumnCard';
import ExternalLinksBox from '../../view_component/ExternalLinksBox';
import ForumThreadsByTagBox from '../../view_component/ForumThreadsByTagBox';
import LinkV2 from '../../view_component/LinkV2';

@observer
class MusicGeralView extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        nameFilter:'',
        relationFilter:'',
        typeFilter:'',
    };        
    this.stringFilter = this.stringFilter.bind(this);
    this.passFilter = this.passFilter.bind(this);
    }

  stringFilter(filter,name)
  {
    if (filter==='')
      return true;
    if (filter==='null' && !isSet(name))
      return true;
    if (!isSet(name))
      return false;
    if (name.toLowerCase().indexOf(filter.toLowerCase())!==-1)
      return true;
    return false;
  }
  passFilter(item)
  {
    let {nameFilter,relationFilter,typeFilter} = this.state;
    return (this.stringFilter(nameFilter,item.title)
    && this.stringFilter(relationFilter,item.reltype)
    && this.stringFilter(typeFilter,item.type)
    )
  }

    render()
    {
      let {current} = this.props;
      let gradeBoxInfo = {grade:0,votes:0};
      let verticalChartValues = [];
      let reactionPosts = [];
      let reactionReady=0;
      let pageId=getRouteParamAtIndex(getCurrentUrl(),2);
      if (isNaN(Number(pageId)))
        pageId=0;
      let pageContentName='';
      let resourceType = contentTypeToFastType('producer');
      let reactionTabUrl = '';

      if (current.ready)
      {
        resourceType = current.resourceType;
        reactionPosts = MyAppPool.session.reactionPost[resourceType][current.id];

        if (typeof reactionPosts ==='undefined')
        {
          reactionPosts=[];
          reactionReady = 0;
        }else
          reactionReady = 1;

        let tabBaseUrl = current.baseUrl;
        reactionTabUrl = tabBaseUrl+'/'+lng('social');

        pageId=current.id;
        pageContentName = current.name;
        gradeBoxInfo.grade = current.grades[0].averagegrade;
        gradeBoxInfo.votes = current.grades[0].totalvotes;
        verticalChartValues = 
          [
            {value:current.grades[0].grade1,text:'<50-59',gradient:"linear-gradient(#FF6A00, #FF0000)"},
            {value:current.grades[0].grade2,text:'60-69',gradient:"linear-gradient(#FFD800, #FF6A00)"},
            {value:current.grades[0].grade3,text:'70-79',gradient:"linear-gradient(#B6FF00, #FFD800)"},
            {value:current.grades[0].grade4,text:'80-89',gradient:"linear-gradient(#00FF21, #99ff00)"},
            {value:current.grades[0].grade5,text:'90-100',gradient:"linear-gradient(#00FF21, #4CFF00)"}
          ];
      }

        if (!isMobile.any)//DESKTOP LAYOUT
          return (<div className='music_geral_view'>
          <div className="contentArea uk-flex uk-flex-row">
        
        <div className="leftContent">

        </div>

        <div className="middleContent">
          <div className="uk-flex uk-flex-row">
            <div style={{width:"20%"}}> 
              <GradeBox
              value={gradeBoxInfo.grade}
              amount={gradeBoxInfo.votes}
              ready={current.ready}
              title={lng('Nota')}
              bywho={lng('usuários')}
              resourceId={pageId}
              resourceType={resourceType}
              mySelectedGrade={MyAppPool.session.user.grades[resourceType][pageId]}
              />
            </div>
            <div  style={{width:"80%",marginLeft:"15px"}}>
            <ChartVerticalBar
              ready={current.ready}
              items = {verticalChartValues}
              />
            </div>
          </div>
         <div style={{"width":"100%","marginTop":"15px"}}>
          <ReactionsCard
                currentUserId={MyAppPool.currentUser.userid}
                currentUserRoles={MyAppPool.currentUser.roles} //mabmod
                ready={reactionReady}
                items={reactionPosts}
                resourceType={resourceType}
                resourceId={pageId}
                resourceName={current.name + " - "+current.band}
                goToUrl={reactionTabUrl}
                  />
        </div>

          <div className="uk-flex uk-flex-row  filterBox">
              <input type="text" value={this.state.nameFilter} onChange={(e) => {this.setState({nameFilter: e.target.value});}} className="uk-textarea textInput" maxLength={140} rows={1} placeholder={lng("Filtrar por Nome") }  />
              <select onChange={(e) => {this.setState({typeFilter: e.target.value});}}>
            <option value="">{lng('Tipo')}</option>
            {
              current.ready?
              current.relatedAnimeTypes.map((item, index) => {
                return (<option value={item} key={index}>{lng(item)}</option>);
              })
              :<></>
            }
          </select>
          </div>
          {current.ready? 
          current.anime.map((item, index) => {
            return (
              this.passFilter(item)?
              <div id={getAnchorName(item.title)} key={index} className="rowCardContainer">
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'225px', 'marginTop':'8px'}}/>}
              >
                <RelatedRowCard
                id ={item.id}
                name ={item.title}
                relation = {lng(item.reltype)}
                type = {lng(item.type)}
                data = {item.airfrom}
                img ={getImgAddress(item.mainimg,'/imgs/default/anime.webp')}
                description = {item.description}
                icon = {getRelatedTypeIcon(item.type)}
                url ={getObraRoute(item.id,item.title,'anime')+'#top'}
                ready={current.ready}
                />
              </LazyLoadComponent>
            </div> : <></>
            )})
          :
          <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
          </div>}

          <div style={{marginTop:"15px"}}>
            <h3>{lng("Latest Discussions")}
            <LinkV2 to={getForumCreateThreadPage("?tagNames="+"lnkres_"+resourceType+"_"+pageId,"#top")} className="uk-button uk-button-default  sendButton">{lng("Criar uma Thread")}</LinkV2>
            </h3>
            <ForumThreadsByTagBox tag={"lnkres_"+resourceType+"_"+pageId}/>
          </div>
          

        </div>
        
        <div className="rightContent">
          <div style={{width:"100%"}}>
            <div  style={{marginTop:"15px"}}>
              {current.ready?<ScrollToTopButtom />:<></>}
            </div>
          </div>
        </div>
      

      </div>
      </div>);

    if (isMobile.any)//MOBILE LAYOUT
      return (<div className='music_geral_view'>
      <div className="contentArea uk-flex uk-flex-row">

      <div className="middleContent">
      <div className="uk-flex uk-flex-row">
        <div style={{width:"20%"}}> 
        <GradeBox
              value={gradeBoxInfo.grade}
              amount={gradeBoxInfo.votes}
              ready={current.ready}
              title={lng('Nota')}
              bywho={lng('usuários')}
              resourceId={pageId}
              resourceType={resourceType}
              mySelectedGrade={MyAppPool.session.user.grades[resourceType][pageId]}
              />
          
        </div>
        <div  style={{width:"80%",marginLeft:"15px"}}>
        <ChartVerticalBar
          ready={current.ready}
          items = {verticalChartValues}
          />
        </div>
      </div>
      <div style={{marginTop:"15px"}}>
      <ReactionsCard
            currentUserId={MyAppPool.currentUser.userid}
            currentUserRoles={MyAppPool.currentUser.roles} //mabmod
            ready={reactionReady}
            items={reactionPosts}
            resourceType={resourceType}
            resourceId={pageId}
            resourceName={current.name}
            goToUrl={reactionTabUrl}
              />
      </div>
      
      <div className="uk-flex uk-flex-row  filterBox">
          <input type="text" value={this.state.nameFilter} onChange={(e) => {this.setState({nameFilter: e.target.value});}} className="uk-textarea textInput" maxLength={140} rows={1} placeholder={lng("Filtrar por Nome") }  />
          <select onChange={(e) => {this.setState({typeFilter: e.target.value});}}>
            <option value="">{lng('Tipo')}</option>
            {
              current.ready?
              current.relatedAnimeTypes.map((item, index) => {
                return (<option value={item} key={index}>{lng(item)}</option>);
              })
              :<></>
            }
          </select>
          
      </div>
      
      {current.ready? 
      current.anime.map((item, index) => {
        return (
          this.passFilter(item)?
          <div id={getAnchorName(item.title)} key={index} className="rowCardContainer">
          <LazyLoadComponent 
          scrollPosition={MyAppPool.scrollPosition }
          visibleByDefault={isServer()}
          placeholder={<SkeletonBlock  style={{'width':'100%',height:'225px', 'marginTop':'8px'}}/>}
          >
            <RelatedRowCard
            id ={item.id}
            name ={item.title}
            relation = {lng(item.reltype)}
            type = {lng(item.type)}
            data = {item.airfrom}
            img ={getImgAddress(item.mainimg,'/imgs/default/anime.webp')}
            description = {item.description}
            icon = {getRelatedTypeIcon(item.type)}
            url ={getObraRoute(item.id,item.title,'anime')+'#top'}
            ready={current.ready}
            />
          </LazyLoadComponent>
        </div> : <></>
        )})
      :
      <div>
        <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
        <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
        <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
      </div>}

      
      <div style={{marginTop:"15px"}}>
            <h3>{lng("Latest Discussions")}
            <LinkV2 to={getForumCreateThreadPage("?tagNames="+"lnkres_"+resourceType+"_"+pageId,"#top")} className="uk-button uk-button-default  sendButton">{lng("Criar uma Thread")}</LinkV2>
            </h3>
            <ForumThreadsByTagBox tag={"lnkres_"+resourceType+"_"+pageId}/>
      </div>

      </div>

      </div>
      </div>);


    }

    
}

export default MusicGeralView;