
import { toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { getImgAddress } from '../util/text';
import { arraysEqual, clone, isSet, isString, pickRandomFromArray, removeUndefinedFromArray } from '../util/typeu';
import React, { Component } from 'react';

let defaultImg = "https://files.nuecloud.cyou/buckets/mab/imgs/defaults/bg1.webp"
let rands= {
    sky:["https://files.nuecloud.cyou/buckets//mab//imgs//defaults//backsky.webp"]
};

export function imgArraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (let i = 0; i < a.length; i++) {
    if (a[i].indexOf('random:')!==-1)
        continue;
      if (a[i] !== b[i]) return false;
    }
    return true;
}

let transitionTime = 1500;
export default class ContentBannerController
{
    preprocessImgs(imgs){
        let useImageUrl = imgs;
        for(let i=0;i<useImageUrl.length;i++)
        {
            let elem = useImageUrl[i];
            console.log("look:",elem);
            if (!isString(elem))
                continue;
            if (elem.indexOf('random:')!==-1)
            {
                let content = elem.split(':');
                let cat = content[1];
                if (!isSet(rands[cat]))
                    useImageUrl[i] = defaultImg;
                else
                    useImageUrl[i] = pickRandomFromArray(rands[cat]);
            }
        }  
        return useImageUrl;
    }
    setContentBannerImage(imageUrlArgs,modes=['bg'],component=<></>)
    {
        let useImageUrl=removeUndefinedFromArray(imageUrlArgs);
        
        if (!isSet(useImageUrl) || !isSet(useImageUrl[0]))
            return;
        if (useImageUrl[0]==='' || useImageUrl.length===0)
            useImageUrl=[defaultImg];
            //console.log("USEIMG0:",useImageUrl);
        if (imgArraysEqual(useImageUrl,MyAppPool.session.contentBanner.image))
            return;
        
        useImageUrl = this.preprocessImgs(useImageUrl);
        //console.log("USEIMG:",useImageUrl);
        if (MyAppPool.session.contentBanner.change!==true)
        {
            MyAppPool.session.contentBanner.change=true;
            setTimeout(()=>{
                MyAppPool.session.contentBanner.change=false;
            },transitionTime);
        }

        console.log("BEFORE::",toJS(MyAppPool.session.contentBanner.image), "AFTER::",useImageUrl);
        MyAppPool.jsObjectStore.lastBannerComponent =  MyAppPool.jsObjectStore.bannerComponent;
        MyAppPool.session.contentBanner.lastMode =  clone(MyAppPool.session.contentBanner.mode);
        MyAppPool.session.contentBanner.lastImage = clone(MyAppPool.session.contentBanner.image);
        MyAppPool.session.contentBanner.image = useImageUrl;
        MyAppPool.session.contentBanner.mode = modes;
        MyAppPool.jsObjectStore.bannerComponent =  component;
    }


}