import { toJS } from "mobx";

export function isSet(ob){
    if (typeof ob === 'undefined')
        return false;
    if (ob===null)
        return false;
    return true;
}
export function setIfNotSetObj(elem,field){
    if (!isSet(elem[field]))
        elem[field]={};
};
export function setIfNotSetArray(elem,field){
    if (!isSet(elem[field]))
        elem[field]=[];
};
export function setIfNotSetObjElems(elem,fields)
{
    if (!isSet(elem))
        return null;
    let useElem=elem;
    for(let i=0;i<fields.length;i++)
    {
        setIfNotSetObj(useElem,fields[i]);
        useElem = useElem[fields[i]];
    }
};
export function getIfIsSetElems(elem,fields)
{
    let useElem=elem;
    for(let i=0;i<fields.length;i++)
    {
        if (!isSet(useElem[fields[i]]))
            return null;
        useElem = useElem[fields[i]];
    }
    return useElem;
};

export function isValidIndex(value) {
    return Number(value)==value;
  }
export function isEmpty(data)
{
    if (Array.isArray(data))
        return (data.length===0) ;
    if (typeof data==='object' && data!==null && data.values().length>0)
        return false;
    return true;
}
export function mobToObj(mobxObservable)
{
    return JSON.parse(JSON.stringify(toJS(mobxObservable)));
}
export function clone(myob)
{
    return JSON.parse(JSON.stringify(myob));
}

export function pickRandomFromArray(items)
{
    return items[Math.floor(Math.random()*items.length)];
}
export function isString(myVar)
{
    return typeof myVar === 'string' || myVar instanceof String;
}
export function removeUndefinedFromArray(array) {
    // Use Array.filter to create a new array with only non-undefined elements
    return array.filter(item => item !== undefined);
}
export function detectUndefinedInArray(array) {
    for(let i=0;i<array.length;i++)
    {
        if (!isSet(array[i]))
            return true;
    }
    return false;
}


let contentTypes = [
'anime',
'manga',
'genre',
'character',
'person',
'producer',
'publisher',
'review',
'post',
'pool',
'article',
'reactionPost',
"anime_grade",
"manga_grade",
"music",
"user",
"thread",
"reply"
];
export let animeFastResourceType=0;
export let mangaFastResourceType=1;
export let characterFastResourceType=3;
export let personFastResourceType=4;
export let companyFastResourceType=5;
export let magazineFastResourceType=6;
export let userFastResourceType=15;
export let musicFastResourceType=14;
export let threadFastResourceType=16;
export let replyFastResourceType=17;

let contentTypeMapping = {};

let animeTopsContentStarter = {TV:{pages:{}},
                OVA:{pages:{}},
                Special:{pages:{}},
                Movie:{pages:{}},
                ONA:{pages:{}},
                Music:{pages:{}},
                Unknown:{pages:{}},
                all:{pages:{}}
            };   
let mangaTopsContentStarter = {TV:{pages:{}},
    Novel:{pages:{}},
    Manga:{pages:{}},
    'One-shot':{pages:{}},
    Doujinshi:{pages:{}},
    Manhwa:{pages:{}},
    Manhua:{pages:{}},
    'Light Novel':{pages:{}},
    all:{pages:{}}
};   

for(let i=0;i<contentTypes.length;i++)
    contentTypeMapping[contentTypes[i]] = i;

// for initializing votes, reviews, etc by resourceId
var contentTypeDefaultStarter;
contentTypeDefaultStarter = {};
for(let i=0;i<contentTypes.length;i++)
    contentTypeDefaultStarter[''+i] = {};

export function getDefaultStarter()
{
    //get deep clone of content starter
    return JSON.parse(JSON.stringify(contentTypeDefaultStarter));
}

export function getAnimeTopsDefaultStarter()
{
    return JSON.parse(JSON.stringify(animeTopsContentStarter)); 
}
export function getMangaTopsDefaultStarter()
{
    return JSON.parse(JSON.stringify(mangaTopsContentStarter)); 
}



export function contentTypeToFastType(val)
{
    let ret = contentTypeMapping[val];
    if (typeof ret==='undefined')
        return null;
    return ret;
}
export function contentFastTypeToType(val)
{
    let ret = contentTypes[val];
    if (typeof ret==='undefined')
        return null;
    return ret;
}

export function getImgMimeFromName(name)
{
    if (name.indexOf('.png')!==-1)
        return 'image/png';
    if (name.indexOf('.jpg')!==-1)
        return 'image/jpg';
    if (name.indexOf('.gif')!==-1)
        return 'image/gif';
    if (name.indexOf('.webp')!==-1)
        return 'image/webp';
    return 'image/jpg'
}

export function setObjectAttribute(destination,key,val)
{
    if (isSet(destination))
        destination[key]=val;
    else
    {
        destination={};
        destination[key]=val;
    }
}

Array.prototype.pushDistinct = function(item) {
    var target = this;
    if(target.indexOf(item) === -1) {
        target.push(item);
    }
    return target;
  };

var myexports = {
    isSet,
    mobToObj,
    contentFastTypeToType,
    contentTypeToFastType,
    getDefaultStarter
}

export default myexports;