
var DAY_IN_MILIS=86400000;

export var routes = {
    resourcePopularCount:{
      path:"/api/dataw/resource/hitcount",
      service:"mabdatawrite",
      method:"POST",
      cache:0,
      params:{resourceType:0,resourceId:1},
      defaultHeaders:{}
    },
    mergeManga:{
      path:"/api/dataw/manga/merge",
      service:"mabdatawrite",
      method:"PATCH",
      cache:0,
      params:{receiverId:0,giverId:1},
      defaultHeaders:{}
    },
    mergeAnime:{
      path:"/api/dataw/anime/merge",
      service:"mabdatawrite",
      method:"PATCH",
      cache:0,
      params:{receiverId:0,giverId:1},
      defaultHeaders:{}
    },
    initUserLists:{
      path:"/api/usew/init/lists",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{},
      defaultHeaders:{}
    },
    createUserTask:{
      path:"/api/usew/user/task",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{},
      defaultHeaders:{}
    },
    getResourceMedia:{
      path:"/api/datar/media",
      service:"mabdataread",
      method:"GET",
      cache:0,
      params:{'resourceType':0,'resourceId':1},
      defaultHeaders:{}
    },
    saveResourceMedia:{
      path:"/api/dataw/user/resource/media",
      service:"mabdatawrite",
      method:"POST",
      cache:0,
      params:{'resourceType':0,'resourceId':1,'type':'video/image',
      'purpose':'banner/cover image/trailer', 'provider':'brasifiles/youtube',
      'imageBase64':'base64encodedimage','link':'xpto'},
      defaultHeaders:{}
    },
    setResourceMediaDefault:{
      path:"/api/dataw/user/resource/media/default/field",
      service:"mabdatawrite",
      method:"POST",
      cache:0,
      params:{'resourceType':0,'resourceId':1,'value':'xpto','field':'cover image'},
      defaultHeaders:{}
    },
    deleteResourceMedia:{
      path:"/api/dataw/user/resource/media",
      service:"mabdatawrite",
      method:"DELETE",
      cache:0,
      params:{'resourceType':0,'resourceId':1,'link':'xpto'},
      defaultHeaders:{}
    },
    getRecentUserTasks:{
      path:"/api/usew/user/task",
      service:"mabuserwrite",
      method:"GET",
      cache:0,
      params:{},
      defaultHeaders:{}
    },
    getCurrentSeasonAnime:{
      path:"/api/datar/search/season/current/anime",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{lng:'pt',tvOnly:true},
      defaultHeaders:{}
    },
    getTopMangaGradePage:{
      path:"/api/datar/tops/manga/grade",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'TV',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopAnimeGradePage:{
      path:"/api/datar/tops/anime/grade",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'TV',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopAnimeGradeSeasonPage:{
      path:"/api/datar/tops/anime/grade/season",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'TV',year:2023,season:0,page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopMangaGradeSeasonPage:{
      path:"/api/datar/tops/manga/grade/season",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'manga',year:2023,season:0,page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopAnimeGradeYearPage:{
      path:"/api/datar/tops/anime/grade/year",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'TV',year:2024,page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopMangaGradeYearPage:{
      path:"/api/datar/tops/manga/grade/year",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'manga',year:2024,page:0,pageSize:40},
      defaultHeaders:{}
    },
    getAmazonAffiliateLinks:{
      path:"/api/afi/amzn/link",
      service:"mabaffiliate",
      method:"GET",
      cache:0,
      params:{query:'figure one piece',country:'BR',fetch:false},
      defaultHeaders:{}
    },
    getApoiaseCampaign:{
      path:"/api/afi/apoiase",
      service:"mabaffiliate",
      method:"GET",
      cache:0,
      params:{},
      defaultHeaders:{}
    },
    getTopAnimeAiringPage:{
      path:"/api/datar/tops/anime/grade/airing",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'TV',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopMangaAiringPage:{
      path:"/api/datar/tops/manga/grade/airing",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'manga',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopAnimeUpcomingPage:{
      path:"/api/datar/tops/anime/popular/upcoming",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'TV',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopMangaUpcomingPage:{
      path:"/api/datar/tops/manga/popular/upcoming",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'TV',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopAnimePopularPage:{
      path:"/api/datar/tops/anime/popular",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{periodo:'"",today,week,month',type:'Movie',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getTopMangaPopularPage:{
      path:"/api/datar/tops/manga/popular",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{periodo:'"",today,week,month',type:'Novel',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getRecentTrailersPage:{
      path:"/api/datar/recent/trailers",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{type:'mytype',page:0,pageSize:40},
      defaultHeaders:{}
    },
    getRecentMusicsPage:{
      path:"/api/datar/recent/musics",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{page:0,pageSize:40},
      defaultHeaders:{}
    },
    getLatestReviews:{
      path:"/api/comur/latest/review",
      service:"mabcomuread",
      method:"GET",
      cache:50,
      params:{page:0,pageSize:40},
      defaultHeaders:{}
    },
    getLatestReactions:{
      path:"/api/comur/latest/reaction",
      service:"mabcomuread",
      method:"GET",
      cache:50,
      params:{page:0,pageSize:40},
      defaultHeaders:{}
    },
    getUserWall:{
      path:"/api/comur/user/wall",
      service:"mabcomuread",
      method:"GET",
      cache:50,
      params:{page:0,pageSize:40},
      defaultHeaders:{}
    },
    searchPublisher:{
      path:"/api/datar/search/publisher",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{name:'myname',page:0,pageSize:40},
      defaultHeaders:{}
    },
    searchCompany:{
      path:"/api/datar/search/company",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{name:'myname',page:0,pageSize:40},
      defaultHeaders:{}
    },
    searchCharacter:{
      path:"/api/datar/search/character",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{lng:"pt",name:'myname',page:0,pageSize:40},
      defaultHeaders:{}
    },
    searchPerson:{
      path:"/api/datar/search/person",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{name:'myname',page:0,pageSize:40},
      defaultHeaders:{}
    },
    searchMusic:{
      path:"/api/datar/search/music",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{name:'myname',page:0,pageSize:40},
      defaultHeaders:{}
    },
    searchUser:{
      path:"/api/userr/search",
      service:"mabuserread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{name:'myname',page:0,pageSize:40},
      defaultHeaders:{}
    },
    searchAnimeWhenEmpty:{
      path:"/api/datar/search/anime/empty",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{lng:"pt"},
      defaultHeaders:{}
    },
    searchAnime:{
      path:"/api/datar/search/anime",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{lng:"pt",filters:{
                        name:"teste123",year:2020,season:0,type:"ova",airing:1,genres:["acao","aventura"],genreIsOr:1}},
      defaultHeaders:{}
    },
    searchAnimeFilters:{
      path:"/api/datar/search/filters/anime",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{lng:'pt'},
      defaultHeaders:{}
    },
    searchMangaWhenEmpty:{
      path:"/api/datar/search/manga/empty",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{lng:"pt"},
      defaultHeaders:{}
    },
    searchManga:{
      path:"/api/datar/search/manga",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{lng:"pt",filters:{
                        name:"teste123",year:2020,season:0,type:"ova",airing:1,genres:["acao","aventura"],genreIsOr:1}},
      defaultHeaders:{}
    },
    searchMangaFilters:{
      path:"/api/datar/search/filters/manga",
      service:"mabdataread",
      method:"GET",
      cache:DAY_IN_MILIS,
      params:{lng:'pt'},
      defaultHeaders:{}
    },
    createUser:{
      path:"/api/usew/register",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{email:'',name:'',nickName:'', pass:'',lang:''},
      defaultHeaders:{'Cache-Control':'no-cache'}
    },
    confirmNewPassword:{
      path:"/api/usew/recoversetnewpass",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{nickName:'', pass:'',token:'',lang:''},
      defaultHeaders:{'Cache-Control':'no-cache'}
    },
    confirmAccount:{
      path:"/api/usew/confirm",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{nickName:'',token:''},
      defaultHeaders:{'Cache-Control':'no-cache'}
    },
    forgotLogin:{
      path:"/api/usew/generaterecoverpass",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{email:'', lang:''},
      defaultHeaders:{'Cache-Control':'no-cache'}
    },
    tokenLogin:{
      path:"/api/usew/tokenlogin",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{token:'', socialType:''},
      defaultHeaders:{'Cache-Control':'no-cache'}
    },
    passLogin:{
      path:"/api/usew/login",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{user:'', pass:''},
      defaultHeaders:{'Cache-Control':'no-cache'}
    },
    refreshLogin:{
      path:"/api/usew/refreshlogin",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{token:'', device:''},
      defaultHeaders:{'Cache-Control':'no-cache'}
    },
    userUpdateMusicUrl:{
      path:"/api/dataw/music/videourl",
      service:"mabdatawrite",
      method:"PATCH",
      cache:0,
      params:{videoUrl:'',musicId:''},
      defaultHeaders:{},
      options:{}
    },
    userUpdateProfile:{
      path:"/api/usew/user/profile",
      service:"mabuserwrite",
      method:"PATCH",
      cache:0,
      params:{avatar:'', banner:'',bio:''},
      defaultHeaders:{},
      options:{}
    },
    neoComuWrite:{
      path:"/api/neocomu/apiwrite.php",
      service:"neocomu",
      method:"POST",
      cache:0,
      params:{action:'', etc:''},
      defaultHeaders:{},
      options:{}
    },
    neoComuRead:{
      path:"/api/neocomu/apiread.php",
      service:"neocomu",
      method:"GET",
      cache:0,
      params:{action:'', etc:''},
      defaultHeaders:{},
      options:{}
    },
    userUploadImage:{
      path:"/api/usew/user/image",
      service:"mabuserwrite",
      method:"POST",
      cache:0,
      params:{imageBase64:0, purpose:'avatar,banner,thread',provider:'imgur'},
      defaultHeaders:{},
      options:{}
    },
    userDeleteImage:{
      path:"/api/usew/user/image",
      service:"mabuserwrite",
      method:"DELETE",
      cache:0,
      params:{link:'https://image.com'},
      defaultHeaders:{},
      options:{}
    },
    userGetImagesPrivate:{
      path:"/api/usew/user/image",
      service:"mabuserwrite",
      method:"GET",
      cache:0,
      params:{purpose:'avatar,banner,thread'},
      defaultHeaders:{},
      options:{}
    },
    getUserByIdLng:{
      path:"/api/userr/profile",
      service:"mabuserread",
      method:"get",
      cache:30,
      params:{aid:0, lang:''},
      defaultHeaders:{}
    },
    getAnimeByIdLng:{
        path:"/api/datar/anime",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{aid:0, lng:''},
        defaultHeaders:{}
      },
      getAnimeSamplesByIdsLng:{
        path:"/api/datar/anime/sample",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{ids:"0,1,2,3", lng:''},
        defaultHeaders:{}
      },
      getAnimeBroadcastingThisWeek:{
        path:"/api/datar/anime/broadcast",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{},
        defaultHeaders:{}
      },
      getAnimeSimulcasting:{
        path:"/api/datar/anime/simulcast",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{start:"yyyy-mm-dd",end:"yyyy-mm-dd",lang:"pt"},
        defaultHeaders:{}
      },
      getMangaByIdLng:{
        path:"/api/datar/manga",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{aid:0, lng:''},
        defaultHeaders:{}
      },
      getMusicByIdLng:{
        path:"/api/datar/music",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{id:0, lng:''},
        defaultHeaders:{}
      },
      getMangasSamplesByIdsLng:{
        path:"/api/datar/manga/sample",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{ids:"0,1,2,3", lng:''},
        defaultHeaders:{}
      },
      getDataSamplesByIdsLng:{
        path:"/api/datar/samples/list",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{resourceType:0, ids:"0,1,2,3", lang:''},
        defaultHeaders:{}
      },
      getUserSamplesByIdsLng:{
        path:"/api/userr/list/user-sample",
        service:"mabuserread",
        method:"get",
        cache:30,
        params:{ids:"0,1,2,3"},
        defaultHeaders:{}
      },
      getCompanyByIdLng:{
        path:"/api/datar/company",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{cid:0, lng:''},
        defaultHeaders:{}
      },
      getPublisherByIdLng:{
        path:"/api/datar/publisher",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{cid:0, lng:''},
        defaultHeaders:{}
      },
      getPersonByIdLng:{
        path:"/api/datar/person",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{pid:0, lng:''},
        defaultHeaders:{}
      },
      getCharacterByIdLng:{
        path:"/api/datar/character",
        service:"mabdataread",
        method:"get",
        cache:30,
        params:{cid:0, lng:''},
        defaultHeaders:{}
      },
      getUserVotes:{
        path:"/api/comur/user-votes",
        service:"mabcomuread",
        method:"get",
        cache:0, //one day
        params:{uid:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      getWorkResourceUserVotes:{
        path:"/api/userr/user-votes",
        service:"mabuserread",
        method:"get",
        cache:0, //one day
        params:{uid:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      getMusicListContent:{
        path:"/api/datar/list/content/music",
        service:"mabdataread",
        method:"get",
        cache:86400000, //ten seconds
        params:{musicIds:"1,2,3,4"},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      getUserLists:{
        path:"/api/userr/user-lists",
        service:"mabuserread",
        method:"get",
        cache:0, 
        params:{uid:1,lang:'pt'},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendCreateList:
      {
        path:"/api/usew/list",
        service:"mabuserwrite",
        method:"post",
        cache:0,
        params:{resourceType:0,name:'',lang:'pt'},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendAddListItem:
      {
        path:"/api/usew/list/item",
        service:"mabuserwrite",
        method:"post",
        cache:0,
        params:{resourceType:'',resourceId:0,listId:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendRemoveListItem:
      {
        path:"/api/usew/list/item",
        service:"mabuserwrite",
        method:"delete",
        cache:0,
        params:{resourceType:'',resourceId:0,listId:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendDeleteList:
      {
        path:"/api/usew/list",
        service:"mabuserwrite",
        method:"delete",
        cache:0,
        params:{listId:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendUpdateList:
      {
        path:"/api/usew/list",
        service:"mabuserwrite",
        method:"patch",
        cache:0,
        params:{lang:"pt",listId:0,name:'',ordering:0},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      getUserGrades:{
        path:"/api/userr/user-grades",
        service:"mabuserread",
        method:"get",
        cache:0, //one day
        params:{uid:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendWorkResourceLike:
      {
        path:"/api/usew/resource/like",
        service:"mabuserwrite",
        method:"post",
        cache:0,
        params:{resourceType:'',resourceId:0,voteValue:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendNeoComuResourceLike:
      {
        path:"/api/comuw/neocomu/vote",
        service:"mabcomuwrite",
        method:"post",
        cache:0,
        params:{resourceType:0,resourceId:0,voteValue:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendWorkResourceGrade:
      {
        path:"/api/usew/resource/grade",
        service:"mabuserwrite",
        method:"post",
        cache:0,
        params:{resourceType:'',resourceId:0,grade:5},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      sendReviewVote:
      {
        path:"/api/comuw/review/like",
        service:"mabcomuwrite",
        method:"post",
        cache:0,
        params:{reviewId:0},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      createOrUpdateReview:
      {
        path:"/api/comuw/review",
        service:"mabcomuwrite",
        method:"post",
        cache:0,
        params:{resourceType:0,resourceId:0,content:'',animGrade:0.0,musicGrade:0.0,storyGrade:0.0,characterGrade:0.0,reviewId:-1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      deleteReview:
      {
        path:"/api/comuw/review",
        service:"mabcomuwrite",
        method:"delete",
        cache:0,
        params:{reviewId:0},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      getReviewByResourceId:
      {
        path:"/api/comur/review",
        service:"mabcomuread",
        method:"get",
        cache:30,
        params:{resourceType:0,resourceId:0},
        defaultHeaders:{}
      },
      sendReactionPostVote:
      {
        path:"/api/comuw/reaction-post/vote",
        service:"mabcomuwrite",
        method:"post",
        cache:0,
        params:{reactionPostId:0,voteValue:1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      createOrUpdateReactionPost:
      {
        path:"/api/comuw/reaction-post",
        service:"mabcomuwrite",
        method:"post",
        cache:0,
        params:{resourceType:0,resourceId:0,content:'',reactionPostId:-1},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      deleteReactionPost:
      {
        path:"/api/comuw/reaction-post",
        service:"mabcomuwrite",
        method:"delete",
        cache:0,
        params:{reactionPostId:0},
        defaultHeaders:{'Cache-Control':'no-cache'}
      },
      getReactionPostByResourceId:
      {
        path:"/api/comur/reaction-post",
        service:"mabcomuread",
        method:"get",
        cache:30,
        params:{resourceType:0,resourceId:0},
        defaultHeaders:{}
      },
      termsOfUseEn:
      {
        path:"/terms/termsofuseen.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfUsePt:
      {
        path:"/terms/termsofusept.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfUseEs:
      {
        path:"/terms/termsofusees.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfUseDe:
      {
        path:"/terms/termsofusede.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfPrivacyEn:
      {
        path:"/terms/privacypolicyen.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfPrivacyPt:
      {
        path:"/terms/privacypolicypt.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfPrivacyEs:
      {
        path:"/terms/privacypolicyes.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfPrivacyDe:
      {
        path:"/terms/privacypolicyde.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfCookiesEn:
      {
        path:"/terms/ckpolicyen.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfCookiesPt:
      {
        path:"/terms/ckpolicypt.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfCookiesEs:
      {
        path:"/terms/ckpolicyes.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      },
      termsOfCookiesDe:
      {
        path:"/terms/ckpolicyde.html",
        service:"frontend",
        method:"get",
        params:{},
        defaultHeaders:{}
      }
};