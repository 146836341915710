
import { computed, toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getAnimePeopleRoute, getAnimePersonagensRoute, getAnimeRelatedRoute, getCharacterRoute, getCompanyRoute, getObraRoute, getPersonRoute, getSearchRoute } from '../store/AppRoutes';
import { getFlag, getRelatedTypeIcon } from '../util/icon';
import { getCurrentLanguage, getCurrentLocale, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { convertBroadcastDayToShowable, convertDataToShowable, convertSeasonNumberToName, getAnchorName, getImgAddress, getRouteParamAtIndex, getSEOUriPath, getYearFromDate } from '../util/text';
import { contentTypeToFastType, isValidIndex, isSet, mobToObj, animeFastResourceType } from '../util/typeu';
import { getCurrentUrl, isServer } from '../util/windowUtil';
import { weeksSince } from '../util/timeUtil';


export default class AnimeController
{
    fetchAnimeById(index)
    {
        let loadId;
        if (!isSet(index) || !isValidIndex(index))
            return Promise.resolve(true);
        if (Number(index)===Number(MyAppPool.session.anime.currentAnime))
        {   
            loadId = MyAppPool.cGlobalLoadingBar.start();
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(true);
        }
        
        MyAppPool.session.anime.animes[index] = {ready:0};
        MyAppPool.session.anime.currentAnime= index;
        loadId = MyAppPool.cGlobalLoadingBar.start(1500);

        if (!isServer())
            brasRequest(routes.resourcePopularCount,false,{resourceType:contentTypeToFastType('anime'),resourceId:index});
      
        return fetchAndProcess(this.setSessionAnime,
            routes.getAnimeByIdLng,true,{aid:index,lng: getCurrentLanguage()})
            .then(async (data) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,true);
                let anime = MyAppPool.session.anime.animes[MyAppPool.session.anime.currentAnime];
                MyAppPool.cAffiliate.getAnimeAmazonAffiliate(anime);
                return Promise.resolve(data);
            })
            .catch((e) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,false);
                MyAppPool.cAlert.notifyGenericError(e,"fetch anime");
                //console.log("Error Fetching anime",e)
            });
    }

    setSessionAnime(data0,ready)
    {
        if (!data0.ok)
            return Promise.reject();
        
        let data = data0.data;
        if (!data.empty)
        {
            let engTitle = '';
            data.mainTitle = data.titles[0].title;
            let maxLength = 8;
            data.remainingTitles = '';
            if (data.titles.length<maxLength)
                maxLength = data.titles.length;
            else
            {
                for (let i=maxLength; i < data.titles.length;i++)
                {
                    data.remainingTitles += data.titles[i].title;
                    if (data.titles[i].type!=="syn" && data.titles[i].langs.indexOf("en")!==-1 && data.titles[i].title.length>engTitle.length)
                        engTitle=data.titles[i].title;

                    if (i<(data.titles.length-1))
                        data.remainingTitles+=", ";
                }
            }
            data.otherTitles = '';
            for (let i=1; i < maxLength;i++)
            {
                data.otherTitles += data.titles[i].title;
                if (data.titles[i].type!=="syn" && data.titles[i].langs.indexOf("en")!==-1 && data.titles[i].title.length>engTitle.length)
                    engTitle=data.titles[i].title;
                if (i<(maxLength-1))
                    data.otherTitles+=", ";
            }
            if (engTitle==='')
                engTitle=data.mainTitle;
            data.engTitle = engTitle;

            data.columItems = [];
            if (data.type!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Tipo'),info:[{type:'misc',value:'',display:lng(data.type)}]};
            if (data.source!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Origem'),info:[{type:'misc',value:'',display:data.source}]};
            
            if (data.studios!==null)
            {   
                if (data.studios.length>0)
                {
                    let items = data.studios;
                    let dcolumItems = [];
                    for (let i=0; i< items.length; i++)
                    {
                        dcolumItems[i] = {type:'url',value:getCompanyRoute(items[i].id,items[i].name),display:items[i].name};
                    }
                    data.columItems[data.columItems.length] =
                    {name:lng('Estúdio(s)'),info:dcolumItems};
                }
            }

            if (data.producers!==null)
            {   
                if (data.producers.length>0)
                {
                let items = data.producers;
                let dcolumItems = [];
                for (let i=0; i< items.length; i++)
                {
                    dcolumItems[i] = {type:'url',value:getCompanyRoute(items[i].id,items[i].name),display:items[i].name};
                }
                data.columItems[data.columItems.length] =
                {name:lng('Produtor(as)'),info:dcolumItems};
                }
            }
            
            
            if (data.season!==null)
            {
                let yearKey =lng('ano');
                let seasonKey=lng('estacao');
                let myYear = getYearFromDate(data.airfrom);
                let queryParams = "?"+ yearKey+'='+myYear + "&"+seasonKey+"="+data.season;
                data.columItems[data.columItems.length] =
                {name:lng('Temporada'),info: [{type:'url',value:getSearchRoute('anime',queryParams),display:convertSeasonNumberToName(data.season,myYear)}]};
            }
            if (data.airfrom!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Lançamento'),info:[{type:'date',value:'',display:convertDataToShowable(data.airfrom)}]};            
            if (data.airto!==null && data.airto!==data.airfrom)
                data.columItems[data.columItems.length] = 
                {name:lng('Finale'),info:[{type:'date',value:'',display:convertDataToShowable(data.airto)}]};
            if (data.broadcast!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Exibição'),info:[{type:'misc',value:'',display:convertBroadcastDayToShowable(data.broadcast)}]};
            if (data.episodes!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Episódios'),info:[{type:'misc',value:'',display:data.episodecount}]};
            if (data.duration!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Duração'),info:[{type:'misc',value:'',display:data.duration + ' ' + lng('minutos')}]};
            if (data.rating!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Indicação'),info:[{type:'misc',value:'',display:data.rating}]};
            if (data.licensors!==null)
            {   
                if (data.licensors.length>0)
                {
                    let items = data.licensors;
                    let dcolumItems = [];
                    for (let i=0; i< items.length; i++)
                    {
                        dcolumItems[i] = {type:'url',value:getCompanyRoute(items[i].id,items[i].name),display:items[i].name};
                    }
                    data.columItems[data.columItems.length] =
                    {name:lng('Licenciado por'),info:dcolumItems};
                }
            }
            
            if (data.remainingTitles!=='')
                data.columItems[data.columItems.length] =
                {name:lng('Outros Titulos'),info:[{type:'misc',value:'',display:data.remainingTitles}]};
            
            let relPeople = [];
            let relRelatedWorks = [];
            let relCharacters = [];
            let relatedAnimeMangaTypes = {};
            let relatedAnimeMangaRelations = {};
            for(let i =0; i<data.related.length; i++)
            {
                let element = data.related[i];

                let anchor = '#'+getAnchorName(element.title);
                relRelatedWorks[i] = {
                url:  getAnimeRelatedRoute(data.id,getSEOUriPath(data.mainTitle),anchor),
                directUrl:getObraRoute(element.id,getSEOUriPath(element.title),element.pagetype,'#top'),
                imgurl:element.mainimg, 
                title:element.title,
                icon: getRelatedTypeIcon(element.type),
                iconText: lng(element.type),
                resourceType:contentTypeToFastType(element.pagetype),
                resourceId:element.id
                }
                //console.log("ELEM::",relRelatedWorks[i]);
            relatedAnimeMangaTypes[element.type]=1;
            relatedAnimeMangaRelations[element.relation]=1;
            }
            
            relatedAnimeMangaTypes = Object.keys(relatedAnimeMangaTypes);
            relatedAnimeMangaRelations = Object.keys(relatedAnimeMangaRelations);
            

            for(let i =0; i<data.characters.length; i++)
            {
                let element = data.characters[i];
                let relVoiceActors = [];
                let anchor = '#'+getAnchorName(element.name+ ' ' + element.familyname);
                relCharacters[i] = {url:  getAnimePersonagensRoute(data.id,getSEOUriPath(data.mainTitle),anchor),
                    directUrl:getCharacterRoute(element.characterid,getSEOUriPath(element.name + ' ' + element.familyname),'#top'),
                    imgurl:element.mainimg, 
                    title:element.name + ' ' + element.familyname,
                    resourceType:contentTypeToFastType("character"),
                    resourceId:element.characterid
                }
                if (element.voiceactors!==null)
                {
                    for(let k =0; k<element.voiceactors.length; k++)
                    {
                        let ele2 = element.voiceactors[k];
                        relVoiceActors[k] = 
                        {imgurl:ele2.mainimg, 
                        url: getPersonRoute (ele2.personid,getSEOUriPath(ele2.name + ' ' + ele2.familyname),"#top"),
                        title:ele2.name + ' ' + ele2.familyname,
                        icon:getFlag(ele2.natcode),
                        iconText:lng('Língua ') + ele2.nationality,
                        resourceType:contentTypeToFastType("person"),
                        resourceId:ele2.personid
                    }
                    }
                    element.relVoiceActors = relVoiceActors;
                }
                else
                    element.relVoiceActors = [];
            }
            let peopleById = {};
            let personRow = [];
            let kCounter=0;
            let peoplePositions = {};
            for(let i =0; i<data.people.length; i++)
            {
                let element = data.people[i];
                let arrayId = peopleById[element.personid];
                if (typeof arrayId!=='undefined')
                {
                    personRow[arrayId].roles[personRow[arrayId].roles.length] = {role:element.role,salary:element.salary,description:element.roledescription};
                    personRow[arrayId].rolesTogether += ', '+ element.role;
                    continue;
                }
                
                personRow[kCounter] = element;
                personRow[kCounter].roles = [{role:element.role,salary:element.salary,description:element.roledescription}];
                personRow[kCounter].rolesTogether = element.role;
                peoplePositions[element.role]=1;
                

                peopleById[data.people[i].personid] = kCounter; 
                let anchor = '#'+getAnchorName(element.name+ ' ' + element.familyname);
                relPeople[kCounter] = {
                    url:  getAnimePeopleRoute(data.id,getSEOUriPath(data.mainTitle),anchor),
                    directUrl:getPersonRoute(element.personid,getSEOUriPath(element.name + ' ' + element.familyname),'#top'),
                    imgurl:element.mainimg, title:element.name+ ' ' + element.familyname,
                    resourceType:contentTypeToFastType("person"),
                    resourceId:element.personid
                }
                kCounter++;
            }
            peoplePositions = Object.keys(peoplePositions);
            
            //console.log(relRelatedWorks);

            data.peoplePositions = peoplePositions;
            data.relatedAnimeMangaRelations = relatedAnimeMangaRelations;
            data.relatedAnimeMangaTypes = relatedAnimeMangaTypes;
            data.personRows = personRow;
            data.relPeople = relPeople;
            data.relRelatedWorks = relRelatedWorks;
            data.relCharacters = relCharacters;
            data.baseUrl = getObraRoute(data.id,data.mainTitle,'anime');
            data.resourceType = contentTypeToFastType('anime');
        }
        //console.log(data);
        MyAppPool.session.grades[contentTypeToFastType('anime')][data.id] = data.grades;
        MyAppPool.session.anime.animes[data.id] = {...MyAppPool.session.anime.animes[data.id],...data} ;
        MyAppPool.session.anime.animes[data.id].ready = ready?1:0;
        //console.log(data.people);
        
        MyAppPool.session.anime.currentAnime=data.id;

        MyAppPool.cPageJsonSchema.makeAnimeJsonSchema();

        return Promise.resolve(true);
    }

    @computed get currentAnime()
    {
        let data = toJS(MyAppPool.session.anime.animes[MyAppPool.session.anime.currentAnime]);
        if (!isSet(data))
            return {ready:0};

        
        data.grades = MyAppPool.session.grades[contentTypeToFastType('anime')][MyAppPool.session.anime.currentAnime];
        return data;//MyAppPool.session.anime.animes[MyAppPool.session.anime.currentAnime];
    }


    getAnimeSamplesByCommaSeparatedIdsList(idsInCommaSeparatedList)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            for(let i=0;i<resData.length;i++)
            {
                let item = resData[i];
                MyAppPool.session.samples.anime[''+item.id] = item;
            }
            return Promise.resolve();
        },
        routes.getAnimeSamplesByIdsLng,true,
        {ids:idsInCommaSeparatedList,lng:getCurrentLanguage()})
        .catch(e => {
            //console.log(("Error Getting Anime Samples"),e)
            MyAppPool.cAlert.notifyGenericError(JSON.stringify(e),"get anime samples");
        });
    }

    getAnimBroadCastingThisWeek()
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let tvShowsByDay = [[],[],[],[],[],[],[],[]];
            let resData = data0.data;
            for(let i=0;i<resData.length;i++)
            {
                let show = resData[i];
                const broadcastDate = new Date(show.broadCastDate);
                const dayOfWeek = broadcastDate.getDay();
                let broadcastTime = broadcastDate.toLocaleTimeString(getCurrentLocale(), {
                    hour: '2-digit',
                    minute: '2-digit'
                });
                let expectedEpisodeNumber = weeksSince(show.airfrom)+1;
                let showForList = {
                    url:  getObraRoute(show.id,getSEOUriPath(show.title),'anime','#top'),
                    imgurl:getImgAddress(show.mainimg), 
                    title:show.title + " \n- "+broadcastTime.toString(),
                    icon: getRelatedTypeIcon(show.type),
                    iconText: lng(show.type),
                    resourceType:animeFastResourceType,
                    resourceId:show.id,
                    grade:undefined,
                    playItem:undefined,
                    redditDiscussionItem:{
                        searchTerm:'https://www.google.com/search?q='+ encodeURIComponent('reddit '+show.title+' - Episode '+expectedEpisodeNumber+' Discussion '+expectedEpisodeNumber+' Anime')+'&btnI=',
                    },
                    alwaysShowName:true,
                    isHentai:show.ishentai,
                    genres:[],
                    date:broadcastDate,
                    gradeable:true
                };

                tvShowsByDay[dayOfWeek].push(showForList);
            }
            MyAppPool.session.anime.tvShowsByDay = tvShowsByDay;
            return Promise.resolve();
        },
        routes.getAnimeBroadcastingThisWeek,true,
        {})
        .catch(e => {
            //console.log(("Error Getting Anime Samples"),e)
            MyAppPool.cAlert.notifyGenericError(JSON.stringify(e),"get anime broadcasting");
        });
    }


    getAnimeSimulcasting(startDateArg,endDateArg)
    {
        let lang = getCurrentLanguage();
        let startDate = startDateArg.toISOString().split('T')[0];
        
        //increase one to the end date, so that the last day is included
        let endDate = endDateArg;
        endDate.setDate(endDate.getDate() + 1);
        endDate = endDate.toISOString().split('T')[0];
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            //let tvShowsByDay = [[],[],[],[],[],[],[],[]];
            let tvShowsByDay = {"Audio Original":[[],[],[],[],[],[],[],[]]};
            let resData = data0.data;
            for(let i=0;i<resData.length;i++)
            {
                let show = resData[i];
                const broadcastDate = new Date(show.releasedate);
                const dayOfWeek = broadcastDate.getDay();
                let broadcastTime = broadcastDate.toLocaleTimeString(getCurrentLocale(), {
                    hour: '2-digit',
                    minute: '2-digit'
                })
                let img = show.imgurl?show.imgurl:show.mainimg;
                let showForList = {
                    url: getObraRoute(show.animeid,getSEOUriPath(show.title),'anime',"#top"),
                    directUrl: show.episodeurl ,
                    imgurl: getImgAddress(img), 
                    title:show.title + " \n- "+broadcastTime.toString(),
                    icon: show.platform?show.platform.toLowerCase():'',
                    iconText: show.platform,
                    resourceType:animeFastResourceType,
                    resourceId:show.animeid,
                    grade:undefined,
                    streamItem:{
                        title:show.name,
                        url:show.episodeurl,
                        episodenum:show.episodenum
                    },
                    redditDiscussionItem:{
                        searchTerm:'https://www.google.com/search?q='+ encodeURIComponent('reddit '+show.title+' - Episode '+show.episodenum+' Discussion '+show.episodenum+' Anime')+'&btnI=',
                    },
                    playItem:undefined,
                    alwaysShowName:true,
                    isHentai:show.ishentai,
                    genres:[],
                    date:broadcastDate,
                    gradeable:true
                };
                if (show.isdub)
                {
                    if (!isSet(tvShowsByDay[show.dublang]))
                        tvShowsByDay[show.dublang] = [[],[],[],[],[],[],[],[]];
                    tvShowsByDay[show.dublang][dayOfWeek].push(showForList);
                }else
                    tvShowsByDay["Audio Original"][dayOfWeek].push(showForList);
            }
            MyAppPool.session.anime.tvShowsSimulcastByDay = tvShowsByDay;
            return Promise.resolve();
        },
        routes.getAnimeSimulcasting,true,
        {lang,start:startDate,end:endDate})
        .catch(e => {
            //console.log(("Error Getting Anime Samples"),e)
            MyAppPool.cAlert.notifyGenericError(JSON.stringify(e),"get anime broadcasting");
        });
    }


    mergeAnime(receiverId,giverId)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            MyAppPool.cAlert.notifyOk("Anime Merged Succesfully");
            return Promise.resolve();
        },
        routes.mergeAnime,false,
        {receiverId,giverId})
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(JSON.stringify(e),"merge anime");
        });
    }
    
    
}