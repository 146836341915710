import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
//import YouTube from 'react-youtube';
import MyAppPool from '../AppPool';
import { getObraRoute, getTrailersRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { getRelatedTypeIcon } from '../util/icon';
import { convertDataToShowable, getImgAddress } from '../util/text';
import { isSet } from '../util/typeu';
import SkeletonBlock from '../view_component/SkeletonBlock';
import { isMobile } from './../util/mobile';
import { getViewportWidthHeight, isServer } from './../util/windowUtil';
import CSSTransition2 from './CSSTransition2';
import Spinner from './Spinner';
import './YoutubeVideo.css';
import LinkV2 from "./LinkV2";

const YouTube = loadable(() => import(/* webpackChunkName: "react-youtube" */ 'react-youtube'));


function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}
const nextTime=5000;
@observer
class YoutubeVideo extends Component {
  constructor(props)
  {
    super(props);
    this.state = {play:false};
    this.onVideoEnd = this.onVideoEnd.bind(this);
    this.playCurrent = this.playCurrent.bind(this);
  }

  componentDidMount()
  {
    
  }
  componentWillReceiveProps()
  {
  }
  onVideoEnd()
  {
    MyAppPool.cMusic.startIfShouldResume();
    this.setState({play:false});
  }
  playCurrent()
  {
    MyAppPool.cMusic.stopAndShouldResumeWhenPossible();
    this.setState({play:true});
  }

  render(){

    let {play} = this.state;
    let {video, height} = this.props;
    let heightPx = height+'px';
    let {ready} = this.props;
    if (!ready)
      return <SkeletonBlock  style={{'width':'100%',height:heightPx}}/>
    
    const trailerOpts = {
        width: '100%',
        height: heightPx,
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        enablejsapi:1,
        controls:1
        }
    };
    let overlayStyle={"top":Math.round((height/2))+'px'};

    return (
        <div className="compYoutubeCommon" style={{backgroundImage: "url('https://img.youtube.com/vi/"+video.ytid+"/hqdefault.jpg')", height:height}}
        onClick={(e)=>{this.playCurrent(); e.preventDefault();}}>
            <CSSTransition2
                in={(play)}
                timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                }}
                classNames="bare"
                unmountOnExit
            >
            <YouTube
                videoId={video.ytid}
                id='homeTrailerPlayer'
                containerClassName='homeTrailerPlayer'
                autoplay={false}
                opts={trailerOpts}
                onEnd={this.onVideoEnd}
            />
            </CSSTransition2>
            {!play?
                <div className="videoShow" style={{"top":Math.round((height/2)-20)+'px'}}>    
                    <i className="far fa-play-circle playIcon iconButton clickable playBtn" />
                </div> :<></>}
            </div>
    );
  }
}


YoutubeVideo.propTypes = {
  ready:PropTypes.number.isRequired,
  height:PropTypes.string,
  video: PropTypes.shape(
      {
        title: PropTypes.string,
        ytid: PropTypes.string.isRequired,
      }
      )
};
YoutubeVideo.defaultProps = {
    video:{
        title: "",
        ytid: "",
    },
    height:340,
    ready:0
};



export default YoutubeVideo;
