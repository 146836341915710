import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import "./TitleBadge.css";

class TitleBadge extends Component {
constructor(props){
    super(props);

}
  componentDidMount()
  {
  }

  render(){
    let {tag} = this.props;
    let rainbowClass='';
    if (tag.rainbow===1)
        rainbowClass="rainbow-badge";
    let style = {};
    if (tag.color!=='')
      style.backgroundColor=tag.color;
    if (tag.size===2)
    {
      style.fontSize="1.1em";
    }
    if (tag.size===3)
    {
      style.fontSize="1.2em";
    }

    return (
            <span style={style} className={"compTitleBadge "+rainbowClass} data-uk-tooltip={tag.description}>{tag.name}</span> 
        );
}
}


TitleBadge.propTypes = {
    tag: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        color:PropTypes.string.isRequired,
        rainbow:PropTypes.string.isRequired,
        size:PropTypes.string.isRequired,
        })
  };
  TitleBadge.defaultProps = {
    tag: {
        name: "Title",
        description: "Title Description",
        color:"",
        rainbow:0,
        size:1,
    }
  };


export default TitleBadge;
