
import { getCurrentLanguage, lng, translateText } from '../ulanguages/language';
import { isSet } from './typeu';

var currentUrl = '';
var userAgent = '';
export function setCurrentUrl(url)
{
  if (isSet(url) && typeof url ==='string')
    currentUrl = url;
}

export function setUserAgent(url)
{
  userAgent = url;
}

export function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}

export function getCssVar(key){
  if (!isServer())
    return getComputedStyle(document.documentElement)
      .getPropertyValue(key);
  return undefined;
}

export function getUserAgent()
{
  if (isServer())
    return userAgent;
  return navigator.userAgent;
}
export function getCurrentUrlUrlEncoded()
{
  return encodeURIComponent(getCurrentUrl());
}
export function getCurrentPageTitleUrlEncoded()
{
  var title = getCurrentPageTitle();
  return encodeURIComponent(title);
}

export function getLogoImage(){
    let logoImage = "/logomarufinal.png";
    if (getCurrentLanguage()==="en")
    {
        logoImage = "/logooni.png";
    }
    return logoImage;
}
export function getMobileLogo(){
    let logoMobileImage = "/smallIcon.png";
    if (getCurrentLanguage()==="en")
    {
        logoMobileImage = "/smallIcononi.png";
    }
    return logoMobileImage;
}

export function getCurrentDomain()
{
  if (!isServer())
  {
    //console.log("CURRENT LOCATION CLIENT: ", window.location.href);
    return window.location.host;
  }
  //console.log("CURRENT LOCATION SERVER: ", currentUrl);
  let pos = 0;
  let pos2=0;
  pos = currentUrl.indexOf("https://");
  if (pos!==-1)
    pos2="https://".length;
  pos = currentUrl.indexOf("http://");
  if (pos!==-1)
    pos2="http://".length;
  let domain = currentUrl.substring(pos2);
  if (domain.charAt(domain.length-1)==='/')
    domain = domain.substring(0,domain.length-1);
    
  return domain;
}
export function getCurrentUrl()
{
  let ret ="";

  if (!isServer())
  {
    ret= isSet(window.location.href) ? window.location.href : '';
  }
  else
    ret = currentUrl;

  //console.log("CURRENTURL:",ret);

  if (typeof ret !=='string')
      return '';
  //console.log("CURRENTURLAFC:",ret);


  return ret;
}

export function getCanonicalUrl(){
  let url = getCurrentUrl();
  let pos = url.indexOf("#");
  if (pos!==-1)
  {url = url.substring(0,pos);}
  return url;
}

export function getCurrentPath()
{
  let url = getCurrentUrl();
  let urlSplit = url.split('/');
  let path='';
  for(let i=3;i < urlSplit.length;i++)
  {
    path += '/'+urlSplit[i];
  }
  if (path==='')
    path='/';
    //console.error(path);
  return path;
}

export function getCurrentPathInAnotherLang(lang='en')
{
    let path = getCurrentPath();
    let currentLang = getCurrentLanguage();
    if (path==='/')
      return path;
    
    let splitPath = path.split("/");
    for(let i=0;i<splitPath.length;i++)
    {
      let test = translateText(currentLang,lang,splitPath[i]);
      if (test!=='-.-')
        splitPath[i] = test;
    }
    path = splitPath.join("/");

    return path;
}

export function getClientHeight()
{
  if (typeof document !=='undefined')
    return document.documentElement.clientHeight;
  return 900;
}

export function getMousePosition()
{
  if (!isServer())
    return window.mousePosition; //obj of the following form: {x:123,y:345}
  else
    return {x:0,y:0};
}

export function getElementRectAbsolute(element)
{
  if (!isServer())
  {
    let elemRect = element.getBoundingClientRect();
    return {
      top:elemRect.top + window.scrollY,
      bottom:elemRect.bottom + window.scrollY,// - bodyRect.bottom,
      left:elemRect.left + window.scrollX,// + bodyRect.left,
      right:elemRect.right + window.scrollX,// - bodyRect.right
    };
  }
  else
    return {top:0,bottom:0,left:0,right:0};
}
var isServerCheck=null;
export function setIsServer(val){
  isServerCheck=val;
}
export function isServer()
{
  if (isServerCheck===null)
  {
    if (typeof window ==='undefined')
      return true;
    if (typeof navigator ==='undefined')
      return true;
    if (typeof document ==='undefined')
      return true;
    return false;
  }else
  {
    return isServerCheck;
  }
}
function getMeta(metaName) {
  if (isServer())
    return '';
  if (typeof document ==='undefined')
    return '';
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
}

export function getCurrentPageTitle()
{
  if (typeof document !=='undefined')
    if (isSet(document.getElementsByTagName("title")[0]))
      return document.getElementsByTagName("title")[0].innerHTML;
  return '';
}
export function getCurrentPageDescription()
{
  if (typeof document !=='undefined')
      return getMeta('description');
  return '';
}

export function scrollToAnchor(next=null,waitTimeArg=1000)
	{
		if (isServer())
			return;
    let anchor = next;
    if (next===null)
    {
      let uri = window.location.href;
      let pos = uri.indexOf('#');
      if (pos===-1)
        return;
      anchor = uri.substr(pos);
    }
    if (anchor==='#')
      return;
    let waitTime = waitTimeArg;
    if (anchor==='#top')//we make an exception to go faster when we are moving to top
      waitTime=100;
		setTimeout(() => {
      try{
			const element = document.querySelector(anchor);
			if (element!==null)
			{
			const topPos = element.getBoundingClientRect().top + window.pageYOffset;
	
			window.scroll({
			  top: topPos,
			  behavior: 'smooth'
			});
		  } 
    }
    catch(e)
    {
      console.error("Unable to scroll due to: ",e);
    }
		}, waitTime);
		return ' ';
	}




  export function getViewportWidthHeight() {
    if (isServer())
      return {width:1920,height:1080};
    
    var viewPortWidth;
    var viewPortHeight;
   
    // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
    if (typeof window.innerWidth != 'undefined') {
      viewPortWidth = window.innerWidth;
      viewPortHeight = window.innerHeight;
    }
   
   // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
    else if (typeof document.documentElement != 'undefined'
    && typeof document.documentElement.clientWidth !=
    'undefined' && document.documentElement.clientWidth != 0) {
       viewPortWidth = document.documentElement.clientWidth;
       viewPortHeight = document.documentElement.clientHeight;
    }
   
    // older versions of IE
    else {
      viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
      viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
    }
    return {width:viewPortWidth, height:viewPortHeight};
   }