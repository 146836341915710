import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
import './ThreadReplyWriter.css'; // Ensure this file exists for styling
import { mobToObj, userFastResourceType } from '../util/typeu';
import MyAppPool from '../AppPool';
import { getImgAddress } from '../util/text';
import { getUserAvatar } from '../util/icon';
import { observer } from 'mobx-react';
import htmlToReact from 'html-to-react';
import { lng } from '../ulanguages/language';
import { timeSinceString } from '../util/timeUtil';
import ThreadTag from './ThreadTag';
import LinkV2 from './LinkV2';
import { getForumSectionPath, getForumTagPath, getForumThreadPage, getUserRoute } from '../store/AppRoutes';
import TitleBadge from './TitleBadge';
import YoutubeVideo from './YoutubeVideo';
import WYSIWYGWriter from './WYSIWYGWriter';
import { isServer } from '../util/windowUtil';

function getPreview(displayContent) {
  const text = displayContent.replace(/<[^>]+>/g, '');
  const preview = text.substring(0, 200);
  return preview.length === 200 ? preview + '...' : preview;
}

@observer
class ThreadReplyWriter extends Component {
  // Helper function to format the relative time
  constructor(props) {
    super(props);
    this.state = {
        content: '',
        isNSFW: false,
        isInappropriate: false,
        isSpoiler: false,
        
    };
  }

  handleCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  
  sendThreadReply = async (content)=>{
    let {isNSFW, isInappropriate, isSpoiler} = this.state;
    let {threadId,pageSize} = this.props;

    if (MyAppPool.cAuth.isGuest())
    {
      MyAppPool.cAuth.activateGlobalLoginBox();
      return;
    }

    let data = await MyAppPool.cNeoComu.createReplyToThread(content,threadId , {isNSFW, isInappropriate, isSpoiler});
    let p2 = MyAppPool.cNeoComu.getThreadRepliesWithHotRanking(threadId,1,pageSize);
    MyAppPool.cAlert.notifyOk(lng("Comentário Criado com Sucesso!"));
    this.setState({content:''});
    return data;
};

  render() {
    let {threadId} = this.props;

    return <div className="uk-card uk-card-default comp_replywritebox">
                <div className="radio-group-flex">
                  <label uk-tooltip={lng("_NSFWExplanation")}><input type="checkbox" name="isNSFW" checked={this.state.isNSFW} onChange={this.handleCheckboxChange} /> {lng("NSFW Content")} </label>
                  <label uk-tooltip={lng("_InapropriateContentExplanation")}><input type="checkbox" name="isInappropriate" checked={this.state.isInappropriate} onChange={this.handleCheckboxChange} /> {lng("Contains Inappropriate Content")} </label>
                  <label uk-tooltip={lng("_SpoilerExplanation")}><input type="checkbox" name="isSpoiler" checked={this.state.isSpoiler} onChange={this.handleCheckboxChange} /> {lng("Contains Spoilers")} </label>
                </div>
                <WYSIWYGWriter id={"replyThread"} hasSendButton={true}
                    placeHolder={lng("Contéudo do Post")}
                    actions={
                        [
                        'superscript',
                        'link',
                        'image',
                        'youtube',
                        'emoji',
                        'spoiler',
                        'insertOrderedList',
                        'insertUnorderedList'
                        ]} 
                        actionClasses={{
                            insertOrderedList:'list-ol',
                            insertUnorderedList:'list-ul',
                            table:'border-all',
                            spoiler: 'eye-slash',
                            emoji:'grin-squint-tears',
                            youtube:'fab fa-youtube'
                            }}
                        actionTags={{
                            link:'<a ',
                            spoiler:'<span class="spoiler">'
                        }}
                        onUpdateText = {async ()=>{}}
                        onCloseClick = {async ()=>{}}
                        onSendClick = {this.sendThreadReply}
                        startContent = {""}
                />
                </div>
  }
    
}

ThreadReplyWriter.propTypes = {
  threadId: PropTypes.number.isRequired
};
ThreadReplyWriter.defaultProps = {
  pageSize: 10
};


export default ThreadReplyWriter;