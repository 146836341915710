import React, { Component } from 'react';
import "./ThreadCommentOld.css";
import WYSIWYGWriter from './WYSIWYGWriter';
import { genRandId } from './WYSIWYGWriterUtils';
import {lng} from '../ulanguages/language';
import MyAppPool from '../AppPool';
import { PropTypes } from 'prop-types';
import CSSTransition2 from './CSSTransition2';
import HtmlToReact from 'html-to-react';


function delay(t) {
    return new Promise(function(resolve) { 
        setTimeout(resolve, t)
    });
 }

class ThreadComment extends Component {

    constructor(props) {
        super(props);
        this.state = {editing:false,editorEditId:genRandId('the-'), editorId:genRandId('tae-'), respWriterVisible:false};
        this.toggleRespWriter = this.toggleRespWriter.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.sendRespWriterContent = this.sendRespWriterContent.bind(this);
    }

  componentDidMount()
  {
      
  }

  toggleRespWriter()
  {
    this.setState({
        respWriterVisible: !this.state.respWriterVisible
      });
  }
  toggleEditing()
  {
    this.setState({
        editing: !this.state.editing
      });
  }

  async sendRespWriterContent(content){
      let a = await delay(3000); 
      return Promise.reject('error:'+content);
  }
  async sendEditContent(content){
    let a = await delay(3000); 
    return Promise.reject('error:'+content);
  }
  deletePost()
  {
    //  console.log("Potato");
  }

  render(){
    let {thread,currentUserId,currentUserRoles} = this.props;
    let showEditIcons = false;
    if (currentUserId===thread.author.userid || currentUserRoles.indexOf('mabmod')!==-1)
      showEditIcons=true;
    const displayContent =thread.content;
    return (
    <div className="comp_tcomment">
    <div className="answer"> 
        <div className="uk-flex uk-flex-row"> 
        <div className="horizontalCounter"></div>
            <div className="contentSpace">
                <div className="uk-flex uk-flex-row flexAlign"> 
                <img alt={lng("User")+" "+thread.author.name+ " "+lng("avatar")} className="userimganswer" src='https://animesdai.stream/imgs/uploadedAvatar/a9065.jpg?m=1576431748'/>
                    <div>
                    <span className="author">{thread.author.name} {lng('respondeu')} </span>
                    </div>
                </div>
                <div>
                    {!this.state.editing?
                    <p className="postContent"  dangerouslySetInnerHTML={{__html: displayContent}}>
                    </p>
                    :
                    <WYSIWYGWriter id={this.state.editorEditId} 
                    placeHolder={lng("O que esta pensando ?")}
                    hasSendButton={true}
                    actions={
                        [
                        'superscript',
                        'link',
                        'image',
                        'youtube',
                        'emoji',
                        'spoiler',
                        'insertOrderedList',
                        'insertUnorderedList'
                        ]} 
                        actionClasses={{
                            insertOrderedList:'list-ol',
                            insertUnorderedList:'list-ul',
                            table:'border-all',
                            spoiler: 'eye-slash',
                            emoji:'grin-squint-tears',
                            youtube:'fab fa-youtube'
                            }}
                        actionTags={{
                            link:'<a ',
                            spoiler:'<span class="spoiler">'
                        }}
                        onCloseClick = {this.toggleEditing}
                        onSendClick = {this.sendEditContent}
                        startContent = {thread.content}
                            />
                    }
                </div>
                <div>
                    <div className="uk-align-left removeMargin">
                    {thread.upvotes}
                    <span className="fas fa-arrow-up clickable arrowButton"/>
                    <span className="fas fa-arrow-down clickable arrowButton"/>
                    </div>
                    <div className="rightIcons">
                        <span className="iconButton clickable smalllink">  <span className="fas fa-share"/> Compartilhar. </span>
                        <div data-uk-drop="pos: top-center; mode:click" className="uk-card uk-card-default sharePop">
                        <div  className="uk-align-center buttonsPanel">
                            <a target='_blank' rel="noopener noreferrer" href="http://www.facebook.com/share.php?u=https%3A%2F%2Fmyanimelist.net%2Fanime%2F40591%2FKaguya-sama_wa_Kokurasetai__Tensai-tachi_no_Renai_Zunousen" className="uk-button uk-button-default sharebutton"> <span data-uk-icon="icon:  facebook"></span></a>
                            <a target='_blank' rel="noopener noreferrer" href="http://twitter.com/share?related=MyAnimeList.net&via=myanimelist&url=https%3A%2F%2Fmyanimelist.net%2Fanime%2F40591%2FKaguya-sama_wa_Kokurasetai__Tensai-tachi_no_Renai_Zunousen&text=Kaguya-sama%20wa%20Kokurasetai%3F%3A%20Tensai-tachi%20no%20Renai%20Zunousen&hashtags=anime" className="uk-button uk-button-default sharebutton"> <span data-uk-icon="icon:  twitter"> X</span></a>
                            <a target='_blank' rel="noopener noreferrer" href="http://reddit.com/submit?url=https%3A%2F%2Fmyanimelist.net%2Fanime%2F40591%2FKaguya-sama_wa_Kokurasetai__Tensai-tachi_no_Renai_Zunousen&title=Kaguya-sama%20wa%20Kokurasetai%3F%3A%20Tensai-tachi%20no%20Renai%20Zunousen" className="uk-button uk-button-default sharebutton"> <span data-uk-icon="icon:  reddit"></span></a>
                            <a target='_blank' rel="noopener noreferrer" href="http://www.tumblr.com/share/link?url=https%3A%2F%2Fmyanimelist.net%2Fanime%2F40591%2FKaguya-sama_wa_Kokurasetai__Tensai-tachi_no_Renai_Zunousen&name=Kaguya-sama%20wa%20Kokurasetai%3F%3A%20Tensai-tachi%20no%20Renai%20Zunousen" className="uk-button uk-button-default sharebutton"> <span data-uk-icon="icon:  tumblr"></span></a>
                            <a target='_blank' rel="noopener noreferrer" data-uk-tooltip="Clique para copiar" className="uk-button uk-button-default sharebutton clickable"> <span data-uk-icon="icon:  link"></span></a>
                        </div>
                        </div>
                        <span onClick={()=>this.toggleRespWriter()} className="iconButton clickable smalllink">  <span className="fas fa-reply"/> {lng("Resp")}. </span>
                        <span onClick={()=>this.toggleEditing()} className="fas fa-pen iconButton clickable"/>
                        <span onClick={()=>MyAppPool.cModal.set(lng('Deletar Post'),lng('Tem certeza?'),this.deletePost)} className="fas fa-trash-alt iconButton clickable"/>
                    </div>
                </div>
                </div>
            </div> 
        </div>
        <CSSTransition2
            in={this.state.respWriterVisible}
            timeout={300}
            classNames="write-node"
            unmountOnExit
        >
        <WYSIWYGWriter id={this.state.editorId} 
        hasSendButton={true}
        placeHolder={lng("O que esta pensando ?")}
        actions={
            [
            'superscript',
            'link',
            'image',
            'youtube',
            'emoji',
            'spoiler',
            'insertOrderedList',
            'insertUnorderedList'
            ]} 
            actionClasses={{
                insertOrderedList:'list-ol',
                insertUnorderedList:'list-ul',
                table:'border-all',
                spoiler: 'eye-slash',
                emoji:'grin-squint-tears',
                youtube:'fab fa-youtube'
                }}
            actionTags={{
                link:'<a ',
                spoiler:'<span class="spoiler">'
            }}
            onCloseClick = {this.toggleRespWriter}
            onSendClick = {this.sendRespWriterContent}
                />
        </CSSTransition2>    
        </div>
    );
}

}

  ThreadComment.propTypes = {
    thread: PropTypes.shape({
    type:PropTypes.string.isRequired,
    subtype:PropTypes.string.isRequired,
    id:PropTypes.number.isRequired,
    subid:PropTypes.number.isRequired,
    threadid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    upvotes:PropTypes.number.isRequired,
    date:PropTypes.string.isRequired,
    comments:PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        userid: PropTypes.number.isRequired,
        avatarurl: PropTypes.string
      }).isRequired
    }).isRequired,
    currentUserId: PropTypes.number.isRequired,
    currentUserRoles: PropTypes.arrayOf(PropTypes.string)
  };

ThreadComment.defaultProps = {
    thread: {
        threadid:'',
        id:0,
        subid:0,
        name:'',
        url: '',
        comments:[],
        upvotes:0,
        content: '',
        date:'',
        author: {
            name: '',
            userid: 1,
            avatarurl: ''
        }},
        currentUserId: 0,
        currentUserRoles: ['guest']
  };
export default ThreadComment;
