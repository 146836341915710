import { observer } from 'mobx-react';
import "./SearchLinkResourceBox.css";
import "./SearchBox.css";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyAppPool from './../AppPool';
import { isMobile } from './../util/mobile';
import { animeFastResourceType, characterFastResourceType, companyFastResourceType, contentTypeToFastType, getIfIsSetElems, isSet, magazineFastResourceType, mangaFastResourceType, musicFastResourceType, personFastResourceType, userFastResourceType } from '../util/typeu';
import { lng } from '../ulanguages/language';
import { getCharacterRoute, getCompanyRoute, getMagazineRoute, getObraRoute, getPersonRoute, getSearchRoute, getUserRoute } from '../store/AppRoutes';
import Spinner from './Spinner';
import { getCssVar } from '../util/windowUtil';
import { getRelatedTypeIcon, getUserAvatar } from '../util/icon';
import { getImgAddress } from '../util/text';
import SearchCard from './SearchCard';
import { getYoutubeThumbnail } from '../util/youtube';

let pageSize = 5;
@observer
class SearchLinkResourceBox extends Component {
    constructor(props)
    {
        super(props);
        this.delayedSetNameAndFetch = this.delayedSetNameAndFetch.bind(this);
        this.fetchPreview = this.fetchPreview.bind(this);
        this.fetchPreviewAnime = this.fetchPreviewAnime.bind(this);
        this.fetchPreviewManga = this.fetchPreviewManga.bind(this);
        this.fetchPreviewPerson = this.fetchPreviewPerson.bind(this);
        this.fetchPreviewUser = this.fetchPreviewUser.bind(this);
        this.fetchPreviewCharacter = this.fetchPreviewCharacter.bind(this);
        this.fetchPreviewCompany = this.fetchPreviewCompany.bind(this);
        this.fetchPreviewMagazine = this.fetchPreviewMagazine.bind(this);
        this.closePreview = this.closePreview.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);

        let links=[
            {to:getSearchRoute('anime'),text:lng('Animes'),type:animeFastResourceType, fetch:this.fetchPreviewAnime},
            {to:getSearchRoute('manga'),text:lng('Mangas'),type:mangaFastResourceType, fetch:this.fetchPreviewManga},
            {to:getSearchRoute('music'),text:lng('Músicas'),type:musicFastResourceType, fetch:this.fetchPreviewMusic},
            {to:getSearchRoute('pessoa'),text:lng('Pessoas'),type: personFastResourceType, fetch:this.fetchPreviewPerson},
            {to:getSearchRoute('personagem'),text:lng('Personagens'),type:characterFastResourceType, fetch:this.fetchPreviewCharacter},
            {to:getSearchRoute('empresa'),text:lng('Empresas'),type:companyFastResourceType, fetch:this.fetchPreviewCompany},
            {to:getSearchRoute('revista'),text:lng('Revistas'),type:magazineFastResourceType, fetch:this.fetchPreviewMagazine},
            {to:getSearchRoute('usuario'),text:lng('Usuários'),type: userFastResourceType, fetch:this.fetchPreviewUser},
        ];
        this.state={
            searchRoutes:links, 
            currentRoute:0, 
            query:'',
            preview:false,
            fetching:false,
            previewData:[],
            previewReady:false,
            currentPage:0
        };
    }
    handleLocationChange = () => {
        this.closePreview();
    };

    componentDidMount() {
        let history = MyAppPool.cLocation.myReactRouterHistory;
        this.unlisten = history.listen(this.handleLocationChange);
      }
    
      componentWillUnmount() {
        this.unlisten();
      }

    closePreview(){
        this.setState({preview:false});
    }

    addResource = (item) =>{
        this.props.onAdd({type:item.resourceType,id:item.resourceId});
    };

    fetchPreviewMusic = (page=0)=>{
        let {query,searchRoutes,currentRoute} = this.state;
        this.setState({fetching:true,preview:true,previewReady:false});
        MyAppPool.cSearchOthers.getMusicSearch(query,page,pageSize)
        .then(()=>{
            let currentPage=page;
            let searchContent = getIfIsSetElems(MyAppPool.session.search.content,[searchRoutes[currentRoute].type,''+pageSize,currentPage]);
            let pageContent =[];
            if (Array.isArray(searchContent))
            {
                pageContent = searchContent;
                let searchResults = [];
                for(let i=0;i<pageContent.length;i++)
                {
                    let item = pageContent[i];
                    let alwaysShowName = true;
                    let url = '';
                    let name = item.title+' ' + lng("Por") +' '+ item.band;
                    let playItem = item;
                    let img = getYoutubeThumbnail(item.ytid);
                    let searchItem = {
                    isHentai:false,
                    expand:true,
                    playMusicUrl:'',
                    title:item.title,
                    url:url,
                    resourceType:searchRoutes[currentRoute].type,
                    imgurl:img,
                    key:i,
                    gradeable:false,
                    date:'',
                    resourceId:item.id,
                    iconText:'',
                    icon:'',
                    playItem:item,
                    grade:undefined,
                    mode:'list'
                }
                searchResults.push(searchItem);
            }
            this.setState({previewData:searchResults,previewReady:true,previewType:animeFastResourceType,fetching:false,});
            }else{
                this.setState({fetching:false,preview:false});
                MyAppPool.cAlert.notifyGenericError('',"fetch preview person (empty)");
            }
        })
        .catch((e)=>{
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError(e,"fetch preview person error");
        });
    };

    fetchPreviewPerson (page=0){
        let {query,searchRoutes,currentRoute} = this.state;
        this.setState({fetching:true,preview:true,previewReady:false});
        MyAppPool.cSearchOthers.getPersonSearch(query,page,pageSize)
        .then(()=>{
            let currentPage=page;
            let searchContent = getIfIsSetElems(MyAppPool.session.search.content,[searchRoutes[currentRoute].type,''+pageSize,currentPage]);
            let pageContent =[];
            if (Array.isArray(searchContent))
            {
                pageContent = searchContent;
                let searchResults = [];
                for(let i=0;i<pageContent.length;i++)
                {
                    let item = pageContent[i];
                    let url= getPersonRoute(item.id,item.fullname,'#top');
                    let img = getImgAddress(item.mainimg,'/imgs/defaults/people.webp','');
                    let searchItem = {
                    isHentai:false,
                    expand:true,
                    playMusicUrl:'',
                    title:item.fullname,
                    url:url,
                    resourceType:searchRoutes[currentRoute].type,
                    imgurl:img,
                    key:i,
                    gradeable:false,
                    date:'',
                    resourceId:item.id,
                    iconText:'',
                    icon:'',
                    playItem:undefined,
                    grade:undefined,
                    mode:'list'
                }
                searchResults.push(searchItem);
            }
            this.setState({previewData:searchResults,previewReady:true,previewType:animeFastResourceType,fetching:false,});
            }else{
                this.setState({fetching:false,preview:false});
                MyAppPool.cAlert.notifyGenericError('',"fetch preview person (empty)");
            }
        })
        .catch((e)=>{
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError(e,"fetch preview person error");
        });
    };

    fetchPreviewUser (page=0){
        let {query,searchRoutes,currentRoute} = this.state;
        this.setState({fetching:true,preview:true,previewReady:false});
        MyAppPool.cSearchOthers.getUserSearch(query,page,pageSize)
        .then(()=>{
            let currentPage=page;
            let searchContent = getIfIsSetElems(MyAppPool.session.search.content,[searchRoutes[currentRoute].type,''+pageSize,currentPage]);
            let pageContent =[];
            if (Array.isArray(searchContent))
            {
                pageContent = searchContent;
                let searchResults = [];
                for(let i=0;i<pageContent.length;i++)
                {
                    let item = pageContent[i];
                    let url= getUserRoute(item.id,item.name,'#top');
                    let img = getUserAvatar(item.mainimg,item.mailhash,'');
                    let searchItem = {
                    isHentai:false,
                    expand:true,
                    playMusicUrl:'',
                    title:item.name,
                    url:url,
                    resourceType:searchRoutes[currentRoute].type,
                    imgurl:img,
                    key:i,
                    gradeable:false,
                    date:'',
                    resourceId:item.id,
                    iconText:'',
                    icon:'',
                    playItem:undefined,
                    grade:undefined,
                    mode:'list'
                }
                searchResults.push(searchItem);
            }
            this.setState({previewData:searchResults,previewReady:true,previewType:animeFastResourceType,fetching:false,});
            }else{
                this.setState({fetching:false,preview:false});
                MyAppPool.cAlert.notifyGenericError('',"fetch preview user (empty)");
            }
        })
        .catch((e)=>{
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError(e,"fetch preview user error");
        });
    };

    fetchPreviewCharacter (page=0){
        let {query,searchRoutes,currentRoute} = this.state;
        this.setState({fetching:true,preview:true,previewReady:false});
        MyAppPool.cSearchOthers.getCharacterSearch(query,page,pageSize)
        .then(()=>{
            let currentPage=page;
            let searchContent = getIfIsSetElems(MyAppPool.session.search.content,[searchRoutes[currentRoute].type,''+pageSize,currentPage]);

            let pageContent =[];
            if (Array.isArray(searchContent))
            {
                pageContent = searchContent;
                let searchResults = [];
                for(let i=0;i<pageContent.length;i++)
                {
                    let item = pageContent[i];
                    let url=getCharacterRoute(item.id,item.fullname,'#top');
                    let img = getImgAddress(item.mainimg,'/imgs/defaults/people.webp','');
                    let searchItem = {
                    isHentai:false,
                    expand:true,
                    playMusicUrl:'',
                    title:item.fullname,
                    url:url,
                    resourceType:searchRoutes[currentRoute].type,
                    imgurl:img,
                    key:i,
                    gradeable:false,
                    date:'',
                    resourceId:item.id,
                    iconText:'',
                    icon:'',
                    playItem:undefined,
                    grade:undefined,
                    mode:'list'
                }
                searchResults.push(searchItem);
            }
            this.setState({previewData:searchResults,previewReady:true,previewType:animeFastResourceType,fetching:false,});
            }else{
                this.setState({fetching:false,preview:false});
                MyAppPool.cAlert.notifyGenericError('',"fetch preview character (empty)");
            }
        })
        .catch((e)=>{
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError(e,"fetch preview character");
        });
    }; 
    fetchPreviewCompany (page=0){
        let {query,searchRoutes,currentRoute} = this.state;
        this.setState({fetching:true,preview:true,previewReady:false});
        MyAppPool.cSearchOthers.getCompanySearch(query,page,pageSize)
        .then(()=>{
            let currentPage=page;
            let searchContent = getIfIsSetElems(MyAppPool.session.search.content,[searchRoutes[currentRoute].type,''+pageSize,currentPage]);
            let pageContent =[];
            if (Array.isArray(searchContent))
            {
                pageContent = searchContent;
                let searchResults = [];
                for(let i=0;i<pageContent.length;i++)
                {
                    let item = pageContent[i];
                    let url=getCompanyRoute(item.id,item.name,'#top');
                    let img = getImgAddress(item.mainimg,'/imgs/defaults/company.webp','');
                    let searchItem = {
                    isHentai:false,
                    expand:true,
                    playMusicUrl:'',
                    title:item.name,
                    url:url,
                    resourceType:searchRoutes[currentRoute].type,
                    imgurl:img,
                    key:i,
                    gradeable:false,
                    date:'',
                    resourceId:item.id,
                    iconText:'',
                    icon:'',
                    playItem:undefined,
                    grade:undefined,
                    mode:'list'
                }
                searchResults.push(searchItem);
            }
            this.setState({previewData:searchResults,previewReady:true,previewType:animeFastResourceType,fetching:false,});
            }else{
                this.setState({fetching:false,preview:false});
                MyAppPool.cAlert.notifyGenericError('',"fetch preview company (empty)");
            }
        })
        .catch((e)=>{
          //  console.log("MYERR:",e);
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError(e,"fetch preview company");
        });
    };
    fetchPreviewMagazine (page=0){
        let {query,searchRoutes,currentRoute} = this.state;
        this.setState({fetching:true,preview:true,previewReady:false});
        MyAppPool.cSearchOthers.getPublisherSearch(query,page,pageSize)
        .then(()=>{
            let currentPage=page;
            let searchContent = getIfIsSetElems(MyAppPool.session.search.content,[searchRoutes[currentRoute].type,''+pageSize,currentPage]);
            let pageContent =[];
            if (Array.isArray(searchContent))
            {
                pageContent = searchContent;
                let searchResults = [];
                for(let i=0;i<pageContent.length;i++)
                {
                    let item = pageContent[i];
                    let url=getMagazineRoute(item.id,item.name,'#top');
                    let img = getImgAddress(item.mainimg,'/imgs/defaults/company.webp','');
                    let searchItem = {
                    isHentai:false,
                    expand:true,
                    playMusicUrl:'',
                    title:item.name,
                    url:url,
                    resourceType:searchRoutes[currentRoute].type,
                    imgurl:img,
                    key:i,
                    gradeable:false,
                    date:'',
                    resourceId:item.id,
                    iconText:'',
                    icon:'',
                    playItem:undefined,
                    grade:undefined,
                    mode:'list'
                }
                searchResults.push(searchItem);
            }
            this.setState({previewData:searchResults,previewReady:true,previewType:animeFastResourceType,fetching:false,});
            }else{
                this.setState({fetching:false,preview:false});
                MyAppPool.cAlert.notifyGenericError('',"fetch preview magazine (empty)");
            }
        })
        .catch((e)=>{
          //  console.log("MYERR:",e);
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError(e,"fetch preview magazine");
        });
    }; 

    fetchPreviewAnime(page=0){
        let {query,searchRoutes,currentRoute} = this.state;
        this.setState({fetching:true,preview:true,previewReady:false});
        MyAppPool.cSearchAnimeManga.getAnimeSearch(query,'','','','','','','','')
        .then(()=>{
            let currentPage=page;
            let currentPageRangeBottom=currentPage*pageSize;
            let currentPageRangeTop=currentPageRangeBottom + pageSize;
            let searchContent = MyAppPool.session.search.content[searchRoutes[currentRoute].type];
            let pageContent =[];
            if (Array.isArray(searchContent))
            {
                pageContent = searchContent.slice(currentPageRangeBottom,currentPageRangeTop);
                let searchResults = [];
                for(let i=0;i<pageContent.length;i++)
                {
                    let item = pageContent[i];
                    let url=getObraRoute(item.info.id,item.titleList[0],'anime','#top');
                    let img = getImgAddress(item.info.mainimg,'/imgs/defaults/anime.webp','');
                    let isHentai=false;
                    if (item.genres.indexOf('Hentai')!==-1)
                        isHentai=true;
                    if (isHentai)
                        continue;
                    let searchItem = {alwaysShowName:false,
                    isHentai:isHentai,
                    expand:true,
                    playMusicUrl:'',
                    title:item.titleList[0],
                    url:url,
                    resourceType:searchRoutes[currentRoute].type,
                    imgurl:img,
                    key:i,
                    gradeable:true,
                    genres:item.genreList,
                    date:item.info.airfrom,
                    resourceId:item.info.id,
                    iconText:lng(item.info.type),
                    icon:getRelatedTypeIcon(item.info.type),
                    playItem:undefined,
                    grade:undefined,
                    mode:'list'
                }
                searchResults.push(searchItem);
            }
            this.setState({previewData:searchResults,previewReady:true,previewType:animeFastResourceType,fetching:false,});
            }else{
                this.setState({fetching:false,preview:false});
                MyAppPool.cAlert.notifyGenericError('',"fetch preview anime (empty)");
            }
        })
        .catch((e)=>{
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError(e,"fetch preview anime ");
        });
    }
    fetchPreviewManga(page=0){
        let {query,searchRoutes,currentRoute} = this.state;
        this.setState({fetching:true,preview:true,previewReady:false});

        MyAppPool.cSearchAnimeManga.getMangaSearch(query,'','','','','','','','')
        .then(()=>{
            let currentPage=page;
            let currentPageRangeBottom=currentPage*pageSize;
            let currentPageRangeTop=currentPageRangeBottom + pageSize;
            let searchContent = MyAppPool.session.search.content[searchRoutes[currentRoute].type];
            let pageContent =[];
            if (Array.isArray(searchContent))
            {
                pageContent = searchContent.slice(currentPageRangeBottom,currentPageRangeTop);
                let searchResults = [];
                for(let i=0;i<pageContent.length;i++)
                {
                    let item = pageContent[i];
                    let url=getObraRoute(item.info.id,item.titleList[0],'manga','#top');
                    let img = getImgAddress(item.info.mainimg,'/imgs/defaults/manga.webp','');
                    let isHentai=false;
                    if (item.genres.indexOf('Hentai')!==-1)
                    isHentai=true;
                    if (isHentai)
                        continue;
                    let searchItem = {alwaysShowName:false,
                    isHentai:isHentai,
                    expand:true,
                    playMusicUrl:'',
                    title:item.titleList[0],
                    url:url,
                    resourceType:searchRoutes[currentRoute].type,
                    imgurl:img,
                    key:i,
                    gradeable:true,
                    genres:item.genreList,
                    date:item.info.airfrom,
                    resourceId:item.info.id,
                    iconText:lng(item.info.type),
                    icon:getRelatedTypeIcon(item.info.type),
                    playItem:undefined,
                    grade:undefined,
                    mode:'list'
                }
                searchResults.push(searchItem);
            }
            this.setState({previewData:searchResults,previewReady:true,previewType:animeFastResourceType,fetching:false,});
            }else{
                this.setState({fetching:false,preview:false});
                MyAppPool.cAlert.notifyGenericError('',"fetch preview manga (empty)");
            }
        })
        .catch((e)=>{
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError(e,"fetch preview manga");
        });
    }
    fetchPreview(page=0){
        let {searchRoutes,currentRoute} = this.state;
        return searchRoutes[currentRoute].fetch(page);
    }



    delayedSetNameAndFetch(val)
    {
      let delayTime=500;
      
      let updateFunc = ()=>{
        let {query,fetching} = this.state;
        if (query==='')
            this.setState({preview:false});
        if (fetching)
            return;
        if (query===val)
                this.fetchPreview();
        else
            setTimeout(updateFunc,delayTime);
      }
      setTimeout(updateFunc,delayTime);
      this.setState({query:val});
    }

    render()
    {
        //onChange={(e)=>{)} 
        let {searchRoutes,preview,fetching,previewData,previewReady,previewType,query} = this.state;
        return <div className="comp_SearchLinkResourceBox  ">
            <div className="uk-navbar-item searchInputs">
            <div className="uk-search uk-search-navbar uk-width-1-1 uk-flex uk-flex-row">
                <input value={this.state.query} onChange={(e) => {
                    this.setState({currentPage:0});
                    this.delayedSetNameAndFetch(e.target.value);
                    }} className="uk-search-input searchInput" type="search" placeholder={lng("Buscar...")} autoFocus/>
                <select aria-label={lng("Selecionador de Tipo de Busca")} onChange={(event)=>this.setState({currentRoute:event.target.value})} className="searchSelect clickable">
                {searchRoutes.map((item, index) => {
                return <option value={index}>{item.text}</option>
                })} 
                </select>
            </div>
           
            </div>
            <div className="dropWrapper">
            {preview?
                <div className="dropCloseFrame" onClick={()=>this.closePreview()}></div>:<></>}
                {preview?
                <div className="uk-card uk-card-default dropBox linkresDrop">
                    
                    <button aria-label={lng("Anterior")}  onClick={()=>{
                            if (this.state.currentPage<=0)
                                return;
                            this.fetchPreview(this.state.currentPage-1);
                            this.setState({currentPage:this.state.currentPage-1});
                          }} className="uk-button uk-button-default closeBtn" value={lng("Anterior")}>{lng("Anterior")}</button>
                          <button aria-label={lng("Próximo")}  onClick={()=>{
                            this.fetchPreview(this.state.currentPage+1);
                            this.setState({currentPage:this.state.currentPage+1});
                          }} className="uk-button uk-button-default closeBtn" value={lng("Próximo")}>{lng("Próximo")}</button>
                          
                          <button aria-label={lng("Fechar")}  onClick={()=>this.closePreview()} className="uk-button uk-button-default closeBtn" value={lng("Fechar")}>{lng("Fechar")}</button>
                          <div style={{marginBottom:"14px"}}></div>
                    {fetching?
                    <Spinner color={getCssVar('--loading-spinner-text-color')}/>
                    :<></>}
                    {previewReady?<div>
                            {previewData.map((item, index) => {
                              return <div onClick={()=>this.addResource(item)} key={index} className="searchItemResult linkresSearchRes clickable">
                                <span className="linKResAddBtn">Add:</span>
                                <SearchCard 
                                  {...item} removeLinks={true} hideActionButtons={true}
                                />
                              </div>
                            })}
                            {previewData.length===0?<div>{lng("Nenhum resultado encontrado para ")}"{query}"</div>:<></>}
                            </div>:<></>
                          }
                          
                          
                </div>
                :<></>}
            </div>
        </div>
    }
}

SearchLinkResourceBox.propTypes = {
};
SearchLinkResourceBox.defaultProps = {
    onAdd: (element) =>{ console.log(element.type,element.id)}
};
export default SearchLinkResourceBox;
