import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
import './ThreadPinned.css'; // Ensure this file exists for styling
import { mobToObj, userFastResourceType } from '../util/typeu';
import MyAppPool from '../AppPool';
import { getImgAddress } from '../util/text';
import { getUserAvatar } from '../util/icon';
import { observer } from 'mobx-react';
import htmlToReact from 'html-to-react';
import { lng } from '../ulanguages/language';
import { timeSinceString } from '../util/timeUtil';
import ThreadTag from './ThreadTag';
import LinkV2 from './LinkV2';
import { getForumThreadPage, getUserRoute } from '../store/AppRoutes';
import TitleBadge from './TitleBadge';
import ThreadUnpinModal from './ThreadUnpinModal';

function getPreview(displayContent) {
  const text = displayContent.replace(/<[^>]+>/g, '');
  const preview = text.substring(0, 200);
  return preview.length === 200 ? preview + '...' : preview;
}

@observer
class ThreadPinned extends Component {
  // Helper function to format the relative time
  constructor(props) {
    super(props);
    this.state = {
      revealContent: false,
      revealHovered: false
    };
  }
  

  renderMedia(content) {
    const imgMatch = content.match(/<img.*?src="(.*?)"/);
    if (imgMatch) {
      return <img className="thread-image" src={imgMatch[1]} alt="Thread Media" />;
    }

    const youtubeMatch = content.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
    );
    if (youtubeMatch) {
      return (
        <iframe
          className="thread-video"
          src={`https://www.youtube.com/embed/${youtubeMatch[1]}`}
          title="YouTube Video"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      );
    }

    return null;
  }
  unpin = () => {
    const { thread } = this.props;
    MyAppPool.cModal.set(lng('Unpin'),<ThreadUnpinModal threadId={thread.thread.id}/>,null,'',null);
  };

  render() {
    const { thread } = this.props;
    let { title, content, datecreated, repliesAmount, views,isNSFW,isInappropriate,isSpoiler } = thread.thread.properties;
    const { id } = thread.thread;
    const { tags,user,section } = thread;
    const {revealContent, revealHovered} = this.state;
    //let sectionName = thread.section.properties.name;
    //let sectionId = thread.section.id;
    //title = "Really Big Title That Should Make Things Harder for The Dev Who ...";
    let useTitle = title.slice(0,64);
    if (useTitle.length !== title.length)
      useTitle+='...';
    let useTags = [];
    if (Array.isArray(tags)) {
      useTags = tags;
    }
    let mod = MyAppPool.cNeoComu.isModeratorOf(section.id);
    //let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+userFastResourceType][''+user.id]);
    //sampleAuthor.titles = sampleAuthor.titles.slice(0,2);

    return (
      <div  className=" uk-card uk-card-default compthread-pinned">
        <LinkV2 to={getForumThreadPage(id,title,"","#threadTop")} >
            <span className="thread-title" data-uk-tooltip={title}>{useTitle} </span>
            <i className="fas fa-thumbtack pin" ></i> 
        </LinkV2>
        {mod? <i className="fas fa-thumbtack link-button" style={{color:"red"}} onClick={this.unpin}></i>:<></> }
      </div>
    );
  }
}

ThreadPinned.propTypes = {
  thread: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    datecreated: PropTypes.number.isRequired,
    repliesAmount: PropTypes.number.isRequired,
    views: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      properties: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,
};

export default ThreadPinned;