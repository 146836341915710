
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { lng } from '../../ulanguages/language';
import { contentTypeToFastType } from '../../util/typeu';
import ReactionsCard from '../../view_component/ReactionsCard';
import RelatedWorks from '../../view_component/RelatedWorks';
import MyAppPool from './../../AppPool';
import { isMobile } from './../../util/mobile';
import ColumnCard from './../../view_component/ColumnCard';
import ScrollToTopButtom from './../../view_component/ScrollToTopButton';
import SkeletonBlock from './../../view_component/SkeletonBlock';
import './CharacterGeralView.css';
import { toJS } from 'mobx';
import AmazonCarrousel from '../../view_component/AmazonCarrousel';
import LinkV2 from '../../view_component/LinkV2';
import { getForumCreateThreadPage, getSupportPage } from '../../store/AppRoutes';
import ForumThreadsByTagBox from '../../view_component/ForumThreadsByTagBox';
import { getCurrentUrl } from '../../util/windowUtil';
import { getRouteParamAtIndex } from '../../util/text';

@observer
class CharacterGeralView extends Component {

  constructor(props) {
    super(props);
    this.state = { 
    };
    }

    render()
    {
      let {current} = this.props;
      let reactionPosts = [];
      let reactionReady=0;
      let pageId=getRouteParamAtIndex(getCurrentUrl(),2);
      if (isNaN(Number(pageId)))
        pageId=0;
      let pageContentName='';
      let resourceType = contentTypeToFastType('character');
      let reactionTabUrl = '';
      let amazonTitle = <span>{lng("Presentes para seus amados")}<LinkV2 to={getSupportPage()}>{lng("#patrocionado")}</LinkV2></span>;

      //console.log("CURR:",toJS(current.columItems));

      if (current.ready)
      {
        resourceType = current.resourceType;
        reactionPosts = MyAppPool.session.reactionPost[resourceType][current.id];

        reactionReady = 1;
        if (typeof reactionPosts ==='undefined')
          reactionPosts=[];

        let tabBaseUrl = current.baseUrl;
        reactionTabUrl = tabBaseUrl+'/'+lng('social');

        pageId=current.id;
        pageContentName = current.fullName;
        }
        
        if (!isMobile.any)//DESKTOP LAYOUT
          return (<div className='character_geral_view'>
          <div className="contentArea uk-flex uk-flex-row">
        
        <div className="leftContent">
          {
          <ColumnCard
          ready = {current.ready}
          items={current.columItems }/>}
        </div>

        <div className="middleContent">

          {current.ready? 
          <>
          <div style={{width:"100%"}}>
            <ReactionsCard
                currentUserId={MyAppPool.currentUser.userid}
                currentUserRoles={MyAppPool.currentUser.roles} //mabmod
                ready={reactionReady}
                items={reactionPosts}
                resourceType={resourceType}
                resourceId={pageId}
                resourceName={current.name}
                goToUrl={reactionTabUrl}
             />
          </div>
          
            <RelatedWorks 
            imgSize='x50'
                title={lng("Atores / Atrizes")} 
                notFoundText={lng('Nenhum encontrado, conhece um ? Adicione!')}
                notFoundButtonText={lng('Adicionar')}
                items={current.relVoiceActors} 
                showItemNumber={6}
                hideIfEmpty={1}
                ready={current.ready}  height={272}/>
            <div style={{marginTop:"15px"}}>
              <AmazonCarrousel 
                title={amazonTitle}
                query={MyAppPool.cAffiliate.getFigureQuery(current.fullName)}
                showItemNumber={5}
                height={272}
                />
          </div>
            <RelatedWorks 
            imgSize='x50'
                title={lng("Anime")} 
                notFoundText={lng('Nenhuma obra encontrada, conhece uma ? Adicione!')}
                notFoundButtonText={lng('Adicionar')}
                items={current.anime} 
                showItemNumber={6}
                hideIfEmpty={1}
                ready={current.ready}  height={272}/>
            <RelatedWorks 
            imgSize='x50'
                title={lng("Manga")} 
                notFoundText={lng('Nenhuma obra encontrada, conhece uma ? Adicione!')}
                notFoundButtonText={lng('Adicionar')}
                items={current.manga} 
                showItemNumber={6}
                hideIfEmpty={1}
                ready={current.ready}  height={272}/>
          </>
          :
          <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
          </div>}
          
          <div style={{marginTop:"15px"}}>
            <h3>{lng("Latest Discussions")}
            <LinkV2 to={getForumCreateThreadPage("?tagNames="+"lnkres_"+resourceType+"_"+pageId,"#top")} className="uk-button uk-button-default  sendButton">{lng("Criar uma Thread")}</LinkV2>
            </h3>
            <ForumThreadsByTagBox tag={"lnkres_"+resourceType+"_"+pageId}/>
          </div>
        </div>
        
        <div className="rightContent">
          <div style={{width:"100%"}}>
            <div  style={{marginTop:"15px"}}>
              <ScrollToTopButtom />
            </div>
          </div>
        </div>
      

      </div>
      </div>);

    if (isMobile.any)//MOBILE LAYOUT
      return (<div className='character_geral_view'>
      <div className="contentArea uk-flex uk-flex-row">

      <div className="middleContent">
      <div style={{marginTop:"15px"}}>
        <ColumnCard
            ready = {current.ready}
            items={current.columItems }/>
        </div>
      <div style={{marginTop:"15px"}}>
        <ReactionsCard
                  currentUserId={MyAppPool.currentUser.userid}
                  currentUserRoles={MyAppPool.currentUser.roles} //mabmod
                  ready={reactionReady}
                  items={reactionPosts}
                  resourceType={resourceType}
                  resourceId={pageId}
                  resourceName={current.name}
                  goToUrl={reactionTabUrl}
              />
        </div>
        <div style={{marginTop:"15px"}}>
        {current.ready? 
            <>
             <RelatedWorks 
             imgSize='x50'
                title={lng("Atores / Atrizes")} 
                notFoundText={lng('Nenhum encontrado, conhece um ? Adicione!')}
                notFoundButtonText={lng('Adicionar')}
                items={current.relVoiceActors} 
                showItemNumber={3}
                hideIfEmpty={1}
                ready={current.ready}  height={272}/>
            <div style={{marginTop:"15px",marginBottom:"24px"}}>
              <AmazonCarrousel 
                title={amazonTitle}
                query={MyAppPool.cAffiliate.getFigureQuery(current.fullName)}
                showItemNumber={3}
                height={272}
                />
          </div>
            <RelatedWorks 
            imgSize='x50'
                title={lng("Anime")} 
                notFoundText={lng('Nenhuma obra encontrada, conhece uma ? Adicione!')}
                notFoundButtonText={lng('Adicionar')}
                items={current.anime} 
                showItemNumber={3}
                hideIfEmpty={1}
                ready={current.ready}  height={272}/>
            <RelatedWorks 
            imgSize='x50'
                title={lng("Manga")} 
                notFoundText={lng('Nenhuma obra encontrada, conhece uma ? Adicione!')}
                notFoundButtonText={lng('Adicionar')}
                items={current.manga} 
                showItemNumber={3}
                hideIfEmpty={1}
                ready={current.ready}  height={272}/>
          </>
            :
            <div>
              <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
              <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
              <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            </div>}
        </div>

        <div style={{marginTop:"15px"}}>
            <h3>{lng("Latest Discussions")}
            <LinkV2 to={getForumCreateThreadPage("?tagNames="+"lnkres_"+resourceType+"_"+pageId,"#top")} className="uk-button uk-button-default  sendButton">{lng("Criar uma Thread")}</LinkV2>
            </h3>
            <ForumThreadsByTagBox tag={"lnkres_"+resourceType+"_"+pageId}/>
        </div>
        
        
      </div>

      </div>
      </div>);


    }

    
}

export default CharacterGeralView;