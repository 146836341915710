
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { lng } from '../../ulanguages/language';
import { contentTypeToFastType } from '../../util/typeu';
import ChartCategoryHorizontal from '../../view_component/ChartCategoryHorizontal';
import ChartVerticalBar from '../../view_component/ChartVerticalBar';
import ColumnCard from '../../view_component/ColumnCard';
import GradeBox from '../../view_component/GradeBox';
import ReactionsCard from '../../view_component/ReactionsCard';
import RelatedWorks from '../../view_component/RelatedWorks';
import ReviewCard from '../../view_component/ReviewCard';
import MyAppPool from './../../AppPool';
import { isMobile } from './../../util/mobile';
import './MangaGeralView.css';
import AmazonCarrousel from '../../view_component/AmazonCarrousel';
import LinkV2 from '../../view_component/LinkV2';
import { getForumCreateThreadPage, getSupportPage } from '../../store/AppRoutes';
import ExternalLinksBox from '../../view_component/ExternalLinksBox';
import TagsColumn from '../../view_component/TagsColumn';
import ForumThreadsByTagBox from '../../view_component/ForumThreadsByTagBox';
import { getRouteParamAtIndex } from '../../util/text';
import { getCurrentUrl } from '../../util/windowUtil';

@observer
class MangaGeralView extends Component {

    render()
    {
      let {current} = this.props;
      let gradeBoxInfo = {grade:0,votes:0};
      let verticalChartValues = [];
      let followCountChart = [];
      let reviews = [];
      let reactionPosts = [];
      let reactionReady=0;
      let reviewsReady=0;
      let pageId=getRouteParamAtIndex(getCurrentUrl(),2);
      if (isNaN(Number(pageId)))
        pageId=0;
      let pageContentName='';
      let resourceType = contentTypeToFastType('manga');
      let reviewTabUrl = '';
      let reactionTabUrl = '';
      let amazonTitle = <span>{lng("Presentes para seus amados")}<LinkV2 to={getSupportPage()}>{lng("#patrocionado")}</LinkV2></span>;

      if (current.ready)
      {
        resourceType = current.resourceType;
        reviews = MyAppPool.session.review[resourceType][current.id];
        reactionPosts = MyAppPool.session.reactionPost[resourceType][current.id];
        
        if (typeof reviews ==='undefined')
        {
          reviews = [];
          reviewsReady=0;
        }else
          reviewsReady=1;

        if (typeof reactionPosts ==='undefined')
        {
          reactionPosts=[];
          reactionReady = 0;
        }else
          reactionReady = 1;

        let tabBaseUrl = current.baseUrl;
        reviewTabUrl = tabBaseUrl+'/'+lng('reviews');
        reactionTabUrl = tabBaseUrl+'/'+lng('social');

        pageId=current.id;
        pageContentName = current.mainTitle;
        gradeBoxInfo.grade = current.grades[0].averagegrade;
        gradeBoxInfo.votes = current.grades[0].totalvotes;
        verticalChartValues = 
          [
            {value:current.grades[0].grade1,text:'<50-59',gradient:"linear-gradient(#FF6A00, #FF0000)"},
            {value:current.grades[0].grade2,text:'60-69',gradient:"linear-gradient(#FFD800, #FF6A00)"},
            {value:current.grades[0].grade3,text:'70-79',gradient:"linear-gradient(#B6FF00, #FFD800)"},
            {value:current.grades[0].grade4,text:'80-89',gradient:"linear-gradient(#00FF21, #99ff00)"},
            {value:current.grades[0].grade5,text:'90-100',gradient:"linear-gradient(#00FF21, #4CFF00)"}
          ];
          followCountChart = [
            {text:lng("Querem"), value:current.grades[0].countwant, color:"linear-gradient(#6600ff, #9999ff)"},
            {text:lng("Leem"), value:current.grades[0].countcurrent, color:"linear-gradient(#0099ff, #66ccff)"},
            {text:lng("Concluiram"), value:current.grades[0].countfinished, color:"linear-gradient(#4dd520, #99ff99)"},
            {text:lng("Largaram"), value:current.grades[0].countdropped, color:"linear-gradient(#669999, #d6f0f0)"}
          ]
        }
        
        if (!isMobile.any)//DESKTOP LAYOUT
          return (<div className="contentArea uk-flex uk-flex-row manga_geral_view">
        
        <div className="leftContent">
          <ColumnCard
          ready = {current.ready}
          items={current.columItems }/>
          <ExternalLinksBox items= {current.externalLinks} title={lng("Links Externos e Streams")}/>
          <TagsColumn items={current.tags} />
        </div>

        <div className="middleContent">
          <div className="uk-flex uk-flex-row">
            <div style={{width:"20%"}}> 
              <GradeBox
              value={gradeBoxInfo.grade}
              amount={gradeBoxInfo.votes}
              ready={current.ready}
              title={lng('Nota')}
              bywho={lng('usuários')}
              resourceId={pageId}
              resourceType={resourceType}
              mySelectedGrade={MyAppPool.session.user.grades[resourceType][pageId]}
              />
            </div>
            <div  style={{width:"80%",marginLeft:"15px"}}>
              <ChartVerticalBar
              ready={current.ready}
              items = {verticalChartValues}
              />
            </div>
          </div>
          <div  style={{marginTop:"15px"}}>
          <ChartCategoryHorizontal 
              ready={current.ready}
              items = {followCountChart}
              />
          </div>
          <div  style={{marginTop:"15px"}}>
            <ReactionsCard
                currentUserId={MyAppPool.currentUser.userid}
                currentUserRoles={MyAppPool.currentUser.roles} //mabmod
                ready={reactionReady}
                items={reactionPosts}
                resourceType={resourceType}
                resourceId={pageId}
                resourceName={current.mainTitle}
                goToUrl={reactionTabUrl}
                  />
          </div>
                <div style={{marginTop:"15px"}}>
                  <ReviewCard
                  ready={reviewsReady}
                  contentName={pageContentName}
                  resourceType='manga'
                  resourceId={pageId}
                  currentUserId={MyAppPool.currentUser.userid}
                  currentUserRoles={MyAppPool.currentUser.roles} //mabmod
                  items = {reviews}
                  cats={[
                    {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
                    {name:lng('Consistencia'),icons:["fab fa-confluence"]},
                    {name:lng('Personagens'),icons:['fas fa-running','fas fa-hiking','fas fa-skating']},
                    {name:lng('História'),icons:['fas fa-pen']}
                  ]}
                  goToUrl={reviewTabUrl}/>
                </div>
          <div style={{marginTop:"15px"}}>
                <RelatedWorks 
                imgSize='x50'
                  title={lng("Personagens")} 
                  notFoundText={lng('Nenhum personagem encontrado, conhece um ? Adicione!')}
                  notFoundButtonText={lng('Adicionar Personagem')}
                  items={current.relCharacters} 
                  hideIfEmpty={1}
                  showItemNumber={5}
                  ready={current.ready}  height={272}/>
              </div>
              <div style={{marginTop:"15px"}}>
                <AmazonCarrousel 
                title={amazonTitle}
                query={MyAppPool.cAffiliate.getMangaQuery(current)}
                showItemNumber={5}
                height={272}
                />
                </div>
          <div style={{marginTop:"15px"}}>
                <RelatedWorks 
                imgSize='x50'
                title={lng("Obras Relacionadas")} 
                notFoundText={lng('Nenhuma obra relacionada encontrada, conhece uma ? Adicione!')}
                notFoundButtonText={lng('Adicionar Obras Relacionadas')}
                items={current.relRelatedWorks} 
                hideIfEmpty={1}
                showItemNumber={5}
                ready={current.ready}  height={272}/>
              </div>
          
                <div style={{marginTop:"15px"}}>
              <RelatedWorks 
              imgSize='x50'
                title={lng("Autores")} 
                notFoundText={lng('Nenhuma pessoa, conhece uma ? Adicione!')}
                notFoundButtonText={lng('Adicionar pessoas')}
                items={current.relPeople} 
                hideIfEmpty={1}
                showItemNumber={5}
                ready={current.ready}  height={272}/>
              </div>
              <div style={{marginTop:"15px"}}>
              
                <AmazonCarrousel 
                title={amazonTitle}
                query={MyAppPool.cAffiliate.getFigureQuery(current.engTitle)}
                showItemNumber={5}
                height={272}
                />
          </div>

          <div style={{marginTop:"15px"}}>
            <h3>{lng("Latest Discussions")}
            <LinkV2 to={getForumCreateThreadPage("?tagNames="+"lnkres_"+resourceType+"_"+pageId,"#top")} className="uk-button uk-button-default  sendButton">{lng("Criar uma Thread")}</LinkV2>
            </h3>
            <ForumThreadsByTagBox tag={"lnkres_"+resourceType+"_"+pageId}/>
          </div>

          </div>
          
          
        
        <div style={{'display':'none'}} className="rightContent">
        </div>
      

      </div>);

    if (isMobile.any)//MOBILE LAYOUT
      return (<div className="contentArea uk-flex uk-flex-row">
      <div className="middleContent">
      <div className="uk-flex uk-flex-row">
          <div style={{width:"20%"}}> 
          <GradeBox
              value={gradeBoxInfo.grade}
              amount={gradeBoxInfo.votes}
              ready={current.ready}
              title={lng('Nota')}
              bywho={lng('usuários')}
              resourceId={pageId}
              resourceType={resourceType}
              mySelectedGrade={MyAppPool.session.user.grades[resourceType][pageId]}
              />
          </div>
          <div  style={{width:"80%",marginLeft:"15px"}}>
            <ChartCategoryHorizontal 
            ready={current.ready}
            items = {followCountChart}
            />
          </div>
        </div>
        <div  style={{marginTop:"15px"}}>
        <ChartVerticalBar
        ready={current.ready}
        items = {verticalChartValues}
        />
        </div>
        <div  style={{marginTop:"15px"}}>
          <ReactionsCard
              currentUserId={MyAppPool.currentUser.userid}
              currentUserRoles={MyAppPool.currentUser.roles} //mabmod
              ready={reactionReady}
              items={reactionPosts}
              resourceType={resourceType}
              resourceId={pageId}
              resourceName={current.mainTitle}
              goToUrl={reactionTabUrl}
                />
        </div>
        <div style={{marginTop:"15px"}}>
                <ReviewCard
                ready={reviewsReady}
                contentName={pageContentName}
                resourceType='anime'
                resourceId={pageId}
                currentUserId={MyAppPool.currentUser.userid}
                currentUserRoles={MyAppPool.currentUser.roles} //mabmod
                items = {reviews}
                goToUrl={reviewTabUrl}/>
              </div>
            <div style={{marginTop:"15px"}}>
              <RelatedWorks 
              imgSize='x50'
              title={lng("Obras Relacionadas")} 
              notFoundText={lng('Nenhuma obra relacionada encontrada, conhece uma ? Adicione!')}
              notFoundButtonText={lng('Adicionar Obras Relacionadas')}
              items={current.relRelatedWorks} 
              hideIfEmpty={1}
              ready={current.ready}  height={272}/>
            </div>
            <div style={{marginTop:"15px"}}>
              <RelatedWorks 
              imgSize='x50'
                title={lng("Personagens")} 
                notFoundText={lng('Nenhum personagem encontrado, conhece um ? Adicione!')}
                notFoundButtonText={lng('Adicionar Personagem')}
                items={current.relCharacters} 
                hideIfEmpty={1}
                ready={current.ready}  height={272}/>
            </div>
            <div style={{marginTop:"15px"}}>
                <AmazonCarrousel 
                title={amazonTitle}
                query={MyAppPool.cAffiliate.getMangaQuery(current)}
                showItemNumber={3}
                height={272}
                />
          </div>
            <div style={{marginTop:"15px"}}>
            <RelatedWorks 
            imgSize='x50'
              title={lng("Autores")} 
              notFoundText={lng('Nenhuma pessoa, conhece uma ? Adicione!')}
              notFoundButtonText={lng('Adicionar pessoas')}
              items={current.relPeople} 
              hideIfEmpty={1}
              ready={current.ready}  height={272}/>
            </div>
          <div style={{marginTop:"15px"}}>
              
                <AmazonCarrousel 
                title={amazonTitle}
                query={MyAppPool.cAffiliate.getFigureQuery(current.engTitle)}
                showItemNumber={3}
                height={272}
                />
          </div>
        
              
        <div style={{marginTop:"15px"}}>
        <ColumnCard
        ready = {current.ready}
        items={current.columItems 
        }/>
        <ExternalLinksBox items= {current.externalLinks} title={lng("Links Externos e Streams")}/>
        <TagsColumn items={current.tags} />
        </div>

        <div style={{marginTop:"15px"}}>
            <h3>{lng("Latest Discussions")}
            <LinkV2 to={getForumCreateThreadPage("?tagNames="+"lnkres_"+resourceType+"_"+pageId,"#top")} className="uk-button uk-button-default  sendButton">{lng("Criar uma Thread")}</LinkV2>
            </h3>
            <ForumThreadsByTagBox tag={"lnkres_"+resourceType+"_"+pageId}/>
        </div>

      </div>
      
    </div>);


    }

    
}

export default MangaGeralView;