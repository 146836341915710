import { lng } from "../ulanguages/language";

export function getSeason(month)
{
    if (month==12 || month <3)
        return 0; //verao(br) / inverno (jp)
    if (month>=3 && month <6)
        return 1;//outono(br) / primavera (jp)
    if (month>=6 && month <9)
        return 2;//inverno(br) / verao (jp)
    if (month>=9 && month <12)
        return 3;//primavera(br) / outono(jp)
    return 0;
}

export function weeksSince(dateString) {
    // Parse the input date
    const inputDate = new Date(dateString);
    
    // Get the current date
    const currentDate = new Date();
    
    // Calculate the difference in time (in milliseconds)
    const timeDifference = currentDate - inputDate;
    
    // Convert the time difference from milliseconds to weeks
    const weeksDifference = timeDifference / (1000 * 60 * 60 * 24 * 7);
    
    // Round down to get the full weeks
    return Math.round(weeksDifference);
}

export function timeSinceString(timestamp) {
    const seconds = Math.floor((Date.now() - timestamp) / 1000);
    if (seconds < 60) return `${seconds} ` + lng('seconds ago');
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} ` + lng('minutes ago');
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} ` + lng('hours ago');
    const days = Math.floor(hours / 24);
    if (days < 30) return `${days} ` + lng('days ago');
    const months = Math.floor(days / 30);
    if (months < 12) return `${months} ` + lng('months ago');
    const years = Math.floor(months / 12);
    return `${years} ` + lng('years ago');
  }

export function getLastXYears(x) {
    const currentYear = new Date().getFullYear();
    const lastXYears = [];
  
    for (let i = 0; i < x; i++) {
      lastXYears.push(currentYear - i);
    }
  
    return lastXYears;
  }
export function getCurrentSeason()
{
    const d = new Date();
    let month = d.getMonth();
    return getSeason(month);
}

export function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

export function delay(t) {
    return new Promise(function(resolve) { 
        setTimeout(resolve, t)
    });
 }

 export function prettyDateTimeStringNoSecond(datestr)
 {
    if (typeof datestr !== 'string')
        return datestr;
    let res = datestr;
    
    let timeZonePos = datestr.indexOf("+");
    if (timeZonePos!==-1)
        res = datestr.substring(0,timeZonePos);

    let amountOfDots = (res.split(":").length - 1);
    if (amountOfDots===2)
        res = res.substring(0,res.lastIndexOf(':')); //remove seconds
    
    res = res.replaceAll('-','/');
    res = res.split('T');
    return res[0].split('/').reverse().join('/') +' '+ res[1]; //reverse date order to match global standards
 }