
import MyAppPool from "../AppPool";
import { getCurrentDomain, getCurrentUrl, isServer } from "../util/windowUtil";
import configData from "./ServiceHostsConfig.json";

export var serviceHosts;

export function setCurrentEnvironment()
{
   let url = getCurrentUrl();
   serviceHosts=configData.prodpt;
   if (url.indexOf('localhost')!==-1)
   {
      serviceHosts=configData.local;
      if (process.env.env==="build")
         serviceHosts=configData.prodpt;
   }
      
   if (url.indexOf('nueanime.net')!==-1)
    serviceHosts=configData.prodes;
   if (url.indexOf('unseranime.net')!==-1)
    serviceHosts=configData.prodde;
   if (url.indexOf('ouranime.net')!==-1)
      serviceHosts=configData.proden;
   if (url.indexOf('nossoanime.net')!==-1)
    serviceHosts=configData.prodpt;
   if (url.indexOf('meusanimes.brasi.li')!==-1)
    serviceHosts=configData.prodpt;
    
   if (isServer())
   {//speed up calls that require the country parameter, by setting a default one in SSR
      MyAppPool.session.country = serviceHosts.baseCountry;
   }

   serviceHosts.frontend = "https://"+getCurrentDomain() ;
   return serviceHosts;
}

setCurrentEnvironment();