
import { getViewportWidthHeight, isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { isMobile } from '../util/mobile';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { isSet, setIfNotSetArray, setIfNotSetObj, setIfNotSetObjElems, userFastResourceType } from '../util/typeu';
import { brasRequest } from '../util/net';
import { routes } from '../store/ApiRoutes';
import { get, toJS } from 'mobx';

function convertTimeStampToDateUserWall(timestamp) {
    if (!isSet(timestamp))
        return new Date();
    // Create a new Date object from the timestamp
    return new Date(timestamp*1000);
  }


const COMMENT_DEPTH_RETRIEVAL=3;
function guaranteeArray(item){
    if (!isSet(item))
        return [];
    if(!Array.isArray(item))
        return [item];
    return item;
}
export default class NeoComuController
{

    async getTagsResources(tags){
        if (!Array.isArray(tags))
            return Promise.resolve();

        let resourceIds = {};
        for(let i=0;i<tags.length;i++)
        {
            let tag = tags[i].properties.name;
            if (tag.indexOf("lnkres_")===-1)
                continue;
            let tagExtract = tag.split("_");
            let resourceType = tagExtract[1];
            let resourceId = tagExtract[2];
            if (!Array.isArray(resourceIds[''+resourceType]))
                resourceIds[''+resourceType] = [];
            resourceIds[''+resourceType].push(resourceId);
        }
        let resourcesArray = Object.entries(resourceIds);
        let resourceCalls = [];
        for(let i=0;i<resourcesArray.length;i++)
        {
            let item = resourcesArray[i];
            resourceCalls.push(MyAppPool.cSamples.getSamplesByArrayIdsList(item[0],item[1]));
        }
        await Promise.all(resourceCalls);
        return Promise.resolve();
    }
    getBaseSection()
    {
        let lng = getCurrentLanguage();
        let base = MyAppPool.session.neocomu.base[lng];
        if (!isSet(base))
            base=62;
        return base;
    }

    async ensureUserHasComunityNode()
    {
        try{
        let answer = await brasRequest(routes.neoComuWrite,{},{"action":"ensureUserExists"});
        }catch(e){
            console.error("Error creating neocomu node user",e);
        }
    };

    async getThreadsByUserId(userId,page=1,limit=10)
    {
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getThreadsByUserId",
            "page":page,
            "limit":limit,
            "baseSection":this.getBaseSection(),
            "userId":userId
        });
        let userThreads = [];
        let threads = answer.data.data;
        let userIds = [];
        let resourceCalls = [];
        for(let i=0;i<threads.length;i++)
        {
            let threadId = threads[i].thread.id;
            threads[i].publishDate = convertTimeStampToDateUserWall(threads[i].thread.properties.datecreated);
            MyAppPool.session.neocomu.threads[threadId] = threads[i];
            userThreads.push({id:threadId,type:'thread',publishDate:threads[i].publishDate});
            userIds.push(threads[i].user.id);
            resourceCalls.push(this.getTagsResources(threads[i].tags));
        }
        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        await Promise.all(resourceCalls);
        setIfNotSetObjElems(MyAppPool.session.usersWallItems,[""+userId,""+limit]);

        let adaptPageToWall = page-1;
        if (adaptPageToWall<0)
            adaptPageToWall=0;
        
        let currentWall = MyAppPool.session.usersWallItems[''+userId][''+limit][adaptPageToWall];
        let newWall = [];
        if (Array.isArray(currentWall))
            newWall = currentWall.concat(userThreads).sort((a,b) => {return b.publishDate-a.publishDate});
        else
            newWall = userThreads;

        MyAppPool.session.usersWallItems[''+userId][''+limit][adaptPageToWall] = newWall;


        return Promise.resolve(userThreads);

        }catch(e){
            console.error("Error getting user neocomu threads ",e);
        }
    };

    async getLatestActivitiesThreads(page=1,limit=10)
    {
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getLatestActivitiesThreads",
            "page":page,
            "limit":limit,
            "baseSection":this.getBaseSection()
        });
        let latestThreads = [];
        let threads = answer.data.data;
        let userIds = [];
        let resourceCalls = [];
        for(let i=0;i<threads.length;i++)
        {
            MyAppPool.session.neocomu.threads[threads[i].thread.id] = threads[i];
            latestThreads.push(threads[i].thread.id);
            userIds.push(threads[i].user.id);
            resourceCalls.push(this.getTagsResources(threads[i].tags));
        }
        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        await Promise.all(resourceCalls);
        setIfNotSetObj(MyAppPool.session.neocomu.latestThreads,""+limit);
        MyAppPool.session.neocomu.latestThreads[""+limit][page] = latestThreads;
        return Promise.resolve(latestThreads);

        }catch(e){
            console.error("Error getting latest neocomu threads ",e);
        }
    };
    async getNewlyCreatedThreads(page=1,limit=10)
    {
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getLatestActivitiesThreads",
            "page":page,
            "limit":limit,
            "baseSection":this.getBaseSection()
        });
        let newlyCreatedThreads = [];
        let threads = answer.data.data;
        let userIds = [];
        let resourceCalls = [];
        for(let i=0;i<threads.length;i++)
        {
            MyAppPool.session.neocomu.threads[threads[i].thread.id] = threads[i];
            newlyCreatedThreads.push(threads[i].thread.id);
            userIds.push(threads[i].user.id);
            resourceCalls.push(this.getTagsResources(threads[i].tags));
        }
        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        await Promise.all(resourceCalls);
        setIfNotSetObj(MyAppPool.session.neocomu.newlyCreatedThreads,""+limit);
        MyAppPool.session.neocomu.newlyCreatedThreads[""+limit][page] = newlyCreatedThreads;
        return Promise.resolve(newlyCreatedThreads);

        }catch(e){
            console.error("Error getting newly created threads ",e);
        }
    };

    async getSectionPinnedThreads(sectionarg,page=1,limit=10)
    {
        let section = Number(sectionarg);
        if (isNaN(section))
            return null;
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getSectionPinnedThreads",
            "sectionId":section,
            "page":page,
            "limit":limit
        });
        let sectionPinnedThreads = [];
        let threads = answer.data.data;
        let userIds = [];
        for(let i=0;i<threads.length;i++)
        {
            MyAppPool.session.neocomu.threads[threads[i].thread.id] = threads[i];
            sectionPinnedThreads.push(threads[i].thread.id);
            userIds.push(threads[i].user.id);
        }
        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        setIfNotSetArray(MyAppPool.session.neocomu.sectionPinnedThreads,""+""+section);
        MyAppPool.session.neocomu.sectionPinnedThreads[""+section] = sectionPinnedThreads;
        return Promise.resolve(sectionPinnedThreads);

        }catch(e){
            console.error("Error getting newly created threads ",e);
        }
    };

    async getTagThreads(tagIdarg,page=1,limit=10)
    {
        let tagId = Number(tagIdarg);
        if (isNaN(tagId))
            return null;
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getTagThreads",
            "tagId":tagId,
            "page":page,
            "limit":limit
        });
        let tagThreads = [];
        let threads = answer.data.data;
        let userIds = [];
        for(let i=0;i<threads.length;i++)
        {
            MyAppPool.session.neocomu.threads[threads[i].thread.id] = threads[i];
            tagThreads.push(threads[i].thread.id);
            userIds.push(threads[i].user.id);
        }

        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        setIfNotSetObjElems(MyAppPool.session.neocomu.tagThreads,[""+tagId,""+limit]);
        MyAppPool.session.neocomu.tagThreads[""+tagId][""+limit][page] = tagThreads;
        return Promise.resolve(tagThreads);

        }catch(e){
            console.error("Error getting newly created threads ",e);
        }
    };


    async getThreadsByTagName(tagName,page=1,limit=10)
    {
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getThreadsByTagName",
            "tagName":tagName,
            "baseSection": this.getBaseSection(),
            "page":page,
            "limit":limit
        });

        let tagThreads = [];
        let threads = answer.data.data;
        let userIds = [];
        let resourceCalls = [];
        for(let i=0;i<threads.length;i++)
        {
            MyAppPool.session.neocomu.threads[threads[i].thread.id] = threads[i];
            tagThreads.push(threads[i].thread.id);
            userIds.push(threads[i].user.id);
            resourceCalls.push(this.getTagsResources(threads[i].tags));
        }

        

        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        await Promise.all(resourceCalls);
        setIfNotSetObjElems(MyAppPool.session.neocomu.tagThreadsByName,[""+tagName,""+limit]);
        MyAppPool.session.neocomu.tagThreadsByName[""+tagName][""+limit][page] = tagThreads;
        return Promise.resolve(tagThreads);

        }catch(e){
            console.error("Error getting newly created threads ",e);
        }
    };


    async getSectionThreads(sectionarg,page=1,limit=10)
    {
        let section = Number(sectionarg);
        if (isNaN(section))
            return null;
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getSectionThreads",
            "sectionId":section,
            "page":page,
            "limit":limit
        });
        let sectionThreads = [];
        let threads = answer.data.data;
        let userIds = [];
        for(let i=0;i<threads.length;i++)
        {
            MyAppPool.session.neocomu.threads[threads[i].thread.id] = threads[i];
            sectionThreads.push(threads[i].thread.id);
            userIds.push(threads[i].user.id);
        }

        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        setIfNotSetObjElems(MyAppPool.session.neocomu.sectionThreads,[""+section,""+limit]);
        MyAppPool.session.neocomu.sectionThreads[""+section][""+limit][page] = sectionThreads;
        return Promise.resolve(sectionThreads);

        }catch(e){
            console.error("Error getting newly created threads ",e);
        }
    };

    async getSection(sectionIdarg)
    {
        let sectionId = Number(sectionIdarg);
        if (isNaN(sectionId))
            return null;
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getSectionsContentById",
            "sectionId":sectionId
        });
        let reqData = answer.data.data;
        let thisSection = reqData.section;
        let subsections = reqData.subsections;
        let {admins,mods,owner} = reqData;
        admins = guaranteeArray(admins);
        mods = guaranteeArray(mods);

        if (!isSet(subsections))
            subsections=[];
        if (!Array.isArray(subsections))
            subsections=[];

        let resultSection  = thisSection;

        resultSection = thisSection;
        resultSection.subsections = subsections;
        let adminsShort = [];
        let modsShort = [];
        let userIds = [];
        
        if (isSet(owner))
        {
            userIds.push(owner.properties.userId);
            
        }
        admins.map((user) => {
            adminsShort.push(user.properties.userId);
            userIds.push(user.properties.userId);
        });
        mods.map((user) => {
            modsShort.push(user.properties.userId);
            userIds.push(user.properties.userId);
        });
        resultSection.admins = adminsShort;
        resultSection.mods = modsShort;

        MyAppPool.session.neocomu.sections[thisSection.id] = resultSection;
        return Promise.resolve(resultSection);

        }catch(e){
            console.error("Error getting section ",e);
        }
    };

    async getAutoCompleteTags(text){
        let sectionId = MyAppPool.cNeoComu.getBaseSection();
        let answer = await brasRequest(routes.neoComuRead,{},
            {
                "action":"getTagsByQuery",
                "sectionId":sectionId,
                "query":text
            });
        let reqData = answer.data.data;
        MyAppPool.session.autoCompleteTags = reqData;
        return reqData;
    }
    async createThread(title,content,sectionId,options={}){
        let baseSection = MyAppPool.cNeoComu.getBaseSection();
        let sendOptions = {...options};
        sendOptions.tagSection = baseSection;
        let requestData = 
        {
            "action":"createThread",
            "title":title,
            "content":content,
            "sectionId":sectionId,
            "options":sendOptions
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }
    async createReplyToThread(content,threadIdarg,options={}){
        let threadId = Number(threadIdarg);
        if (isNaN(threadId))
            return null;
        let sendOptions = {...options};
        let requestData = 
        {
            "action":"createReplyToThread",
            "content":content,
            "threadId":threadId,
            "options":sendOptions
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }
    async createReplyToReply(content,replyId,options={}){
        let sendOptions = {...options};
        let requestData = 
        {
            "action":"createReplyToReply",
            "content":content,
            "parentReplyId":replyId,
            "options":sendOptions
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }
    async createReplyToUserThread(content,userId,options={}){
        let sendOptions = {...options};
        let requestData = 
        {
            "action":"createReplyToUserThread",
            "content":content,
            "userId":userId,
            "options":sendOptions
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }

    async updateReply(content,replyId,options={}){
        let sendOptions = {...options};
        let requestData = 
        {
            "action":"updateReply",
            "content":content,
            "replyId":replyId,
            "options":sendOptions
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }

    async deleteReply(replyId){
        let requestData = 
        {
            "action":"deleteReply",
            "replyId":replyId
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }

    async updateThread(title,content,sectionId,options={}){
        let baseSection = MyAppPool.cNeoComu.getBaseSection();
        let sendOptions = {...options};
        sendOptions.tagSection = baseSection;
        let requestData = 
        {
            "action":"updateThread",
            "title":title,
            "content":content,
            "id":sectionId,
            "options":sendOptions
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }

    async deleteThread(id,hard=false){
        let requestData = 
        {
            "action":"deleteThread",
            "hardDelete":hard,
            "id":id,
            deleteContent:lng("Thread Deleted")
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }


    async getThread(threadIdarg){
        let threadId = Number(threadIdarg);
        if (isNaN(threadId))
            return null;
        let requestData = 
        {
            "action":"getThread",
            "threadId":threadId
        }
        let answer = await brasRequest(routes.neoComuRead,{},
            requestData);
        if (answer.data.ok===false)
            return null;
        
        let threadData =answer.data.data;

        let userIds = [];
        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        await this.getTagsResources(threadData.tags);
            
        MyAppPool.session.neocomu.threads[threadData.thread.id] = threadData;
        return threadData;
    }

    async getThreadPinnedSections(threadIdarg){
        let threadId = Number(threadIdarg);
        if (isNaN(threadId))
            return null;
        let requestData = 
        {
            "action":"getSectionsThreadIsPinnedTo",
            "threadId":threadId
        }
        let answer = await brasRequest(routes.neoComuRead,{},
            requestData);
        if (answer.data.ok===false)
            return null;
        
        let threadData =answer.data.data;
        return threadData;
    }

    async getUserPermissions()
    {
        let userId = MyAppPool.currentUser.userid;
        let requestData = {
            "action":"getUserAdministeredOrModeratedSections",
            "userId":userId
        }
        let answer = await brasRequest(routes.neoComuRead,{},
            requestData);
        let permissions = answer.data.data;
        MyAppPool.session.user.forumPermissions = permissions;
        return permissions;
    }
    isModeratorOf(sectionId)
    {
        let permissions = MyAppPool.session.user.forumPermissions;
        let size = permissions.moderatedSections.length;
        for (let i=0;i<size;i++)
        {
            if (permissions.moderatedSections[i]===sectionId)
                return true;
        }
        let isAdmin = this.isAdministratorOf(sectionId);
        if (isAdmin)
            return isAdmin;
        let isOwner = this.isOwnerOf(sectionId);
        if (isOwner)
            return isOwner;
        return false;
    }
    isOwnerOf(sectionId)
    {
        let permissions = MyAppPool.session.user.forumPermissions;
        let size = permissions.ownedSections.length;
        for (let i=0;i<size;i++)
        {
            if (permissions.ownedSections[i]===sectionId)
                return true;
        }
        return false;
    }
    isAdministratorOf(sectionId)
    {
        let permissions = MyAppPool.session.user.forumPermissions;
        let size = permissions.administeredSections.length;
        for (let i=0;i<size;i++)
        {
            if (permissions.administeredSections[i]===sectionId)
                return true;
        }
        let isOwner = this.isOwnerOf(sectionId);
        if (isOwner)
            return isOwner;
        return false;
    }
    processReplies = (reply,usersArray) =>
    {
        let replies = reply.replies;
        reply.repliesIds=[];
        let localReply = MyAppPool.session.neocomu.replies[reply.reply.id];
        if (isSet(localReply))
            reply.repliesIds = [...localReply.repliesIds];
        for(let i=0;i<replies.length;i++)
        {
            let currentReply = replies[i];
            let replyId = currentReply.reply.id;
            currentReply.threadId = reply.threadId;
            currentReply.userPage = reply.userPage;
            if (!isSet(currentReply.currentPage))
                currentReply.currentPage = 0;
            
            this.processReplies(replies[i],usersArray);
            reply.repliesIds.push(replyId);
            MyAppPool.session.neocomu.replies[replyId] = currentReply;
            usersArray.push(currentReply.user.id);
        }
        reply.repliesIds = [...new Set(reply.repliesIds)];
        return;
    }

    async getThreadRepliesWithHotRanking(threadIdarg,page=1,limit=10)
    {
        let threadId = Number(threadIdarg);
        if (isNaN(threadId))
            return null;
        let requestData = 
        {
            "action":"getThreadRepliesWithHotRanking",
            "threadId":threadId,
            "page":page,
            "limit":limit
        }
        let answer = await brasRequest(routes.neoComuRead,{},
            requestData);
        
        let threadReplies = [];
        let replies = answer.data.data;
        let userIds = [];
        for(let i=0;i<replies.length;i++)
        {
            let replyToThread = replies[i];
            let replyId = replyToThread.reply.id;
            replyToThread.currentPage=1;
            replyToThread.threadId = Number(threadId)
            threadReplies.push(replyId);
            userIds.push(replyToThread.user.id);
            replyToThread.repliesIds=[];
            this.processReplies(replyToThread,userIds);
            MyAppPool.session.neocomu.replies[replyId] = replyToThread;
        }

        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        setIfNotSetObjElems(MyAppPool.session.neocomu.repliesToThread,[""+threadId,""+limit]);
        MyAppPool.session.neocomu.repliesToThread[threadId][""+limit][page] = threadReplies;
        return Promise.resolve(threadReplies);
    }

    async getUserThreadRepliesWithHotRanking(userIdarg,page=1,limit=10)
    {
        let userId = Number(userIdarg);
        if (isNaN(userId))
            return null;
        let requestData = 
        {
            "action":"getUserThreadRepliesWithHotRanking",
            "userId":userId,
            "page":page,
            "limit":limit
        }
        let answer = await brasRequest(routes.neoComuRead,{},
            requestData);
        
        let threadReplies = [];
        let replies = answer.data.data;
        let userIds = [];
        for(let i=0;i<replies.length;i++)
        {
            let replyToThread = replies[i];
            let threadId = replyToThread.thread.id;
            let replyId = replyToThread.reply.id;
            replyToThread.publishDate = convertTimeStampToDateUserWall(replyToThread.reply.properties.datecreated);
            replyToThread.currentPage=1;
            replyToThread.userPage=Number(userId);
            replyToThread.threadId = Number(threadId)
            threadReplies.push({id:replyId,type:'reply',publishDate:replyToThread.publishDate});
            userIds.push(replyToThread.user.id);
            replyToThread.repliesIds=[];
            this.processReplies(replyToThread,userIds);
            MyAppPool.session.neocomu.replies[replyId] = replyToThread;
        }

        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);

        setIfNotSetObjElems(MyAppPool.session.usersWallItems,[""+userId,""+limit]);
        let adaptPageToWall = page-1;
        if (adaptPageToWall<0)
            adaptPageToWall=0;
            
        let currentWall = MyAppPool.session.usersWallItems[''+userId][''+limit][adaptPageToWall];
        let newWall = [];
        if (Array.isArray(currentWall))
            newWall = currentWall.concat(threadReplies).sort((a,b) => {return b.publishDate-a.publishDate});
        else
            newWall = threadReplies;

        MyAppPool.session.usersWallItems[''+userId][''+limit][adaptPageToWall] = newWall;

        return Promise.resolve(threadReplies);
    }

    async getReply(replyIdarg,page=1,limit=10)
    {
        let replyId = Number(replyIdarg);
        if (isNaN(replyId))
            return null;
        let requestData = 
        {
            "action":"getReply",
            "replyId":replyId,
            "page":page,
            "limit":limit,
            "depth":COMMENT_DEPTH_RETRIEVAL
        }
        let answer = await brasRequest(routes.neoComuRead,{},
            requestData);
        
        let replyData = answer.data.data;
        let threadId = replyData.thread.id;
        let userIds = [];
        replyData.currentPage=1;

        replyData.threadId = Number(threadId);
        userIds.push(replyData.user.id);
        replyData.repliesIds=[];
        this.processReplies(replyData,userIds);
        MyAppPool.session.neocomu.replies[replyId] = replyData;

        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        return Promise.resolve(replyData);
    }

    async loadMoreReplies(replyIdarg,limit=10)
    {
        let replyId = Number(replyIdarg);
        if (isNaN(replyId))
            return null;
        let localReply = MyAppPool.session.neocomu.replies[replyId];
        if (!isSet(localReply))
            return null;

        let page = localReply.currentPage?localReply.currentPage+1:1;
        let requestData = 
        {
            "action":"getReply",
            "replyId":replyId,
            "page":page,
            "limit":limit,
            "depth":COMMENT_DEPTH_RETRIEVAL
        }
        let answer = await brasRequest(routes.neoComuRead,{},
            requestData);
        
        let replyData = answer.data.data;
        let threadId = replyData.thread.id;
        let userIds = [];
        replyData.currentPage=page;

        replyData.threadId = Number(threadId);
        userIds.push(replyData.user.id);
        replyData.repliesIds= localReply.repliesIds;
        replyData.replies = [...localReply.replies,...replyData.replies];

        this.processReplies(replyData,userIds);
        MyAppPool.session.neocomu.replies[replyId] = replyData;

        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        return Promise.resolve(replyData);
    }


    async getSearch(query,limit=10, nuke=false)
    {
        let page = 1;
        try{
        let answer = await brasRequest(routes.neoComuRead,{},
        {
            "action":"getSearchThreads",
            "baseSectionId":this.getBaseSection(),
            "searchQuery":query,
            "page":page,
            "limit":limit
        });
        let searchThreads = [];
        let threads = answer.data.data;
        let userIds = [];
        for(let i=0;i<threads.length;i++)
        {
            MyAppPool.session.neocomu.threads[threads[i].thread.id] = threads[i];
            searchThreads.push(threads[i].thread.id);
            userIds.push(threads[i].user.id);
        }

        await MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,userIds);
        setIfNotSetObjElems(MyAppPool.session.neocomu.searchThreads,[""+query,""+limit]);
        //NUKE previous search
        if (nuke)
            MyAppPool.session.neocomu.searchThreads[""+query][""+limit]={};

        MyAppPool.session.neocomu.searchThreads[""+query][""+limit][page] = searchThreads;
        return Promise.resolve(searchThreads);
        }catch(e){
            console.error("Error getting newly created threads ",e);
        }
    };

    async unpinThread(threadIdarg, selectedSection){
        let threadId = Number(threadIdarg);
        if (isNaN(threadId))
            return null;
        let requestData = 
        {
            "action":"deleteSectionPinned",
            "threadId":threadId,
            "sectionId":selectedSection
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }
    async pinThread(threadIdarg, selectedSection){
        let threadId = Number(threadIdarg);
        if (isNaN(threadId))
            return null;
        let requestData = 
        {
            "action":"createSectionPinned",
            "threadId":threadId,
            "sectionId":selectedSection
        }
        let answer = await brasRequest(routes.neoComuWrite,{},
            requestData);
        return answer.data;
    }

}