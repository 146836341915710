
import { getViewportWidthHeight, isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { isMobile } from '../util/mobile';
import { isSet } from '../util/typeu';
import { lng } from '../ulanguages/language';

export default class Preference
{
    set(name,value)
    {
        MyAppPool.saveItemToLocalStorage(name,value);
    }
    get(name)
    {
        return MyAppPool.recoverItemFromLocalStorage(name);
    }
    setBrowserOption(option,value)
    {
        MyAppPool.session.browserOptions[option] = value;
        this.set("browserOptions",MyAppPool.session.browserOptions);
        MyAppPool.cAlert.notifyOk(lng("Configuração Salva"));
    }
    retrieveBrowserOptions()
    {
        let savedOptions = this.get("browserOptions");
        if (isSet(savedOptions))
            MyAppPool.session.browserOptions = {...MyAppPool.session.browserOptions , ...savedOptions};
    }
    setTheme(value)
    {
        this.set("theme",value);
    }
    getTheme() 
    {
        let ret = this.get("theme");
        if (!isSet(ret))
            ret = 'darkTheme';
        return ret;
    }
    setListBoxCardMode(value)
    {
        this.set("listBoxCardMode",value);
    }
    getListBoxCardMode()
    {
        let ret =  this.get("listBoxCardMode");
        if (!isSet(ret))
            ret = 'card';
        return ret;
    }
}