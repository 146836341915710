import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
import './ThreadPreview.css'; // Ensure this file exists for styling
import { isSet, mobToObj, userFastResourceType } from '../util/typeu';
import MyAppPool from '../AppPool';
import { getImgAddress } from '../util/text';
import { getUserAvatar } from '../util/icon';
import { observer } from 'mobx-react';
import htmlToReact from 'html-to-react';
import { lng } from '../ulanguages/language';
import { timeSinceString } from '../util/timeUtil';
import ThreadTag from './ThreadTag';
import LinkV2 from './LinkV2';
import { getForumSectionPath, getForumTagPath, getForumThreadPage, getUserRoute } from '../store/AppRoutes';
import TitleBadge from './TitleBadge';
import YoutubeVideo from './YoutubeVideo';
import { toJS } from 'mobx';
import ResourceLinkBox from './ResourceLinkBox';
import { isServer } from '../util/windowUtil';

function getPreview(displayContent, query='') {
  const text = displayContent.replace(/<[^>]+>/g, '');

  if (query) {
    const index = text.toLowerCase().indexOf(query.toLowerCase());
    if (index !== -1) {
      const start = Math.max(0, index - 100);
      const end = Math.min(text.length, start + 200);
      const preview = text.substring(start, end);
      const highlighted = preview.replace(new RegExp(`(${query})`, 'ig'), '<span style="color: var(--highLightedText);">$1</span>');
      return highlighted + (end === text.length ? '' : '...');
    }
  }
  return text.substring(0, 200) + (text.length > 200 ? '...' : '');
}

@observer
class ThreadPreview extends Component {
  // Helper function to format the relative time
  constructor(props) {
    super(props);
    this.state = {
      revealContent: false,
      revealHovered: false,
      linkMax:isServer()?99:3
    };
  }
  

  renderMedia(content) {
    const youtubeMatch = content.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/
    );
    if (youtubeMatch) {
      return <div >
        <YoutubeVideo height={250} video={{ytid:youtubeMatch[1],title:""}} ready={1}/>
        </div>
    }

    const imgMatch = content.match(/<img.*?src="(.*?)"/);
    if (imgMatch) {
      return <img className="thread-image" src={imgMatch[1]} alt="Thread Media" />;
    }

    

    return null;
  }

  render() {
    const { thread, query } = this.props;
    const { title, content, datecreated, repliesAmount, views,isNSFW,isInappropriate,isSpoiler } = thread.thread.properties;
    const { id } = thread.thread;
    const { tags,user,highlightedReply } = thread;
    let {revealContent, revealHovered, linkMax} = this.state;
    const {disableNSFWProtection, disableSpoilerProtection, disableInapropriateContentProtection} = MyAppPool.session.browserOptions;

    let addParamToLink = '';
    let highLightedPreviewText = '';
    let highLighted = false;
    if (isSet(highlightedReply))
    {
      addParamToLink = highlightedReply.id;
      highLightedPreviewText = getPreview(highlightedReply.properties.content,query);
      highLighted=true;
    }

    let linkCount = 0;
    let linkAvailable = 0;
    
    //let sectionName = thread.section.properties.name;
    //let sectionId = thread.section.id;
    let useTitle = title.slice(0,64);
    if (useTitle.length !== title.length)
      useTitle+='...';
    let useTags = [];
    if (Array.isArray(tags)) {
      useTags = tags;
    }
    let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+userFastResourceType][''+user.id]);
    sampleAuthor.titles = sampleAuthor.titles.slice(0,2);
    let useName = sampleAuthor.name.slice(0,32);
    if (useName.length !== sampleAuthor.name.length)
      useName+='...';
    
    const displayContentPreviewText = getPreview(content,query);
    const displayContentPreview = htmlToReact.Parser().parse(displayContentPreviewText);
    const highLightedPreview = htmlToReact.Parser().parse(highLightedPreviewText);

    let hideContent = '';
    let why='';
    if ((isSpoiler && disableSpoilerProtection)
    || (isNSFW && disableNSFWProtection)
    || (isInappropriate && disableInapropriateContentProtection)
    )
    {
      revealContent=true;
    }

    if (isSpoiler && revealContent===false)
    {
      hideContent='hideSpoiler';
      why = lng('Contains Spoilers');
    }
    if (isNSFW && revealContent===false)
    {
      
      hideContent='hideSpoiler';
      why = lng('NSFW Content');
    }
    if (isInappropriate && revealContent===false)
    {
      hideContent='';
      why = lng('Contains Inappropriate Content');
    }

    return (
      <div className="compthread-preview uk-card uk-card-default">
        <LinkV2 to={getForumThreadPage(id,title,addParamToLink,"#threadTop")}>
        <div className="thread-header">
          <h2 className="thread-title" data-uk-tooltip={title}>{useTitle} </h2> 
          <LinkV2 to={getUserRoute(sampleAuthor.id,sampleAuthor.name,"#top")} className="user-area">
          <div className="thread-author">
            <img className="author-avatar" src={getUserAvatar(sampleAuthor.img,sampleAuthor.hash)} alt="Author Avatar" />
            <div className="author-info">
              <span className="author-name" data-uk-tooltip={sampleAuthor.name}>{useName}</span>
              <div className="author-titles">
                {sampleAuthor.titles.map((title, index) => <TitleBadge key={index} tag={title}/>)}</div>
            </div>
          </div>
          </LinkV2>
        </div>
        </LinkV2>
        <div className={"thread-content-section"} 
        onMouseEnter={() => this.setState({ revealHovered: true })}
        onMouseLeave={() => this.setState({ revealHovered: false })}
        onClick={()=>{
          if (isNSFW || isInappropriate || isSpoiler)
            this.setState({ revealContent: true });
        }}
        >
        {((isNSFW || isInappropriate || isSpoiler) && !revealContent)?
          <div className={`borderedText reveal-content-text ${revealHovered ? 'show' : ''}`}>
          {lng("Reveal Content")} ({why})
          </div> :<></>
        } {!isInappropriate || (isInappropriate  && revealContent)?
          <LinkV2 to={getForumThreadPage(id,title,addParamToLink,"#threadTop")}>
          <div className={"thread-content "+hideContent}>
          {this.renderMedia(content)} <br/>
          {displayContentPreview}
          {highLighted? [<br/>,<br/>,<span className="uk-badge">{lng("Highlighted Comment")}</span>,<br/>,highLightedPreview]:''}
          
          </div></LinkV2> : <div style={{"height":"150px"}}></div>}
          
        </div>
        <div className="linkresflow">
        {useTags.map((element, index) => {
                      let tag = element.properties.name;
                      if (tag.indexOf("lnkres_")==-1)
                        return <></>;
                        linkAvailable+=1;
                      if (linkCount >=linkMax)
                        return <></>;
                      linkCount+=1;
                      let extractInfo = tag.split("_");
                      let resourceId = Number(extractInfo[2]);
                      let resourceType = Number(extractInfo[1]);
                      return <div className="linkresspace"><ResourceLinkBox resourceType={resourceType} resourceId={resourceId} /></div> ;       
          })}
          {linkAvailable>linkMax?<button className="uk-button uk-button-default" onClick={() => this.setState({ linkMax: linkMax + 3 })}>{lng("Mais")}</button> :<></>}
          </div>
        <div className="thread-footer">
          <div className="thread-counters">
          <span className="thread-views"><span className="fas fa-eye"/> {views} </span>
          <span className="thread-replies"><span className="fas fa-comments"/> {repliesAmount}  </span>
          <span className="thread-date">{timeSinceString(datecreated * 1000)}</span>
          </div>
          <div>
          <ThreadTag backgroundColor={"#33ccff"} name={thread.section.properties.name} url={getForumSectionPath(thread.section.id,thread.section.properties.name)}/>
          {useTags.map((element, index) => {
             if (element.properties.name.indexOf("lnkres_")!==-1)
              return <></>;
            return <ThreadTag name={element.properties.name} url={getForumTagPath(element.id,element.properties.name)}/>;
          })}
          </div>
          
        </div>
        
      </div>
    );
  }
}

ThreadPreview.propTypes = {
  query: PropTypes.string,
  thread: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    datecreated: PropTypes.number.isRequired,
    repliesAmount: PropTypes.number.isRequired,
    views: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      properties: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,
};

ThreadPreview.defaultProps = {
  query: ''
};

export default ThreadPreview;