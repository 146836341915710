import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../../AppPool';
import { lng } from '../../ulanguages/language';
import { getImgAddress, getRouteParamAtIndex } from '../../util/text';
import { getIfIsSetElems, isSet } from '../../util/typeu';
//import LoginBox from '../view_component/LoginBox';
import { getCurrentUrl, isServer } from '../../util/windowUtil';
import SpinnerBox from '../../view_component/SpinnerBox';

import './home.css';
import ContentBannerSetter from '../../view_component/ContentBannerSetter';
import ThreadPreview from "../../view_component/ThreadPreview";
import InfiniteScrollBlock from "../../view_component/InfiniteScrollBlock";
import { serverRegisterFetch } from "../../util/updateManager";
import ThreadPinned from "../../view_component/ThreadPinned";
import ThreadTag from "../../view_component/ThreadTag";
import { getForumSearchPath } from "../../store/AppRoutes";
import { toJS } from "mobx";
import Erro404 from "../../view_component/Erro404";

let pageSizeGlob = 10;
@observer
class ForumSearch extends Component {

  constructor(props) {
    super(props);

    this.state = {currentPage:1,pageSize:pageSizeGlob, lastFetchSize:1};
    serverRegisterFetch(ForumSearch.getInitialValues);
  }

  componentDidMount()
  {
    ForumSearch.getInitialValues();
  }
  static async getInitialValues()
  {
    let parametersUrl = MyAppPool.cLocation.formatedUrlParameters;
    let searchQuery=parametersUrl["query"];
    let p3 = Promise.resolve();
    if (isSet(searchQuery))
      p3 = MyAppPool.cNeoComu.getSearch(searchQuery,pageSizeGlob,true);
    return Promise.all([p3]);
  }

  componentWillReceiveProps()
  {
    ForumSearch.getInitialValues();
  }

  loadNextPage = ()=>{
    let parametersUrl = MyAppPool.cLocation.formatedUrlParameters;
    let searchQuery=parametersUrl["query"];
    if (isSet(searchQuery))
    {
        let {lastFetchSize,currentPage,pageSize} = this.state;
        if (lastFetchSize>0)
        {
            return MyAppPool.cNeoComu.getSearch(searchQuery,pageSizeGlob,false).then(data=>{
                this.setState({lastFetchSize:data.length,currentPage:currentPage+1});
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    }
  }

  render(){
    let {pageSize,currentPage} = this.state;

    let parametersUrl = MyAppPool.cLocation.formatedUrlParameters;
    let searchQuery=parametersUrl["query"];

    if (!isSet(searchQuery))
        return <div  className=' contentArea threadsSection'>{lng("No Search Query Entered")}</div>
    
    let meta = {title:lng('Forum')+" "+lng('Pesquisar')+' | NANI',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    let tag = Number(getRouteParamAtIndex(getCurrentUrl(),3));
    
    let searchThreadIDPages = getIfIsSetElems(MyAppPool.session.neocomu.searchThreads,[""+searchQuery,""+pageSize]);
    let allPages=[];
    let name=searchQuery;
    if (isSet(searchThreadIDPages)) 
    {
        allPages = Object.values(searchThreadIDPages).flat();   
    }

      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div >
            <div  className=' contentArea'>
            <ContentBannerSetter />
            <div className="threadsSection">
                <h2>/{lng("Pesquisar")}:{name}</h2>
                <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                {allPages.map((elemId,index)=>{
                let elem = MyAppPool.session.neocomu.threads[elemId];
                return  <ThreadPreview key={index} thread={elem} query={searchQuery}/> 
                })}
                </InfiniteScrollBlock>
            </div>
            </div>
          </div>
        </div>
      );
}

}

export default ForumSearch;
