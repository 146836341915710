export default {
    "entrar": {
      "en": "enter",
      "pt": "entrar",
      "es": "entra",
      "de": "eintr"
    },
    "listas": {
      "en": "lists",
      "pt": "listas",
      "es": "listas",
      "de": "listen"
    },
    "favoritos": {
      "en": "favorites",
      "pt": "favoritos",
      "es": "favoritos",
      "de": "favoriten"
    },
    "entrar": {
      "en": "enter",
      "pt": "entrar",
      "es": "entra",
      "de": "eintr"
    },
    "recuperar": {
      "en": "recover",
      "pt": "recuperar",
      "es": "recuperar",
      "de": "genesen"
    },
    "confirmar": {
      "en": "confirm",
      "pt": "confirmar",
      "es": "confirmar",
      "de": "bestatigen"
    },
    "personagem": {
      "en": "character",
      "pt": "personagem",
      "es": "personaje",
      "de": "charakter"
    },
    "pessoa": {
      "en": "person",
      "pt": "pessoa",
      "es": "persona",
      "de": "person"
    },
    "manga": {
      "en": "manga",
      "pt": "manga",
      "es": "manga",
      "de": "manga"
    },
    "anime": {
      "en": "anime",
      "pt": "anime",
      "es": "anime",
      "de": "anime"
    },
    "reviews": {
      "en": "reviews",
      "pt": "reviews",
      "es": "reviews",
      "de": "reviews"
    },
    "personagens": {
      "en": "characters",
      "pt": "personagens",
      "es": "personajes",
      "de": "charaktere"
    },
    "MAB por MaxBrasi": {
      "en": "♪ Nani! ♪ by LeFerdie",
      "pt": "Nani! por LeFerdie",
      "es": "¡Nani! por LeFerdie",
      "de": "♪ Nani! von LeFerdie"
    },
    "Imagem acima do limite": {
      "en": "Image above the limit",
      "pt": "Imagem acima do limite",
      "es": "Imagen por encima del límite",
      "de": "Bild über dem Limit"
    },
    "Imagem Deletada com Sucesso!":{
      "en": "Image sucessfully deleted!",
      "pt": "-",
      "es": "¡Imagen eliminada exitosamente!",
      "de": "Bild erfolgreich gelöscht!"
    },
    "Gif Acima do Limite": {
      "en": "Gifs over the limit",
      "pt": "Gif Acima do Limite",
      "es": "Gif por encima del límite",
      "de": "Gif über dem Limit"
    },
    "Erro": {
      "en": "Error",
      "pt": "Erro",
      "es": "Erro.",
      "de": "Fehler"
    },
    "ou": {
      "en": "or",
      "pt": "ou",
      "es": "o",
      "de": "oder"
    },
    "Adicionar": {
      "en": "Add",
      "pt": "Adicionar",
      "es": "Añadir",
      "de": "Hinzufügen"
    },
    "Arte": {
      "en": "Art",
      "pt": "Arte",
      "es": "Art",
      "de": "Kunst"
    },
    "Listas": {
      "en": "Lists",
      "pt": "Listas",
      "es": "Listas",
      "de": "Listen"
    },
    "Favoritos": {
      "en": "Favorites",
      "pt": "Favoritos",
      "es": "Favoritos",
      "de": "Favoriten"
    },
    "Música/Sons": {
      "en": "Music/Sound Effects.",
      "pt": "Música/Sons",
      "es": "Música y Sonidos",
      "de": "Musik/Sounds"
    },
    "Personagens": {
      "en": "Characters",
      "pt": "Personagens",
      "es": "Personajes",
      "de": "Charaktere"
    },
    "História": {
      "en": "History",
      "pt": "História",
      "es": "Historia",
      "de": "Geschichte"
    },
    "Final": {
      "en": "Final",
      "pt": "Final",
      "es": "Final",
      "de": "Abschlussnote"
    },
    "bold": {
      "en": "bold",
      "pt": "Negrito",
      "es": "Negrita",
      "de": "schwarz"
    },
    "italic": {
      "en": "italic",
      "pt": "Itálico",
      "es": "enitalico",
      "de": "kurzschrift"
    },
    "underline": {
      "en": "underline",
      "pt": "Sublinhar",
      "es": "subrayar",
      "de": "unterstreichen"
    },
    "strikethrough": {
      "en": "strikethrough",
      "pt": "Riscar",
      "es": "rayado",
      "de": "zerkratzt"
    },
    "superscript": {
      "en": "superscript",
      "pt": "Sobrescrito",
      "es": "superíndice",
      "de": "überschrift"
    },
    "link": {
      "en": "link",
      "pt": "Link",
      "es": "enlace",
      "de": "link"
    },
    "image": {
      "en": "image",
      "pt": "Imagem",
      "es": "imagen",
      "de": "bild"
    },
    "emoji": {
      "en": "emoji",
      "pt": "Emoji",
      "es": "emojis",
      "de": "emojis"
    },
    "spoiler": {
      "en": "spoiler",
      "pt": "Spoiler",
      "es": "revelación",
      "de": "Spoiler"
    },
    "insertOrderedList": {
      "en": "Insert ordered list",
      "pt": "Inserir Lista Ordernada",
      "es": "Insertar una lista ordenada.",
      "de": "Einfügen einer geordneten Liste"
    },
    "insertUnorderedList": {
      "en": "Insert unordered list",
      "pt": "Inserir Lista Sem Ordem",
      "es": "Insertar en una lista sin orden",
      "de": "Liste ohne Reihenfolge einfügen"
    },
    "Compartilhar": {
      "en": "Share",
      "pt": "Compartilhar",
      "es": "Cuota",
      "de": "Teilen"
    },
    "Resp": {
      "en": "Resp",
      "pt": "Resp",
      "es": "Resp",
      "de": "Resp"
    },
    "em": {
      "en": "in",
      "pt": "em",
      "es": "en",
      "de": "in"
    },
    "Análises": {
      "en": "Reviews",
      "pt": "Resenhas",
      "es": "Reseñas",
      "de": "Bewertungen"
    },
    "Reações": {
      "en": "Impressions",
      "pt": "Impressões",
      "es": "Impresiones",
      "de": "Eindrücke"
    },
    "Distribuição das Notas": {
      "en": "Distribution of Grades",
      "pt": "Distribuição das Notas",
      "es": "Distribución de las Notas",
      "de": "Verteilung der Noten"
    },
    "Trilha Sonora": {
      "en": "Soundtrack",
      "pt": "Trilha Sonora",
      "es": "Banda Sonora",
      "de": "Klangtrack"
    },
    "youtube": {
      "en": "Youtube Video",
      "pt": "Youtube Video",
      "es": "Youtube Video",
      "de": "Video auf Youtube"
    },
    "removeformat": {
      "en": "Remove Formatting",
      "pt": "Remover Formatação",
      "es": "Eliminar Formato",
      "de": "Entfernen von Formatierung"
    },
    "O que esta pensando ?": {
      "en": "What are you thinking?",
      "pt": "O que esta pensando ?",
      "es": "¿ Qué estás pensando?",
      "de": "Was ist denn los?"
    },
    "Enviar": {
      "en": "Send",
      "pt": "Enviar",
      "es": "Envío",
      "de": "Übersenden"
    },
    "Fechar": {
      "en": "Close",
      "pt": "Fechar",
      "es": "Cierre",
      "de": "Schließen"
    },
    "Mover para Esquerda":{
      "en": "Move Left",
      "pt": "-",
      "es": "Mover a la izquierda",
      "de": "geh nach links"
    },
    "Mover para Direita":{
      "en": "Move Right",
      "pt": "-",
      "es": "mover a la derecha",
      "de": "nach rechts bewegen"
    },
    "Confirmar": {
      "en": "Confirm",
      "pt": "Confirmar",
      "es": "Confirmar",
      "de": "Bestätigen"
    },
    "Cancelar": {
      "en": "Cancel",
      "pt": "Cancelar",
      "es": "Cancelar",
      "de": "Abbrechen"
    },
    "Clique e Escreva uma Análise!": {
      "en": "Click and write a review!",
      "pt": "Clique e Escreva uma Resenha!",
      "es": "¡Clic y escribe una reseña!",
      "de": "Klicken und eine Bewertungen schreiben!"
    },
    "Compartilhar no Facebook": {
      "en": "Share on Facebook",
      "pt": "Compartilhar no Facebook",
      "es": "Compartir en Facebook",
      "de": "Facebook-Teilen"
    },
    "Compartilhar no Twitter": {
      "en": "Share on X(Twitter)",
      "pt": "Compartilhar no X(Twitter)",
      "es": "Compartir en X(Twitter).",
      "de": "X(Twitter)-Teilen"
    },
    "Compartilhar no Reddit": {
      "en": "Share on Reddit",
      "pt": "Compartilhar no Reddit",
      "es": "Compartir en Reddit",
      "de": "Reddit-Teilen"
    },
    "Compartilhar no Tumblr": {
      "en": "Share on Tumblr",
      "pt": "Compartilhar no Tumblr",
      "es": "Compartir en Tumblr",
      "de": "Tumblr-Teilen"
    },
    "Copiar para à Area de Colagem": {
      "en": "Copy to the collage area",
      "pt": "Copiar para à Area de Colagem",
      "es": "Copiar para el área de pegamento",
      "de": "In Zwischenablage kopieren"
    },
    "Nota": {
      "en": "Grade",
      "pt": "Nota",
      "es": "Nota",
      "de": "Note"
    },
    "usuários": {
      "en": "users",
      "pt": "usuários",
      "es": "usuarios",
      "de": "benutzer"
    },
    "Por": {
      "en": "By",
      "pt": "Por",
      "es": "Por",
      "de": "Von"
    },
    "Ninguém escreveu uma reação ainda! Seja o primeiro(a)!!": {
      "en": "No one has written a reaction yet! Be the first!!!",
      "pt": "Ninguém escreveu uma reação ainda! Seja o primeiro(a)!!",
      "es": "¡No hay reacciones todavía! ¡Sé el primero!!!",
      "de": "Noch hat niemand eine Reaktion geschrieben! Sei der Erste!!!"
    },
    "Ninguém escreveu uma análise ainda! Seja o primeiro(a)!!": {
      "en": "No one has written a review yet! Be the first!!!",
      "pt": "Ninguém escreveu uma resenha ainda! Seja o primeiro(a)!!",
      "es": "¡Nadie ha escrito una reseña todavía! ¡Sé el primero!",
      "de": "Noch hat niemand eine Bewertung geschrieben! Sei der Erste!!!"
    },
    "respondeu": {
      "en": "answered",
      "pt": "respondeu",
      "es": "respondió",
      "de": "antwortete"
    },
    "Adicionar a lista de música": {
      "en": "Add to the playlist",
      "pt": "Adicionar a lista de música",
      "es": "Añadir la lista de música",
      "de": "Die Playlist hinzufügen"
    },
    "Adicionar ao fim da fila de reprodução": {
      "en": "Add to end of playback row.",
      "pt": "Adicionar ao fim da fila de reprodução",
      "es": "Añadir al final de la fila de reproducción",
      "de": "Hinzufügen an die Spielaufstellung"
    },
    "Tocar": {
      "en": "Play",
      "pt": "Tocar",
      "es": "Tocar",
      "de": "Spiel"
    },
    "Tocando": {
      "en": "♪ Playing ♪",
      "pt": "Tocando",
      "es": "Tocando",
      "de": "Spielen"
    },
    "Fila de Reprodução": {
      "en": "Reproduction list",
      "pt": "Fila de Reprodução",
      "es": "Línea de reproducción",
      "de": "Reproduktionsliste"
    },
    "meusanimesbrasili": {
      "en": "our-anime",
      "pt": "nosso-anime",
      "es": "nuestro-anime",
      "de": "unser-anime"
    },
    "Lançamento": {
      "en": "Release Date",
      "pt": "Data de Lançamento",
      "es": "Dato de lanzamiento",
      "de": "Einführungstermin"
    },
    "Finale": {
      "en": "Finale",
      "pt": "Finale",
      "es": "Finale",
      "de": "Endes"
    },
    "Segunda-Feira": {
      "en": "Monday",
      "pt": "Segunda-Feira",
      "es": "El lunes",
      "de": "Montag"
    },
    "Terça-Feira": {
      "en": "Tuesdays",
      "pt": "Terça-Feira",
      "es": "El martes",
      "de": "Dienstag"
    },
    "Quarta-Feira": {
      "en": "Wednesdays",
      "pt": "Quarta-Feira",
      "es": "Miércoles",
      "de": "Mittwoch"
    },
    "Quinta-Feira": {
      "en": "Thursdays",
      "pt": "Quinta-Feira",
      "es": "El jueves",
      "de": "Donnerstag"
    },
    "Sexta-Feira": {
      "en": "Friday",
      "pt": "Sexta-Feira",
      "es": "Viernes",
      "de": "Freitag"
    },
    "Sábado": {
      "en": "Saturdays",
      "pt": "Sábado",
      "es": "Sábado",
      "de": "Samstag"
    },
    "Domingo": {
      "en": "Sunday",
      "pt": "Domingo",
      "es": "El dom",
      "de": "Sonntag"
    },
    "às": {
      "en": "by",
      "pt": "às",
      "es": "a las",
      "de": "bei"
    },
    "Exibição": {
      "en": "Exhibition",
      "pt": "Exibição",
      "es": "Exhibición",
      "de": "Ausstellung"
    },
    "(s)": {
      "en": "(s)",
      "pt": "(s)",
      "es": "(s)",
      "de": "(e/em)"
    },
    "Episódios": {
      "en": "Episodes",
      "pt": "Episódios",
      "es": "Episodios",
      "de": "Episoden"
    },
    "Duração": {
      "en": "Duration",
      "pt": "Duração",
      "es": "Duración",
      "de": "Dauer"
    },
    "minutos": {
      "en": "minutes",
      "pt": "minutos",
      "es": "minutos",
      "de": "minuten"
    },
    "Tipo": {
      "en": "Type",
      "pt": "Tipo",
      "es": "Tipo",
      "de": "Typ"
    },
    "TV": {
      "en": "TV",
      "pt": "TV",
      "es": "TV",
      "de": "Fernseher"
    },
    "TV Short": {
      "en": "TV Short",
      "pt": "TV(Curta)",
      "es": "TV(Curta)",
      "de": "TV(Kurz)"
    },
    "Movie": {
      "en": "Movie",
      "pt": "Filme",
      "es": "Filme",
      "de": "Film"
    },
    "OVA": {
      "en": "Original video animation (OVA)",
      "pt": "Animação Original de Vídeo (OVA)",
      "es": "Animación original de vídeo (OVA)",
      "de": "Original-Videoanimation (OVA)"
    },
    "ONA": {
      "en": "Original Internet animation (ONA)",
      "pt": "Animação Original de Internet (ONA)",
      "es": "Animación original de Internet (ONA)",
      "de": "Original Internet Animation (ONA)"
    },
    "Special": {
      "en": "Special",
      "pt": "Especial",
      "es": "Especial",
      "de": "Speziell"
    },
    "Indicação": {
      "en": "Indication",
      "pt": "Indicação",
      "es": "Indicación",
      "de": "Empfehlung"
    },
    "Origem": {
      "en": "Origin",
      "pt": "Origem",
      "es": "Origen",
      "de": "Herkunft"
    },
    "Temporada": {
      "en": "Season",
      "pt": "Temporada",
      "es": "Temporada",
      "de": "Jahreszeit"
    },
    "Outono": {
      "en": "Autumn",
      "pt": "Outono",
      "es": "Otoño",
      "de": "Herbst"
    },
    "Inverno": {
      "en": "Winter",
      "pt": "Inverno",
      "es": "Invierno",
      "de": "Winter"
    },
    "Primavera": {
      "en": "Spring",
      "pt": "Primavera",
      "es": "Primavera",
      "de": "Frühling"
    },
    "Verão": {
      "en": "Summer.",
      "pt": "Verão",
      "es": "Verano",
      "de": "Sommer"
    },
    "Outros Titulos": {
      "en": "Other titles",
      "pt": "Outros Titulos",
      "es": "Otros títulos",
      "de": "Andere Titel"
    },
    "Querem": {
      "en": "Want",
      "pt": "Querem",
      "es": "Quieren",
      "de": "Wollen"
    },
    "Assistem": {
      "en": "Watching",
      "pt": "Assistem",
      "es": "Miran",
      "de": "Sehen"
    },
    "Concluiram": {
      "en": "Finished",
      "pt": "Concluiram",
      "es": "Terminaron",
      "de": "Sind Fertig"
    },
    "Largaram": {
      "en": "Dropped",
      "pt": "Largaram",
      "es": "Abandonado",
      "de": "Losgelassen"
    },
    "Usuários": {
      "en": "Users",
      "pt": "Usuários",
      "es": "Usuarios",
      "de": "Benutzer"
    },
    "Usuário": {
      "en": "User",
      "pt": "Usuário",
      "es": "Usuario",
      "de": "Benutzer"
    },
    "User": {
      "en": "-",
      "pt": "Usuário",
      "es": "Usuario",
      "de": "Benutzer"
    },
    "avatar":{
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "Benutzerbild"
    },
    "Avatar available":{
      "en": "-",
      "pt": "Avatar disponível",
      "es": "Avatar disponible",
      "de": "Avatar verfügbar"
    },
    "Banner available":{
      "en": "-",
      "pt": "Banner disponível",
      "es": "Banner disponible",
      "de": "Banner verfügbar"
    },
    "Nenhuma obra relacionada encontrada, conhece uma ? Adicione!": {
      "en": "No related work found, do you know of one? Add it here!",
      "pt": "Nenhuma obra relacionada encontrada, conhece uma ? Adicione!",
      "es": "No se encontraron obras relacionadas, ¿conoces una? ¡Aúnta!",
      "de": "Keine verwandten Werke gefunden, kennst du eine? Zähl!"
    },
    "Nenhuma análise encontrada, escreva uma!": {
      "en": "No analysis found, write one!",
      "pt": "Nenhuma resenha encontrada, escreva uma!",
      "es": "No encontraron reseñas, escriban uno.",
      "de": "Keine Bewertung gefunden, schreibe eine!"
    },
    "Adicionar Obras Relacionadas": {
      "en": "Add Related Works",
      "pt": "Adicionar Obras Relacionadas",
      "es": "Añadir obras relacionadas...",
      "de": "Hinzufügen von verwandten Werken"
    },
    "Nenhum encontrado": {
      "en": "None found",
      "pt": "Nenhum encontrado",
      "es": "No se encuentra",
      "de": "Keine gefunden"
    },
    "Obras Relacionadas": {
      "en": "Related works",
      "pt": "Obras Relacionadas",
      "es": "Obras relacionadas..",
      "de": "Verwandte Werke"
    },
    "Nenhum personagem encontrado, conhece um ? Adicione!": {
      "en": "No character found, do you know one? Add up!",
      "pt": "Nenhum personagem encontrado, conhece um ? Adicione!",
      "es": "No encontramos ningún personaje, ¿conoces uno?",
      "de": "Keine Figur gefunden, kennst du eine? Zähl!"
    },
    "Adicionar Personagem": {
      "en": "Add Character",
      "pt": "Adicionar Personagem",
      "es": "Añadir personaje",
      "de": "Charakter hinzufügen"
    },
    "Personagem": {
      "en": "Character",
      "pt": "Personagem",
      "es": "Personaje",
      "de": "Charakter"
    },
    "Character": {
      "en": "-",
      "pt": "Personagem",
      "es": "Personaje",
      "de": "Charakter"
    },
    "Autor": {
      "en": "Author",
      "pt": "Autor",
      "es": "Autor",
      "de": "Autor(in)"
    },
    "Autores": {
      "en": "Authors",
      "pt": "Autores",
      "es": "Autores",
      "de": "Autoren"
    },
    "Nenhuma pessoa, conhece uma ? Adicione!": {
      "en": "No one, you know one? Add it here!",
      "pt": "Nenhuma pessoa, conhece uma ? Adicione!",
      "es": "¿Ninguna persona, conoce a una?",
      "de": "Keine Person, kennst du eine? Zähl!"
    },
    "Adicionar pessoas": {
      "en": "Add people",
      "pt": "Adicionar pessoas",
      "es": "Añadir personas",
      "de": "Personen hinzufügen"
    },
    "Nenhuma música encontrada": {
      "en": "No music found",
      "pt": "Nenhuma música encontrada",
      "es": "No se encuentra ninguna canción",
      "de": "Keine Musik gefunden"
    },
    "Geral": {
      "en": "General",
      "pt": "Geral",
      "es": "General",
      "de": "Allgemeine"
    },
    "Reviews": {
      "en": "Reviews",
      "pt": "Resenhas",
      "es": "Reseñas",
      "de": "Bewertungen"
    },
    "Review": {
      "en": "Review",
      "pt": "Resenha",
      "es": "Reseña",
      "de": "Bewertung"
    },
    "Staff": {
      "en": "Staff",
      "pt": "Staff",
      "es": "Personal",
      "de": "Die Mitarbe"
    },
    "autores": {
      "en": "authors",
      "pt": "autores",
      "es": "autores",
      "de": "autoren"
    },
    "Relacionados": {
      "en": "Related",
      "pt": "Relacionados",
      "es": "Relacionados",
      "de": "Verwandt"
    },
    "obras-relacionadas": {
      "en": "related-works",
      "pt": "obras-relacionadas",
      "es": "obras-relacionadas",
      "de": "verwandte-arbeiten"
    },
    "Social": {
      "en": "Social",
      "pt": "Social",
      "es": "Social",
      "de": "Sozial"
    },
    "social": {
      "en": "social",
      "pt": "social",
      "es": "social",
      "de": "sozial"
    },
    "arte": {
      "en": "art",
      "pt": "arte",
      "es": "arte",
      "de": "kunst"
    },
    "Conheça!": {
      "en": "Discover",
      "pt": "Conheça!",
      "es": "¡Ver mais!",
      "de": "Mehr sehen"
    },
    "Protagonista": {
      "en": "Protagonist",
      "pt": "Protagonista",
      "es": "Protagonista",
      "de": "Hauptdarsteller"
    },
    "Atores e Atrizes": {
      "en": "Actors and actresses",
      "pt": "Atores e Atrizes",
      "es": "Actores y actrices",
      "de": "Schauspieler und Schauspielerinnen"
    },
    "Nenhuma ator/atriz encotrado, conhece um ? Adicione!": {
      "en": "No actors found, do you know one? Add up!",
      "pt": "Nenhuma ator/atriz encotrado, conhece um ? Adicione!",
      "es": "No hay actores, ¿conoces a alguno? ¡Aún!",
      "de": "Keine Schauspielerin gefunden, kennst du eine? Zähl!"
    },
    "Adicionar ator / atriz": {
      "en": "Add actor / actress.",
      "pt": "Adicionar ator / atriz",
      "es": "Añadir actor / actriz.",
      "de": "Schauspieler hinzufügen"
    },
    "Língua ": {
      "en": "Language ",
      "pt": "Língua ",
      "es": "Idioma ",
      "de": "Sprache "
    },
    "Manga": {
      "en": "Manga",
      "pt": "Manga",
      "es": "Manga",
      "de": "Manga"
    },
    "Mangás": {
      "en": "Mangás",
      "pt": "Mangás",
      "es": "Mangás",
      "de": "Mangás"
    },
    "Anime": {
      "en": "Anime",
      "pt": "Anime",
      "es": "Anime",
      "de": "Anime"
    },
    "Animes": {
      "en": "Animes",
      "pt": "Animes",
      "es": "Animes",
      "de": "Animes"
    },
    "Editora": {
      "en": "Publisher",
      "pt": "Editora",
      "es": "Editora",
      "de": "Verleger"
    },
    "Produtora": {
      "en": "Producer",
      "pt": "Produtora",
      "es": "Producción",
      "de": "Hersteller"
    },
    "Producer": {
      "en": "-",
      "pt": "Produtora",
      "es": "Producción",
      "de": "Hersteller"
    },
    "Doujinshi": {
      "en": "Doujinshi ( Fan work )",
      "pt": "Doujinshi ( Obra de Fã )",
      "es": "Doujinshi (Trabajo de un fan)",
      "de": "Doujinshi ( Fan arbeit )"
    },
    "Manhwa": {
      "en": "Manhwa (Korean comic)",
      "pt": "Manhwa ( Quadrinho Koreano )",
      "es": "Manhwa (Cómic coreano)",
      "de": "Manhwa (Koreanisches Comic)"
    },
    "Manhua": {
      "en": "Manhua (Chinese comic)",
      "pt": "Manhua ( Quadrinho Chinês )",
      "es": "Manhua (Cómic chino)",
      "de": "Manhua (Chinesische Comic)"
    },
    "One-Shot": {
      "en": "One-Shot",
      "pt": "Volume Único",
      "es": "Volumen único",
      "de": "Einbändige"
    },
    "One-shot": {
      "en": "One-Shot",
      "pt": "Volume Único",
      "es": "Volumen único",
      "de": "Einbändige"
    },
    "Novel": {
      "en": "Novel",
      "pt": "Romance",
      "es": "Romance",
      "de": "Romantik"
    },
    "autor": {
      "en": "author",
      "pt": "autor",
      "es": "autor",
      "de": "autor"
    },
    "Error Creating Review": {
      "en": "Error creating the review",
      "pt": "Erro Criando a Review",
      "es": "Error en la creación del resenã",
      "de": "Fehler beim Erstellen der Bewertung"
    },
    "Houve um erro enviando esse conteúdo.":{
      "en": "An error happened while trying to send this content.",
      "pt": "Houve um erro ao tentar enviar esse conteúdo.",
      "es": "Ocurrió un error al intentar enviar este contenido.",
      "de": "Beim Versuch, diesen Inhalt zu senden, ist ein Fehler aufgetreten."
    },
    "Faça uma análise sobre sua experiência com ": {
      "en": "Do a review of your experience with the ",
      "pt": "Faça uma resenha sobre sua experiência com ",
      "es": "Haz una resenã de tu experiencia con  ",
      "de": "Schreibe eine Bewertung über deine Erfahrung mit "
    },
    "Tem certeza que quer deletar essa análise?": {
      "en": "Are you sure you want to delete that review?",
      "pt": "Tem certeza que quer deletar essa resenha?",
      "es": "¿Estás seguro de que quieres borrar ese resenã?",
      "de": "Bist du sicher, dass du diese Bewertung löschen wollen?"
    },
    "Deletar Análise": {
      "en": "Delete Review",
      "pt": "Deletar Resenha",
      "es": "Eliminar el resenã",
      "de": "Bewertung Löschen"
    },
    "Deletar":{
      "en": "Delete",
      "pt": "Deletar",
      "es": "Eliminar",
      "de": "Löschen"
    },
    "Eu achei essa review útil!": {
      "en": "I found this helpful!",
      "pt": "Me ajudou!",
      "es": "¡Me ayudó!",
      "de": "Du hast mir geholfen!"
    },
    "Assistido": {
      "en": "Watched",
      "pt": "Assistido",
      "es": "Visto",
      "de": "Schaute"
    },
    "Assistindo": {
      "en": "Watching",
      "pt": "Assistindo",
      "es": "Viendo",
      "de": "Aufpassen"
    },
    "Quero Assistir": {
      "en": "Want to watch",
      "pt": "Quero Assistir",
      "es": "Quiero ver",
      "de": "Will zusehen"
    },
    "Capa": {
      "en": "Cover Art",
      "pt": "Capa",
      "es": "Arte de la Cubierta",
      "de": "Deckblattkunst"
    },
    "Foto":{
      "en": "Photography",
      "pt": "Fotografia",
      "es": "Fotografía",
      "de": "Fotografie"
    },
    "Selecionador de Tipo de Busca":{
      "en": "Search Content Type Selector",
      "pt": "-",
      "es": "Selector de tipo de contenido de búsqueda",
      "de": "Suchinhaltstypauswahl"
    },
    "Favoritar":{
      "en": "Favorite",
      "pt": "-",
      "es": "Favorito",
      "de": "Favorit"
    },
    "User uploaded Image":{
      "en": "-",
      "pt": "Imagem enviada pelo usuário",
      "es": "Imagen cargada por el usuario",
      "de": "Vom Benutzer hochgeladenes Bild"
    },
    "_errgif":{
      "en": "GIFs are only allowed for supportive users, Sponsor level or Above.",
      "pt": "GIFs são permitidos apenas para usuários apoiadores, de nível Patrocionador ou Maior.",
      "es": "Los GIF solo están permitidos para usuarios de apoyo, nivel de patrocinador o superior.",
      "de": "GIFs sind nur für unterstützende Benutzer ab Sponsor-Level zulässig."
    },
    "Adicionar Música":{
      "en": "Add Music",
      "pt": "-",
      "es": "Añadir Música",
      "de": "Zusätzliche Musik"
    },
    "banner": {
      "en": "banner",
      "pt": "banner",
      "es": "elbanner",
      "de": "banner"
    },
    "Esperando Ativação": {
      "en": "Waiting for activation",
      "pt": "Esperando Ativação",
      "es": "Esperando la activación",
      "de": "Warten auf Aktivierung"
    },
    "Em poucas palavras, o que você diria de ": {
      "en": "In a nutshell, what would you say of ",
      "pt": "Em poucas palavras, o que você diria de ",
      "es": "En pocas palabras, ¿qué dirías de ",
      "de": "Kurz gesagt, was würdest du sagen über "
    },
    "Deletar Reação": {
      "en": "Delete the reaction",
      "pt": "Deletar Reação",
      "es": "Eliminar la reacción",
      "de": "Löschen Reaktion"
    },
    "Tem certeza?": {
      "en": "Are you sure?",
      "pt": "Tem certeza?",
      "es": "¿Estás seguro?",
      "de": "Bist du sicher?"
    },
    "Todas": {
      "en": "All",
      "pt": "Todas",
      "es": "Todas",
      "de": "Alle"
    },
    "Mais": {
      "en": "More",
      "pt": "Mais",
      "es": "Más",
      "de": "Mehr"
    },
    "Menos": {
      "en": "Less",
      "pt": "Menos",
      "es": "Menos",
      "de": "Weniger"
    },
    "usuario": {
      "en": "user",
      "pt": "usuario",
      "es": "usuario",
      "de": "benut"
    },
    "Todos os Animes": {
      "en": "All Anime",
      "pt": "Todos os Animes",
      "es": "Todos los Animes",
      "de": "Alle Anime"
    },
    "empresa": {
      "en": "company",
      "pt": "empresa",
      "es": "empresa",
      "de": "firma"
    },
    "Estúdio(s)": {
      "en": "Studio(s)",
      "pt": "Estúdio(s)",
      "es": "Estudio(s)",
      "de": "Studio(s)"
    },
    "Licenciado por": {
      "en": "Licensed by",
      "pt": "Licenciado por",
      "es": "Licenciado por",
      "de": "Lizenziert von"
    },
    "Produtor(as)": {
      "en": "Producer(s)",
      "pt": "Produtor(as)",
      "es": "Productor(es)",
      "de": "Hersteller(n)"
    },
    "Meus Animes Brasili": {
      "en": "Our Anime Network",
      "pt": "Nosso Anime Network",
      "es": "Nuestro Anime Network",
      "de": "Unser Anime Network"
    },
    "Meus Anime Brasili , tudo sobre animes e mangas em um lugar só! Comunidade localizada de animes e mangá ! Siga as têndencias de animes, conheça a trilha sonora de animes, veja as datas de lançamento de animes , os trailers de animes , crie suas listas de animes, mangás , empresas , musicas , e muito mais!": {
      "en": "Our Anime Network, all about anime and manga in one place! Local community of anime and manga! Follow anime trends, know anime soundtracks, see anime release dates, anime trailers, create your lists of anime, manga, companies, music, and more! The network is a great way to get fans to enjoy anime and manga!",
      "pt": "Nosso Anime Network , tudo sobre animes e mangas em um lugar só! Comunidade localizada de animes e mangá ! Siga as têndencias de animes, conheça a trilha sonora de animes, veja as datas de lançamento de animes , os trailers de animes , crie suas listas de animes, mangás , empresas , musicas , e muito mais!",
      "es": "Nuestro Anime Network, todo sobre anime y manga en un solo lugar! comunidad localizada de anime y manga! sigue las tendencias de anime, conoce la banda sonora de anime, mira las fechas de lanzamiento de anime, los trailers de anime, crea tus listas de anime, manga, empresas, música, y mucho más! ¡Ahora, puedes ver el anime y manga en todo el mundo!",
      "de": "Unser Anime Network, alles über Anime und Manga an einem Ort! Lokalisierte Community von Anime und Manga! Folge den Anime Trends, erfahre die Anime Soundtracks, siehe Anime Release Dates, Anime Trailer, erstelle deine Anime, Manga, Unternehmen, Musiklisten und vieles mehr! Ich bin ein großer Fan von Anime und Manga! Ich bin ein großer Fan von Anime und Manga!"
    },
    "Sinopse": {
      "en": "Synopsis",
      "pt": "Sinopse",
      "es": "Sinopsis",
      "de": "Zusammenfassung"
    },
    "Capa de": {
      "en": "Cover of",
      "pt": "Capa de",
      "es": "Capa de",
      "de": "Titelbild von"
    },
    "seguem": {
      "en": "follows",
      "pt": "seguem",
      "es": "sigueadelante",
      "de": "folgt"
    },
    "são": {
      "en": "are",
      "pt": "são",
      "es": "son",
      "de": "sind"
    },
    "Personagens de": {
      "en": "Characters from",
      "pt": "Personagens de",
      "es": "Personajes de",
      "de": "Charaktere von"
    },
    "Staff de": {
      "en": "Staff of",
      "pt": "Staff de",
      "es": "El personal de",
      "de": "Die Mitarbeiter von"
    },
    "Reviews de": {
      "en": "Reviews of",
      "pt": "Resenhas de",
      "es": "Resenãs de",
      "de": "Die Bewertungen von"
    },
    "Filmes , Mangás , Novels , Temporadas de": {
      "en": "Movies, Manga, Novels, seasons of",
      "pt": "Filmes , Mangás , Novels , Temporadas de",
      "es": "Películas, Manga, Novelas, Temporadas de",
      "de": "Filme, Manga, Romane, Saison von "
    },
    "Filtrar por Nome": {
      "en": "Search by Name",
      "pt": "Buscar por Nome",
      "es": "Buscar por nombre",
      "de": "Nach Namen suchen"
    },
    "Relação": {
      "en": "Relation",
      "pt": "Relação",
      "es": "Relación",
      "de": "Beziehung"
    },
    "Posição": {
      "en": "Position",
      "pt": "Posição",
      "es": "Posición",
      "de": "Position"
    },
    "Filtrar Por Avaliação": {
      "en": "Filter by rating",
      "pt": "Filtrar Por Avaliação",
      "es": "Filtrar por evaluación",
      "de": "Filtern nach Bewertung"
    },
    "Ruim": {
      "en": "Bad",
      "pt": "Ruim",
      "es": "Malo",
      "de": "Schlecht"
    },
    "Mediano": {
      "en": "Median",
      "pt": "Mediano",
      "es": "Mediano",
      "de": "Durchschnittliche"
    },
    "Boa": {
      "en": "Good",
      "pt": "Boa",
      "es": "Bueno",
      "de": "Gut"
    },
    "Excelente": {
      "en": "Excelent",
      "pt": "Excelente",
      "es": "Excelente",
      "de": "Hervorragend"
    },
    "Adicionar Nota": {
      "en": "Add grade",
      "pt": "Adicionar Nota",
      "es": "Añadir nota",
      "de": "Hinzufügen note"
    },
    "Você votou ": {
      "en": "You voted ",
      "pt": "Você votou ",
      "es": "Usted votó ",
      "de": "Du hast gewählt "
    },
    "Criar Lista": {
      "en": "Create a list",
      "pt": "Criar Lista",
      "es": "Crear una lista",
      "de": "Liste Erstellen"
    },
    "Nome da Lista": {
      "en": "List Name",
      "pt": "Nome da Lista",
      "es": "Nombre de la lista",
      "de": "Name der Liste"
    },
    "revista": {
      "en": "magazine",
      "pt": "revista",
      "es": "revista",
      "de": "zeitschrift"
    },
    "Revista(s)": {
      "en": "Magazine(s)",
      "pt": "Revista(s)",
      "es": "Revista (s)",
      "de": "Zeitschrift(en)"
    },
    "Capítulos": {
      "en": "Chapters",
      "pt": "Capítulos",
      "es": "Capítulos",
      "de": "Kapitel"
    },
    "Volumes": {
      "en": "Volumes",
      "pt": "Volumes",
      "es": "Volúmenes",
      "de": "Zeitschriftenbände"
    },
    "Todos os Mangas": {
      "en": "All Mangas",
      "pt": "Todos os Mangas",
      "es": "Todos los mangas",
      "de": "Alle Mangas"
    },
    "Leem": {
      "en": "Reading",
      "pt": "Leem",
      "es": "Leyendo",
      "de": "Lesen"
    },
    "Empresa": {
      "en": "Company",
      "pt": "Empresa",
      "es": "Empresa",
      "de": "Unternehmen"
    },
    'Revista':{
      "en": "Magazine",
      "pt": "Revista",
      "es": "Revista",
      "de": "Zeitschrift"
    },
    'Publisher':{
      "en": "Magazine",
      "pt": "Revista",
      "es": "Revista",
      "de": "Zeitschrift"
    },
    "Todas as Empresas": {
      "en": "All companies",
      "pt": "Todas as Empresas",
      "es": "Todas las empresas..",
      "de": "Alle Unternehmen"
    },
    "Sem informações a mostrar": {
      "en": "No information to show",
      "pt": "Sem informações a mostrar",
      "es": "No hay información que mostrar",
      "de": "Keine Informationen zu zeigen"
    },
    "Música": {
      "en": "Music",
      "pt": "Música",
      "es": "Música",
      "de": "Musik"
    },
    "Produced": {
      "en": "Produced",
      "pt": "Produziu",
      "es": "Producido",
      "de": "Erzeugt"
    },
    "Created": {
      "en": "Created",
      "pt": "Criou",
      "es": "Creó",
      "de": "Erstellt"
    },
    "Licensed": {
      "en": "Licensed",
      "pt": "Licenciou",
      "es": "Licenció",
      "de": "Lizenziert"
    },
    "null": {
      "en": "null",
      "pt": "null",
      "es": "null",
      "de": "null"
    },
    "Animação": {
      "en": "Animation",
      "pt": "Animação",
      "es": "Animación",
      "de": "Animation"
    },
    "Adaptação/Roteiro": {
      "en": "Adaptation/Script",
      "pt": "Adaptação/Roteiro",
      "es": "Adaptación / Guión de la película",
      "de": "Adaption/Spielregister"
    },
    "Nasceu em": {
      "en": "Born in",
      "pt": "Nasceu em",
      "es": "Nacido en",
      "de": "Geboren in"
    },
    "Website": {
      "en": "Website",
      "pt": "Website",
      "es": "Website",
      "de": "Website"
    },
    "Nacionalidade": {
      "en": "Nationality",
      "pt": "Nacionalidade",
      "es": "Nacionalidad",
      "de": "Nationalität"
    },
    "Todas as Pessoas": {
      "en": "All People",
      "pt": "Todas as Pessoas",
      "es": "Todas las personas",
      "de": "Alle Menschen"
    },
    "Pessoa": {
      "en": "Person",
      "pt": "Pessoa",
      "es": "Persona",
      "de": "Person"
    },
    "Person": {
      "en": "-",
      "pt": "Pessoa",
      "es": "Persona",
      "de": "Person"
    },
    "Trabalhou em": {
      "en": "Worked in",
      "pt": "Trabalhou em",
      "es": "Trabajó en",
      "de": "Werke"
    },
    "Atuou": {
      "en": "Acting",
      "pt": "Atuou",
      "es": "Ha actuado",
      "de": "Synchronrollen"
    },
    "Personagens atuados por": {
      "en": "Characters played by",
      "pt": "Personagens atuados por",
      "es": "Personajes interpretados por",
      "de": "Charaktere synchronrollen von"
    },
    "Nenhuma obra encontrada, conhece uma ? Adicione!": {
      "en": "No work found, do you know one? Add it here!",
      "pt": "Nenhuma obra encontrada, conhece uma ? Adicione!",
      "es": "No hay obras encontradas, ¿conoces alguna? ¡Aúnta!",
      "de": "Keine Funde, kennst du eine? Zähl!"
    },
    "Nenhuma papel encontrado, conhece um ? Adicione!": {
      "en": "No role found, do you know one? Add it here!",
      "pt": "Nenhum papel encontrado, conhece um ? Adicione!",
      "es": "No encontraron ningún papel, ¿conocen uno?",
      "de": "Keine Papiere gefunden, kennst du eine? Zähl!"
    },
    "Atores / Atrizes": {
      "en": "Actors / Actresses",
      "pt": "Atores / Atrizes",
      "es": "Actores / Actrices",
      "de": "Schauspieler / Schauspielerin"
    },
    "Recuperar minha senha": {
      "en": "Recover Password",
      "pt": "Recuperar minha senha",
      "es": "Recuperar mi contraseña",
      "de": "Passwort Wiederherstellen"
    },
    "Entrar": {
      "en": "Sign In",
      "pt": "Entrar",
      "es": "Entrar",
      "de": "Anmelden"
    },
    "E-mail": {
      "en": "E-mail",
      "pt": "E-mail",
      "es": "E-mail",
      "de": "E-Mail"
    },
    "Senha": {
      "en": "Password",
      "pt": "Senha",
      "es": "Contraseña",
      "de": "Passwort"
    },
    "Entrar no M.A.B!": {
      "en": "Sign in to Nani!",
      "pt": "Entrar no Nani!",
      "es": "¡Inicia sesión en Nani!",
      "de": "Eintritt bei Nani an!"
    },
    "Visitante": {
      "en": "Visiting",
      "pt": "Visitante",
      "es": "Visitante",
      "de": "Besucher"
    },
    "Sair": {
      "en": "Sign off",
      "pt": "Sair",
      "es": "Cerrar sesión",
      "de": "Abmelden"
    },
    "Nenhum encontrado, conhece um ? Adicione!": {
      "en": "No one found, do you know one? Add it here!",
      "pt": "Nenhum encontrado, conhece um ? Adicione!",
      "es": "No encontré ninguno, ¿conoces uno?",
      "de": "Keine gefunden, kennst du eine? Zähl!"
    },
    "buscar": {
      "en": "search",
      "pt": "buscar",
      "es": "buscar",
      "de": "suchen"
    },
    "Buscar": {
      "en": "Searching",
      "pt": "Buscar",
      "es": "Buscar",
      "de": "Suchen"
    },
    "Adicionar tudo à fila de reprodução": {
      "en": "Add all to playlist",
      "pt": "Adicionar tudo à fila de reprodução",
      "es": "Añadir todo a la cola de reproducción",
      "de": "Alles in die Wiedergabe-Warteschl"
    },
    "Adicionar à fila de reprodução": {
      "en": "Add to playlist",
      "pt": "Adicionar à fila de reprodução",
      "es": "Añadir a la fila de reproducción",
      "de": "Zusatz zur Wiedergabe-Warteschl"
    },
    "Adicionar à playlist": {
      "en": "Add to playlist",
      "pt": "Adicionar à playlist",
      "es": "Añadir a la lista de reproducción",
      "de": "Zum Playlist hinzufügen"
    },
    "Remover da fila de reprodução": {
      "en": "Remove from playlist",
      "pt": "Remover da fila de reprodução",
      "es": "Retirar de la fila de reproducción",
      "de": "Aus der Wiedergabe-Warteschl entfernen"
    },
    "Remover da reprodução": {
      "en": "Remove from reproduction",
      "pt": "Remover da reprodução",
      "es": "Eliminar de la reproducción",
      "de": "Von der Wiedergabe-Warteschl Entfernen"
    },
    "Voltar": {
      "en": "Go back",
      "pt": "Voltar",
      "es": "Volver",
      "de": "Zurück"
    },
    "Há um problema em nosso servidor, tente novamente mais tarde.": {
      "en": "There's a problem on our server, please try again later.",
      "pt": "Há um problema em nosso servidor, tente novamente mais tarde.",
      "es": "Hay un problema en nuestro servidor, intente de nuevo más tarde.",
      "de": "Es gibt ein Problem auf unserem Server, versuchen Sie es später noch einmal."
    },
    "E-mail não encontrado": {
      "en": "Email not found",
      "pt": "E-mail não encontrado",
      "es": "No se ha encontrado correo electrónico",
      "de": "E-Mail nicht gefunden"
    },
    "Pedido enviado incorretamente": {
      "en": "Order sent incorrectly",
      "pt": "Pedido enviado incorretamente",
      "es": "Pedido enviado incorrectamente",
      "de": "Anfrage falsch übermittelt"
    },
    "Um e-mail com instruções para trocar sua senha foi enviado para o seu endereço.": {
      "en": "An e-mail with instructions to change your password was sent to your address",
      "pt": "Um e-mail com instruções para trocar sua senha foi enviado para o seu endereço",
      "es": "Se envió un correo electrónico con instrucciones para cambiar su contraseña a su dirección",
      "de": "Eine E-Mail mit Anweisungen, Ihr Passwort zu ändern, wurde an Ihre Adresse gesendet."
    },
    "Usuário ou Senha incorretos": {
      "en": "User name or password incorrect",
      "pt": "Usuário ou Senha incorretos",
      "es": "Usuario o contraseña incorrectos",
      "de": "Falscher Benutzername oder Passwort"
    },
    "Nova Senha": {
      "en": "New password",
      "pt": "Nova Senha",
      "es": "Nuevo contraseña",
      "de": "Neues Passwort"
    },
    "Repita a Senha": {
      "en": "Repeat the password",
      "pt": "Repita a Senha",
      "es": "Repita el contraseña",
      "de": "Passwort Wiederholen"
    },
    "Há um problema com essa sessão, comece o processo novamente.": {
      "en": "There's a problem with this session, start the process again.",
      "pt": "Há um problema com essa sessão, comece o processo novamente.",
      "es": "Hay un problema con esta sesión, vuelva a empezar el proceso.",
      "de": "Es gibt ein Problem mit dieser Sitzung. Starten Sie den Prozess noch einmal."
    },
    "Sua nova senha foi configurada com sucesso. Redirecionando ao login...": {
      "en": "Your new password was successfully set, redirecting to the login....",
      "pt": "Sua nova senha foi configurada com sucesso. Redirecionando ao login...",
      "es": "Su nueva contraseña ha sido configurada con éxito. Redireccionando para iniciar sesión...",
      "de": "Ihr neues Passwort wurde erfolgreich eingerichtet. Sie werden zum Login weitergeleitet..."
    },
    "As senhas estão diferentes, tente escrever novamente.": {
      "en": "The passwords are different, try typing again.",
      "pt": "As senhas estão diferentes, tente escrever novamente.",
      "es": "Las contraseñas son diferentes, intenta escribir de nuevo.",
      "de": "Die Passwörter sind anders, versuchen Sie es noch einmal."
    },
    "A senha deve ter pelo menos 7 caracteres.": {
      "en": "The password must be at least 7 characters long.",
      "pt": "A senha deve ter pelo menos 7 caracteres.",
      "es": "La contraseña debe tener al menos 7 caracteres.",
      "de": "Das Passwort muss mindestens 7 Zeichen lang sein."
    },
    "Crie uma senha de backup": {
      "en": "Create a backup password",
      "pt": "Crie uma senha de backup",
      "es": "Crear una contraseña de respaldo",
      "de": "Backup-Passwort Erstellen"
    },
    "Sua senha foi configurada com sucesso.": {
      "en": "Your password was successfully set.",
      "pt": "Sua senha foi configurada com sucesso.",
      "es": "Su contraseña se ha configurado con éxito.",
      "de": "Ihr Passwort wurde erfolgreich eingerichtet."
    },
    "Criar minha conta": {
      "en": "Create my account.",
      "pt": "Criar minha conta",
      "es": "Crear mi cuenta",
      "de": "Konto Erstellen"
    },
    "Primeiro Nome (Nome de Pessoa)": {
      "en": "First name (personal name) of the person",
      "pt": "Primeiro Nome (Nome de Pessoa)",
      "es": "Nombre de la persona (nombre de la persona)",
      "de": "Erster Name (Name der Person)"
    },
    "Nickname (Nome de Usuário)": {
      "en": "Nickname (user name) of the user",
      "pt": "Nickname (Nome de Usuário)",
      "es": "Apodo (nombre de usuario) del(a) usuario",
      "de": "Benennung (Name) (Name des Ben)"
    },
    "E-mail (a conta será validada)": {
      "en": "Email (account will be validated)",
      "pt": "E-mail (a conta será validada)",
      "es": "Correo electrónico (la cuenta será validada)",
      "de": "E-Mail (die Rechnung wird validiert)"
    },
    "Criar Conta": {
      "en": "Create an account",
      "pt": "Criar Conta",
      "es": "Crear una cuenta",
      "de": "Konto Erstellen"
    },
    "Campos de nome e apelido devem ter pelo menos 2 caracteres": {
      "en": "Name and surname fields must be at least 2 characters long",
      "pt": "Campos de nome e apelido devem ter pelo menos 2 caracteres",
      "es": "Los campos nombre y apellido deben tener al menos 2 caracteres",
      "de": "Name und Nachnamefelder müssen mindestens 2 Zeichen haben"
    },
    "Use um e-mail válido": {
      "en": "Use a valid email address.",
      "pt": "Use um e-mail válido",
      "es": "Utilice un correo electrónico válido",
      "de": "Verwenden Sie eine gültige E-Mail"
    },
    "Esse nickname já está em uso, tente outro": {
      "en": "That nickname is already in use, try another one.",
      "pt": "Esse nickname já está em uso, tente outro",
      "es": "Ese se usa ya, prueba otro",
      "de": "Dieser Spitzname ist bereits im Einsatz, versuchen Sie ander"
    },
    "Esse endereço de e-mail já esta em uso": {
      "en": "That e-mail address is already in use",
      "pt": "Esse endereço de e-mail já esta em uso",
      "es": "Esa dirección de correo electrónico ya está en uso",
      "de": "Diese E-Mail-Adresse ist bereits in Gebrauch"
    },
    "Sua conta foi criado com sucesso. Você receberá dentro de alguns minutos um e-mail para confirmar a conta, lembre de verificar também a caixa de spam e \"outros\". Contas não confirmadas serão deletadas em três dias.": {
      "en": "Your account has been successfully created. You will receive an email within a few minutes to confirm your account, remember to check also the spam box and \"other\". Unconfirmed accounts will be deleted in three days.",
      "pt": "Sua conta foi criado com sucesso. Você receberá dentro de alguns minutos um e-mail para confirmar a conta, lembre de verificar também a caixa de spam e \"outros\". Contas não confirmadas serão deletadas em três dias.",
      "es": "Su cuenta se ha creado con éxito. En pocos minutos recibirá un correo electrónico para confirmar la cuenta, recuerde también revisar el buzón de correo no deseado y \"Otros\". Las cuentas no confirmadas se eliminarán en tres días.",
      "de": "Ihr Konto wurde erfolgreich erstellt. Sie erhalten innerhalb weniger Minuten eine E-Mail zur Konfirmation Ihres Kontos, achten Sie auch darauf, den Spam- und \"andere\" -Feld zu überprüfen. Nicht bestätigte Konten werden in drei Tagen gelöscht"
    },
    "Há um problema com seu pedido, usuário ou token não encontrados, tente acessar pelo seu e-mail.": {
      "en": "There's a problem with your request, user or token not found, try logging in via your email.",
      "pt": "Há um problema com seu pedido, usuário ou token não encontrados, tente acessar pelo seu e-mail.",
      "es": "Hay un problema con su solicitud, usuario o token no encontrado, intente acceder por su correo electrónico.",
      "de": "Es gibt ein Problem mit Ihrer Anfrage, Benutzer oder Token nicht gefunden, versuchen Sie es mit Ihrer E-Mail."
    },
    "Validando sua conta...": {
      "en": "Validating your account....",
      "pt": "Validando sua conta...",
      "es": "Validando su cuenta...",
      "de": "Bestätige Ihren Account."
    },
    "Sua conta foi habilitada com sucesso!": {
      "en": "Your account has been successfully activated!",
      "pt": "Sua conta foi habilitada com sucesso!",
      "es": "¡Su cuenta ha sido activada con éxito!",
      "de": "Ihr Konto wurde erfolgreich aktiviert!"
    },
    "Anunciada": {
      "en": "Announced.",
      "pt": "Anunciado",
      "es": "Anunciado",
      "de": "Ankündigung"
    },
    "Em exibição": {
      "en": "Airing",
      "pt": "Em exibição",
      "es": "En exhibición",
      "de": "Ausstrahlen"
    },
    "Finalizada": {
      "en": "Finished",
      "pt": "Finalizado",
      "es": "Terminado",
      "de": "Abgeschlossen"
    },
    "Descubra Animes": {
      "en": "Discover Animes",
      "pt": "Descubra Animes",
      "es": "Descubre las Animas",
      "de": "Animes Entdecken"
    },
    "Descubra Mangas": {
      "en": "Discover Mangas",
      "pt": "Descubra Mangas",
      "es": "Descubre las Mangas",
      "de": "Mangas Entdecken"
    },
    "Descubra Pessoas": {
      "en": "Discover People",
      "pt": "Descubra Pessoas",
      "es": "Descubre a las personas",
      "de": "Personen Entdecken"
    },
    "ano": {
      "en": "year",
      "pt": "ano",
      "es": "año",
      "de": "jahr"
    },
    "Ano": {
      "en": "Year",
      "pt": "Ano",
      "es": "Año",
      "de": "Jahr"
    },
    "estacao": {
      "en": "season",
      "pt": "estacao",
      "es": "estacion",
      "de": "saison"
    },
    "tipo": {
      "en": "type",
      "pt": "tipo",
      "es": "tipo",
      "de": "typ"
    },
    "exibicao": {
      "en": "airstatus",
      "pt": "exibicao",
      "es": "exhibicion",
      "de": "ausstrahlen"
    },
    "genero": {
      "en": "genre",
      "pt": "genero",
      "es": "genero",
      "de": "genre"
    },
    "Genero": {
      "en": "Genre",
      "pt": "Genero",
      "es": "Genero",
      "de": "Genre"
    },
    "tipo_filtro": {
      "en": "filter_type",
      "pt": "tipo_filtro",
      "es": "tipode_filtro",
      "de": "typ_filter"
    },
    "Filtro: Exclusivo": {
      "en": "Filter: exclusive",
      "pt": "Filtro: Exclusivo",
      "es": "Filtro: exclusivo",
      "de": "Filter: exklusiv"
    },
    "Filtro: Inclusivo": {
      "en": "Filter: Included",
      "pt": "Filtro: Inclusivo",
      "es": "Filtro: incluido",
      "de": "Filter: Inklusive"
    },
    "nome": {
      "en": "name",
      "pt": "nome",
      "es": "nombre",
      "de": "name"
    },
    "pagina": {
      "en": "page",
      "pt": "pagina",
      "es": "página",
      "de": "seite"
    },
    "Seleciona se a filtragem vai incluir novos items a cada genero escolhido (Inclusivo), ou excluir itens que não contenham todos os genêros escolhidos (Exclusivo)": {
      "en": "Selects whether to filter new items for each selected genre (Inclusive), or delete items that do not contain all selected genres (Exclusive)",
      "pt": "Seleciona se a filtragem vai incluir novos items a cada genero escolhido (Inclusivo), ou excluir itens que não contenham todos os genêros escolhidos (Exclusivo)",
      "es": "Selecciona si el filtrado incluirá nuevos elementos para cada género elegido (Incluido), o excluirá elementos que no contengan todos los géneros elegidos (Exclusivo).",
      "de": "Wählen Sie, ob Sie Filterungen für jede gewählte Genre durchführen (Inklusive) oder um Elemente zu entfernen, die nicht alle gewählten Genres enthalten (Exklusiv)"
    },
    "Remove todos os filtros da pesquisa": {
      "en": "Remove all filters from search.",
      "pt": "Remove todos os filtros da pesquisa",
      "es": "Elimina todos los filtros de búsqueda",
      "de": "Alle Filter aus der Suche Entfernen"
    },
    "Limpar Filtros": {
      "en": "Clear Filters",
      "pt": "Limpar Filtros",
      "es": "Limpiar filtros",
      "de": "Filter reinigen"
    },
    "Avaliar": {
      "en": "Evaluate",
      "pt": "Avaliar",
      "es": "Evaluar",
      "de": "Bewertung"
    },
    "Popular de todos os tempos": {
      "en": "All time Popular",
      "pt": "Popular de todos os tempos",
      "es": "Popular de todos los tiempos",
      "de": "Die Populärste aller Zeiten"
    },
    "Próximos a lançar": {
      "en": "About to release",
      "pt": "Próximos a lançar",
      "es": "A punto de lanzar",
      "de": "Kurz vor der Veröffentlichung"
    },
    "Popular desta temporada": {
      "en": "Popular this Season",
      "pt": "Popular desta Temporada",
      "es": "Popular de esta Temporada",
      "de": "Die Beliebteste der Saison"
    },
    "Pessoas": {
      "en": "People",
      "pt": "Pessoas",
      "es": "Personas",
      "de": "Personen"
    },
    "Empresas": {
      "en": "Companies",
      "pt": "Empresas",
      "es": "Empresas",
      "de": "Unternehmens"
    },
    "Mangas": {
      "en": "Mangas",
      "pt": "Mangas",
      "es": "Mangas",
      "de": "Mangas"
    },
    "Revistas": {
      "en": "Magazines",
      "pt": "Revistas",
      "es": "Revistas",
      "de": "Zeitschriften"
    },
    "de": {
      "en": "of",
      "pt": "de",
      "es": "de",
      "de": "von"
    },
    "até": {
      "en": "upto",
      "pt": "até",
      "es": "hasta",
      "de": "bis zu"
    },
    "Busca Avançada": {
      "en": "Advanced Search",
      "pt": "Busca Avançada",
      "es": "Busca Avanzada",
      "de": "Erweiterte Suche"
    },
    "Busca Simples": {
      "en": "Simple Search",
      "pt": "Busca Simples",
      "es": "Busca sencillo",
      "de": "Einfache Suche"
    },
    "data-inicio": {
      "en": "start-date",
      "pt": "data-inicio",
      "es": "fecha-de-inicio",
      "de": "anfangsdatum"
    },
    "data-fim": {
      "en": "end-date",
      "pt": "data-fim",
      "es": "fecha-de-finalizacion",
      "de": "enddatum"
    },
    "Buscar Manga": {
      "en": "Manga Search",
      "pt": "Buscar Manga",
      "es": "Buscar Manga",
      "de": "Manga Suchen"
    },
    "Buscar Anime": {
      "en": "Anime Search",
      "pt": "Buscar Anime",
      "es": "Buscar Anime",
      "de": "Anime Suchen"
    },
    "Buscar Pessoa": {
      "en": "Person Search",
      "pt": "Buscar Pessoa",
      "es": "Buscar Persona",
      "de": "Person Suchen"
    },
    "Buscar Revistas": {
      "en": "Magazine Search",
      "pt": "Buscar Revistas",
      "es": "Buscar Revistas",
      "de": "Zeitschriften Suchen"
    },
    "Buscar Empresas": {
      "en": "Company Search",
      "pt": "Buscar Empresas",
      "es": "Buscar Empresas",
      "de": "Unternehmen Suchen"
    },
    "Buscar Personagem": {
      "en": "Character Search",
      "pt": "Buscar Personagem",
      "es": "Buscar Personaje",
      "de": "Charakter Suchen"
    },
    "serializacao": {
      "en": "serialisation",
      "pt": "serializacao",
      "es": "serializacion",
      "de": "serialisierung"
    },
    "Serialização": {
      "en": "Serialisation",
      "pt": "Serialização",
      "es": "Serialización",
      "de": "Serialisierung"
    },
    "Lançando": {
      "en": "Airing",
      "pt": "Lançando",
      "es": "Al Aire",
      "de": "Ausstrahlen"
    },
    "Mostrar Conteúdo Adulto": {
      "en": "Show adult content",
      "pt": "Mostrar Conteúdo Adulto",
      "es": "Muestra contenido para adultos.",
      "de": "Erwachsene Inhalte anzeigen"
    },
    "Descubra Personagens": {
      "en": "Discover Characters",
      "pt": "Descubra Personagens",
      "es": "Descubre a los Personajes",
      "de": "Charaktere Entdecken"
    },
    "Descubra Revistas": {
      "en": "Discover Magazines",
      "pt": "Descubra Revistas",
      "es": "Descubre las revistas",
      "de": "Zeitschriften Entdecken"
    },
    "Descubra Empresas": {
      "en": "Discover Companies",
      "pt": "Descubra Empresas",
      "es": "Descubre empresas",
      "de": "Unternehmen Entdecken"
    },
    "Remover": {
      "en": "Remove",
      "pt": "Remover",
      "es": "Eliminar",
      "de": "Entferne"
    },
    "Próxima": {
      "en": "Next",
      "pt": "Próxima",
      "es": "Siguiente",
      "de": "Nächste"
    },
    "Anterior": {
      "en": "Previous",
      "pt": "Anterior",
      "es": "Anterior",
      "de": "Vorheriger"
    },
    "Descobrir": {
      "en": "Explore",
      "pt": "Explorar",
      "es": "Descubrir",
      "de": "Entdecken"
    },
    "Olá Visitante,": {
      "en": "Hello, guest,",
      "pt": "Olá Visitante,",
      "es": "Hola visitante,",
      "de": "Hallo, Besucher,"
    },
    "Adicionar OPs à fila de reprodução": {
      "en": "Add OPs to playback queue",
      "pt": "Adicionar OPs à fila de reprodução",
      "es": "Añadir OPs a la fila de reproducción",
      "de": "OPs in die Wiedergabefolge hinzufügen"
    },
    "Adicionar EDs à fila de reprodução": {
      "en": "Add EDs to playback queue",
      "pt": "Adicionar EDs à fila de reprodução",
      "es": "Añadir EDs a la fila de reproducción",
      "de": "EDs in die Wiedergabefolge hinzufügen"
    },
    "Apagar tudo": {
      "en": "Erase all",
      "pt": "Apagar tudo",
      "es": "Borrar todo",
      "de": "Alles löschen"
    },
    "Nada": {
      "en": "Nothing",
      "pt": "Nada",
      "es": "Nada",
      "de": "Nichts"
    },
    "Tocar Lista de Musica": {
      "en": "Play music list",
      "pt": "Tocar Lista de Musica",
      "es": "Tocar Lista de música",
      "de": "Musikliste Spielen"
    },
    "Criar Lista com essas músicas": {
      "en": "Create a list with these songs",
      "pt": "Criar Lista com essas músicas",
      "es": "Crear una lista con estas canciones",
      "de": "Liste mit diesen Songs Erstellen"
    },
    "Adicionar Lista de Músicas": {
      "en": "Add to a Playlist",
      "pt": "Adicionar em Lista de Músicas",
      "es": "Añadir en una lista de canciones.",
      "de": "Zusatz zum Wiedergabeliste"
    },
    "Todos": {
      "en": "All",
      "pt": "Todos",
      "es": "Todos",
      "de": "Alle"
    },
    "Lista já Existente": {
      "en": "Already existing list",
      "pt": "Lista já Existente",
      "es": "La lista ya existe",
      "de": "Liste existiert bereits"
    },
    "Nova Lista": {
      "en": "New list",
      "pt": "Nova Lista",
      "es": "Nueva lista",
      "de": "Neue Liste"
    },
    "Selecione uma Lista": {
      "en": "Select a List",
      "pt": "Selecione uma Lista",
      "es": "Seleccione una Lista",
      "de": "Liste ayswählen"
    },
    "Top Animes em Exibição": {
      "en": "Top Airing Anime",
      "pt": "Top Animes em Exibição",
      "es": "Top Animes en Exhibición",
      "de": "Top Ausgestrahlter Anime"
    },
    "Animes mais Esperados": {
      "en": "Most Anticipated Anime",
      "pt": "Animes mais Esperados",
      "es": "Animes más Esperados",
      "de": "Mehr erwartete Anime"
    },
    "Animes mais Populares esse Mês": {
      "en": "Most Popular Anime This Month",
      "pt": "Animes mais Populares esse Mês",
      "es": "Animes más Populares de este Mes",
      "de": "Die beliebtesten Anime dieses Monats"
    },
    "Animes da Temporada": {
      "en": "This Season Anime",
      "pt": "Animes da Temporada",
      "es": "Animes de la temporada",
      "de": "Die Animes der Saison"
    },
    "Filmes e Especiais da Temporada": {
      "en": "This Season Movies and Specials",
      "pt": "Filmes e Especiais da Temporada",
      "es": "Películas y especiales de la temporada.",
      "de": "Filme und Spezialfilme der Saison"
    },
    "Light Novel": {
      "en": "Light Novel",
      "pt": "Romance Leve",
      "es": "Romance ligero",
      "de": "Light Novel"
    },
    "Music": {
      "en": "Music",
      "pt": "Música",
      "es": "Música",
      "de": "Musik"
    },
    "Unknown": {
      "en": "Unknown",
      "pt": "Desconocido",
      "es": "Desconhecido",
      "de": "Unbekannt"
    },
    "Home": {
      "en": "Index",
      "pt": "Início",
      "es": "Inicio",
      "de": "Startseite"
    },
    "Consistencia": {
      "en": "Consistency",
      "pt": "Consistencia",
      "es": "Consistencia",
      "de": "Konsistenz"
    },
    "Top Anime": {
      "en": "Top Anime",
      "pt": "Top Anime",
      "es": "Top Anime",
      "de": "Top Anime"
    },
    "Top Filmes": {
      "en": "Top Films",
      "pt": "Top Filmes",
      "es": "Top Películas",
      "de": "Top Filme"
    },
    "Top Especiais": {
      "en": "Tops Specials",
      "pt": "Top Especiais",
      "es": "Tops Especiales",
      "de": "Top Spezial"
    },
    "Top OVAs": {
      "en": "Top OVAs",
      "pt": "Top OVAs",
      "es": "Top OVAs",
      "de": "Top OVAs"
    },
    "Top Mangas": {
      "en": "Top Mangas",
      "pt": "Top Mangas",
      "es": "Top Mangas",
      "de": "Top Mangas"
    },
    "Top Romances": {
      "en": "Top Romance",
      "pt": "Top Romances",
      "es": "Top Romance",
      "de": "Top Romane"
    },
    "Personagens Mais Amados": {
      "en": "Most Liked Characters",
      "pt": "Personagens Mais Amados",
      "es": "Personajes más queridos",
      "de": "Die beliebtesten Charaktere"
    },
    "Últimas Trilhas Sonoras": {
      "en": "Latest sound tracks",
      "pt": "Últimas Trilhas Sonoras",
      "es": "Últimas pistas de sonido",
      "de": "Letzte Soundtracks"
    },
    "Termos de Uso": {
      "en": "Terms of use",
      "pt": "Termos de Uso",
      "es": "Condiciones de uso",
      "de": "Nutzungsbedingungen"
    },
    "Politica de Privacidade": {
      "en": "Privacy policy",
      "pt": "Politica de Privacidade",
      "es": "Política de privacidad",
      "de": "Datenschutzrichtlinie"
    },
    "Politica de Cookies": {
      "en": "Cookie Policy",
      "pt": "Politica de Cookies",
      "es": "Política de cookies",
      "de": "Cookies-Richtlinie"
    },
    "Declaro que Li e Concordo com:": {
      "en": "I declare that I have read and agree to:",
      "pt": "Declaro que Li e Concordo com:",
      "es": "Declaro que he leído y estoy de acuerdo con",
      "de": "Ich erkläre, dass ich gelesen habe und stimme zu:"
    },
    "Você deve concordar com nossos termos para usar o site": {
      "en": "You must agree to our terms to use the website",
      "pt": "Você deve concordar com nossos termos para usar o site",
      "es": "Usted debe aceptar nuestros términos para usar el sitio web",
      "de": "Sie müssen unseren Bedingungen zustimmen, um die Website zu nutzen"
    },
    "Mangas Populares Essa Semana": {
      "en": "Popular Manga This Week",
      "pt": "Mangas Populares Essa Semana",
      "es": "Mangas populares esta semana",
      "de": "Die beliebtesten Manga dieser Woche"
    },
    "Romances Populares Essa Semana": {
      "en": "Popular Novels This Week",
      "pt": "Romances Populares Essa Semana",
      "es": "Romances populares de esta semana",
      "de": "Populäre Romanzen dieser Woche"
    },
    "Romances Leves Populares Essa Semana": {
      "en": "Popular light novel this week",
      "pt": "Romances Leves Populares Essa Semana",
      "es": "Romance ligero popular esta semana",
      "de": "Die beliebtesten light novel dieser Woche"
    },
    "Carregando": {
      "en": "Loading",
      "pt": "Carregando",
      "es": "Cargando",
      "de": "Wird geladen..."
    },
    "Todos Direitos Reservados": {
      "en": "All rights reserved",
      "pt": "Todos Direitos Reservados",
      "es": "Todos los derechos reservados",
      "de": "Alle Rechte vorbehalten"
    },
    "Design e Engenharia por": {
      "en": "Design and Engineering by: -",
      "pt": "Design e Engenharia por",
      "es": "Diseño e Ingeniería por",
      "de": "Design und Engineering durch: -"
    },
    "Ir Diretamente": {
      "en": "Go Directly",
      "pt": "Ir Diretamente",
      "es": "Ve directo",
      "de": "Geh sofort"
    },
    "Ir para o topo": {
      "en": "Go to Top",
      "pt": "Ir para o topo",
      "es": "Ve arriba",
      "de": "Zur Spitze Gehen"
    },
    "Resumo ainda não foi escrito.":
    {
      "en": "Summary has not been written.",
      "pt": "-",
      "es": "El resumen no ha sido escrito.",
      "de": "Zusammenfassung wurde nicht geschrieben."
    },
    "Anime Stats":
    {
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "-"
    },
    "Notas - Anime":
    {
      "en": "Grades - Anime",
      "pt": "-",
      "es": "-",
      "de": "Noten - Anime"
    },
    "Leitura Stats":
    {
      "en": "Read Stats",
      "pt": "-",
      "es": "Lectura Stats",
      "de": "Lesen Stats"
    },
    "Notas - Leitura":
    {
      "en": "Grades - Reading",
      "pt": "-",
      "es": "Notas - Lectura",
      "de": "Noten - Lesen"
    },
    "Quer":
    {
      "en": "Want",
      "pt": "-",
      "es": "Quiere",
      "de": "Möchte"
    },
    "Assiste":
    {
      "en": "Watches",
      "pt": "Assistindo",
      "es": "Mirando",
      "de": "Ansehen"
    },
    "Lê":
    {
      "en": "Reads",
      "pt": "-",
      "es": "Leyendo",
      "de": "Lesen"
    },
    "Concluiu":
    {
      "en": "Finished",
      "pt": "-",
      "es": "Concluido",
      "de": "Abgeschlossen"
    },
    "Largou":
    {
      "en": "Dropped",
      "pt": "-",
      "es": "Abandonó",
      "de": "Fallen"
    },
    "Tocar Trailer":
    {
      "en": "Play Trailer",
      "pt": "-",
      "es": "Reproducir Tráiler",
      "de": "Trailer Abspielen"
    },
    "Deletar Lista":{
      "en": "Delete List",
      "pt": "-",
      "es": "Eliminar lista",
      "de": "Liste Löschen"
    },
    "Editar":{
      "en": "Edit",
      "pt": "-",
      "es": "-",
      "de": "Bearbeiten"
    },
    "O login falhou! Podemos estar passando por algum tipo de problema, tente novamente!":{
      "en": "Login failed! We may be experiencing some kind of problem, please try again!",
      "pt": "-",
      "es": "¡Error de inicio de sesion! Es posible que estemos experimentando algún tipo de problema, ¡inténtalo de nuevo!",
      "de": "Fehler bei der Anmeldung! Möglicherweise liegt ein Problem vor. Bitte versuchen Sie es erneut!"
    },
    "Post":{
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "-"
    },
    "Pool":{
      "en": "Poll",
      "pt": "Enquete",
      "es": "Encuesta",
      "de": "Umfrage"
    },
    "Article":{
      "en": "Article",
      "pt": "Artigo",
      "es": "Artículo",
      "de": "Artikel"
    },
    "ReactionPost":{
      "en": "Impression",
      "pt": "Impressão",
      "es": "Impresión",
      "de": "Eindruck"
    },
    "Anime_grade":{
      "en": "Anime Grade",
      "pt": "Nota de Anime",
      "es": "Nota de Anime",
      "de": "Anime-Notiz"
    },
    "configuracoes":{
      "en": "settings",
      "pt": "conta",
      "es": "configuraciones",
      "de": "einstellungen"
    },
    "Configurações/Preferências":{
      "en": "Settings",
      "pt": "Conta",
      "es": "Preferencias",
      "de": "Präferenzen"
    },
    "Salvar": {
      "en": "Save",
      "pt": "-",
      "es": "Guardar",
      "de": "Speichern"
    },
    "Usar Gravatar":{
      "en": "Use Gravatar",
      "pt": "-",
      "es": "-",
      "de": "Benutze Gravatar"
    },
    "https://gravatar.com/":{
      "en": "https://en.gravatar.com/",
      "pt": "https://br.gravatar.com/",
      "es": "https://es.gravatar.com/",
      "de": "https://de.gravatar.com/"
    },
    "é uma solução global de avatares adotada por muitos sites":{
      "en": "is a global avatar solution adopted by many websites",
      "pt": "-",
      "es": "es una solución de avatar global adoptada por muchos sitios web",
      "de": "ist eine globale Avatar-Lösung, die von vielen Websites übernommen wird"
    },
    "Deletar Imagem":{
      "en": "Delete Image",
      "pt": "-",
      "es": "Eliminar Imagen",
      "de": "Lösche Bild"
    },
    "Deletar Post":{
      "en": "Delete Post",
      "pt": "-",
      "es": "Eliminar Post",
      "de": "Lösche Beitrag"
    },
    "Avatar":{
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "Benutzerbild"
    },
    "Carregar do Dispositivo":{
      "en": "Upload from Device...",
      "pt": "Carregar do Dispositivo...",
      "es": "Cargar desde dispositivo...",
      "de": "Vom Gerät hochladen..."
    },
    "Banner":{
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "-"
    },
    "Biografia":{
      "en": "Biography",
      "pt": "-",
      "es": "Biografía",
      "de": "Biografie"
    },
    "Escreva sua folha de rosto!":{
      "en": "Write your cover page!",
      "pt": "-",
      "es": "¡Escribe tu portada!",
      "de": "Schreiben Sie Ihr Deckblatt"
    },
    "Conta Verificada com Sucesso!":{
      "en": "Account verified successfully!",
      "pt": "-",
      "es": "¡Cuenta verificada con éxito!",
      "de": "Konto erfolgreich bestätigt!"
    },
    "Usuário Desconectado...":{
      "en": "User disconnected...",
      "pt": "-",
      "es": "Usuario desconectado...",
      "de": "Benutzerverbindung getrennt..."
    },
    "Senha de backup registrada com sucesso!":{
      "en": "Backup Password successfully registered!",
      "pt": "-",
      "es": "Contraseña de respaldo registrada con éxito!",
      "de": "Backup-Passwort erfolgreich registriert!"
    },
    "Usuário criado com sucesso! Siga as instruções para confirmar sua conta!":{
      "en": "User created successfully, follow the instructions to verify your account!",
      "pt": "-",
      "es": "Usuario creado con éxito, sigue las instrucciones para verificar tu cuenta!",
      "de": "Benutzer erfolgreich erstellt, folgen Sie den Anweisungen, um Ihr Konto zu verifizieren!"
    },
    "Um e-mail foi enviado para você, com instruções de como trocar sua senha.":{
      "en": "An email has been sent to you with instructions on how to change your password.",
      "pt": "-",
      "es": "Se le ha enviado un correo electrónico con instrucciones sobre cómo cambiar su contraseña.",
      "de": "Ihnen wurde eine E-Mail mit Anweisungen zum Ändern Ihres Passworts zugesandt."
    },
    "Logado com sucesso!":{
      "en": "Successfully logged in!",
      "pt": "-",
      "es": "¡Iniciado sesión correctamente!",
      "de": "Erfolgreich eingeloggt!"
    },
    "Que vergonha! Parece que algo deu errado no nosso servidor, o desenvolvedor foi alertado, tente novamente mais tarde!":{
      "en": "How embarrassing! Looks like something went wrong on our server, the developer has been alerted, please try again later!",
      "pt": "-",
      "es": "¡Que vergüenza! Parece que algo salió mal en nuestro servidor, el desarrollador ha sido alertado, ¡inténtalo de nuevo más tarde!",
      "de": "Wie peinlich! Offenbar ist auf unserem Server ein Fehler aufgetreten. Der Entwickler wurde benachrichtigt. Bitte versuchen Sie es später noch einmal!"
    },
    "Nota salva!":{
      "en": "Grade Saved!",
      "pt": "-",
      "es": "¡Nota guardada!",
      "de": "Note gespeichert!"
    },
    "Imagem Gravada com Sucesso!":{
      "en": "Image Successfully Uploaded!",
      "pt": "-",
      "es": "¡Imagen cargada con éxito!",
      "de": "Bild erfolgreich hochgeladen!"
    },
    "Tema":{
      "en": "Theme",
      "pt": "-",
      "es": "-",
      "de": "Thema"
    },
    "Claro":{
      "en": "Light",
      "pt": "-",
      "es": "Ligero",
      "de": "Licht"
    },
    "Escuro":{
      "en": "Dark",
      "pt": "-",
      "es": "Oscuro",
      "de": "Thema"
    },
    "Buscar...":{
      "en": "Search...",
      "pt": "-",
      "es": "-",
      "de": "Suchen..."
    },
    "Nenhum resultado encontrado para ":{
      "en": "No results found for",
      "pt": "-",
      "es": "No se encontraron resultados para",
      "de": "Keine Ergebnisse gefunden für"
    },
    "Bem vindo ao Nosso Anime Network! A plataforma de anime e mangá Brasileira mais moderna e mais atualizada, um database e comunidade. Faça parte da comunidade, Crie suas listas de animes ,mangás, empresas, personagens, pessoas, listas padrões e customizadas também! Leia resenhas, explore as comunidades, notícias e muito mais!":{
      "en": "Welcome to Our Anime Network! The most modern and updated anime and manga platform, a database and community. Be part of the community, Create your lists of anime, manga, companies, characters, people, standard and customized lists too! Read reviews, explore communities, news and more!",
      "pt": "-",
      "es": "¡Bienvenido a nuestra red de anime! La plataforma de anime y manga en español más moderna y actualizada, una base de datos y una comunidad. ¡Sé parte de la comunidad, crea tus listas de anime, manga, empresas, personajes, personas, listas estándar y personalizadas también! ¡Lea reseñas, explore comunidades, noticias y más!",
      "de": "Willkommen in unserem Anime-Netzwerk! Die modernste und aktuellste deutsche Anime- und Manga-Plattform, Datenbank und Community. Werden Sie Teil der Community. Erstellen Sie auch Ihre Listen mit Anime, Manga, Unternehmen, Charakteren, Personen sowie Standard- und benutzerdefinierten Listen! Lesen Sie Rezensionen, erkunden Sie Communities, Neuigkeiten und mehr!"
    },
    "Home":{
      "en": "-",
      "pt": "Início",
      "es": "Inicio",
      "de": "Startseite"
    },
    "Japão":{
      "en": "Japan",
      "pt": "-",
      "es": "Japón",
      "de": "Japan"
    },
    "NANI logo":{
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "-"
    },
    "Compartilhar não disponível no seu browser":{
      "en": "Share not available in your browser",
      "pt": "-",
      "es": "Compartir no disponible en su navegador",
      "de": "Teilen ist in Ihrem Browser nicht verfügbar"
    },
    "Você precisa escolher um nome para sua lista!":{
      "en": "You must pick a name for your list!",
      "pt": "-",
      "es": "¡Debes elegir un nombre para tu lista!",
      "de": "Sie müssen einen Namen für Ihre Liste auswählen!"
    },
    "Você precisa escolher uma lista!":{
      "en": "You must pick a list!",
      "pt": "-",
      "es": "¡Debes elegir una lista!",
      "de": "Sie müssen eine Liste auswählen!"
    },
    "_tem":{
      "en": "in",
      "pt": "tem",
      "es": "tiene",
      "de": "hat"
    },
    "Qual é a":{
      "en": "What is the",
      "pt": "-",
      "es": "Cual es la",
      "de": "Was ist die"
    },
    "Quais são as":{
      "en": "What are the",
      "pt": "-",
      "es": "Cuáles son las",
      "de": "Wo sind die"
    },
    "Qual é o":{
      "en": "What is the",
      "pt": "-",
      "es": "Cual es lo",
      "de": "Was ist die"
    },
    "Quais são os":{
      "en": "What are the",
      "pt": "-",
      "es": "Cuáles son los",
      "de": "Wo sind die"
    },
    "Quantos":{
      "en": "How many",
      "pt": "-",
      "es": "Cuantos",
      "de": "Wie viele"
    },
    "dia de exibição":{
      "en": "broadcast day",
      "pt": "-",
      "es": "día de exposición",
      "de": "Ausstellungstag"
    },
    "Qual a data de nascimento":{
      "en": "What is the birthdate",
      "pt": "-",
      "es": "¿Cuál es la fecha de nacimiento",
      "de": "Was ist Geburtsdatum"
    },
    "Data do Fim da Exibição":{
      "en": "Finale date",
      "pt": "-",
      "es": "Fecha de finalización de la exposición",
      "de": "Enddatum der Ausstellung"
    },
    "Sincronizar Conta":{
      "en": "Account Sync",
      "pt": "-",
      "es": "Sincronizar cuenta",
      "de": "Konto synchronisieren"
    },
    "Tarefas Assíncronas":{
      "en": "Asynchronous Tasks",
      "pt": "-",
      "es": "Tareas asincrónicas",
      "de": "Asynchrone Aufgaben"
    },
    "Requisitar Syncronização com":{
      "en": "Request Sync with",
      "pt": "-",
      "es": "Solicitar sincronización con",
      "de": "Synchronisierung anfordern mit"
    },
    "Nome de Usuário no MyAnimeList":{
      "en": "MyAnimeList Username",
      "pt": "-",
      "es": "Nombre de usuario de MyAnimeList",
      "de": "MyAnimeList-Benutzername"
    },
    "Nome de Usuário no MyAnimeList":{
      "en": "MyAnimeList Username",
      "pt": "-",
      "es": "Nombre de usuario de MyAnimeList",
      "de": "MyAnimeList-Benutzername"
    },
    "Insira seu nome de usuário do MyAnimeList Abaixo, ao clicar em Confirmar uma tarefa assincrona será criada, pode levar algumas horas para sincronização completar.":{
      "en": "Enter your MyAnimeList username Below, when you click Confirm an asynchronous task will be created, it may take a few hours for sync to complete.",
      "pt": "-",
      "es": "Ingrese su nombre de usuario MyAnimeList A continuación, cuando haga clic en Confirmar, se creará una tarea asincrónica, la sincronización puede tardar algunas horas en completarse.",
      "de": "Geben Sie unten Ihren MyAnimeList-Benutzernamen ein. Wenn Sie auf „Bestätigen“ klicken, wird eine asynchrone Aufgabe erstellt. Es kann einige Stunden dauern, bis die Synchronisierung abgeschlossen ist."
    },
    "Você pode encontrar seu nome de usuário na URL da página da sua lista de anime, exemplo:":{
      "en": "You can find your username in the URL of your anime list page, example:",
      "pt": "-",
      "es": "Puedes encontrar tu nombre de usuario en la URL de tu página de lista de anime, ejemplo:",
      "de": "Sie finden Ihren Benutzernamen in der URL Ihrer Anime-Listenseite, Beispiel:"
    },
    "Digite um e-mail e senha para entrar!":{
      "en": "Enter an email and password to log in!",
      "pt": "-",
      "es": "¡Ingrese un correo electrónico y una contraseña para iniciar sesión!",
      "de": "Geben Sie einfach eine E-Mail-Adresse und ein Passwort ein, um sich anzumelden!"
    },
    "_code_mal sync":{
      "en": "Sync with MyAnimeList",
      "pt": "Sincronização com MyAnimeList",
      "es": "Sincronizar con MyAnimeList",
      "de": "Mit MyAnimeList synchronisieren"
    },
    "_code_queue":{
      "en": "In Queue",
      "pt": "Na fila de Execução",
      "es": "En la cola de ejecución",
      "de": "In der Ausführungswarteschlange"
    },
    "Status":{
      "en": "Status",
      "pt": "Estado",
      "es": "Estado",
      "de": "Status"
    },
    "Data de Requisição":{
      "en": "Request Date",
      "pt": "-",
      "es": "Fecha de solicitud",
      "de": "Anforderungsdatum"
    },
    "Tarefa":{
      "en": "Task",
      "pt": "-",
      "es": "Tarea",
      "de": "Aufgabe"
    },
    "Você pode apenas requisitar uma task similar por vez. Espere a execução da ultima tarefa completar antes requisitar outra.":{
      "en": "You can only request one similar task at a time. Wait for the last task to complete before requesting another.",
      "pt": "-",
      "es": "Sólo puedes solicitar una tarea similar a la vez. Espere a que se complete la última tarea antes de solicitar otra.",
      "de": "Sie können jeweils nur eine ähnliche Aufgabe anfordern. Warten Sie, bis die letzte Aufgabe abgeschlossen ist, bevor Sie eine weitere anfordern."
    },
    "Você precisa escrever seu nome de usuário para requisitar a sincronização":{
      "en": "You need to enter your username to request synchronization",
      "pt": "-",
      "es": "Debes ingresar tu nombre de usuario para solicitar la sincronización.",
      "de": "Sie müssen Ihren Benutzernamen eingeben, um die Synchronisierung anzufordern"
    },
    "Moderador":{
      "en": "Moderator",
      "pt": "-",
      "es": "Moderador",
      "de": "Moderator"
    },
    "Task Requerida":{
      "en": "Task Requested",
      "pt": "-",
      "es": "Tarea solicitada",
      "de": "Aufgabe angefordert"
    },
    "Resenha Enviada com Sucesso!":{
      "en": "Review Successfully Submitted!",
      "pt": "-",
      "es": "¡Revisión enviada exitosamente!",
      "de": "Bewertung erfolgreich eingereicht!"
    }
    ,
    "Impressão Enviada com Sucesso!":{
      "en": "Impression Successfully Submitted!",
      "pt": "-",
      "es": "¡Impresión enviada exitosamente!",
      "de": "Impression erfolgreich übermittelt!"
    },
    "Salvando...":{
      "en": "Saving...",
      "pt": "-",
      "es": "Guardando...",
      "de": "Sparen..."
    },
    "Sucesso!":{
      "en": "Success!",
      "pt": "-",
      "es": "¡Éxito!",
      "de": "Erfolg!"
    },
    "Presentes para seus amados":{
      "en": "See Gifts for your loved ones",
      "pt": "Veja Presentes para seus amados",
      "es": "Ver Regalos para tus seres queridos",
      "de": "Siehe Geschenke für Ihre Lieben"
    },
    "apoie-nos":{
      "en": "support-us",
      "pt": "-",
      "es": "apoyanos",
      "de": "unterstütze-uns"
    },
    "Conheça maneiras de apoiar a plataforma!":{
      "en": "Discover ways to support the platform!",
      "pt": "-",
      "es": "¡Descubra formas de apoyar la plataforma!",
      "de": "Entdecken Sie Möglichkeiten, die Plattform zu unterstützen!"
    },
    "Porque precisamos da sua ajuda?":{
      "en": "Why do we need your help?",
      "pt": "-",
      "es": "Por qué necesitamos su ayuda?",
      "de": "Warum brauchen wir Ihre Hilfe?"
    },
    "O que eu ganho doando, apoiando?":{
      "en": "What do I get by donating, supporting?",
      "pt": "-",
      "es": "¿Qué gano donando, apoyando?",
      "de": "Was habe ich davon, wenn ich spende, unterstütze?"
    },
    "Apoio mensal ou doação única":{
      "en": "Monthly support or one-time donation",
      "pt": "-",
      "es": "Apoyo mensual o donación única",
      "de": "Monatliche Unterstützung oder einmalige Spende"
    },
    "Atenção, doações e apoios são processadas manualmente e podem levar até 48 horas para serem ativadas":{
      "en": "Attention, donations and supports are processed manually and can take up to 48 hours to be activated",
      "pt": "-",
      "es": "Atención, las donaciones y los apoyos se procesan manualmente y pueden tardar hasta 48 horas en activarse.",
      "de": "Achtung, Spenden und Unterstützungen werden manuell bearbeitet und es kann bis zu 48 Stunden dauern, bis sie aktiviert werden"
    },
    "Caso sua conta de apoiador não seja ativada, mande um e-mail para: ":{
      "en": "If your supporter account is not activated, send an email to:",
      "pt": "-",
      "es": "Si su cuenta de colaborador no está activada, envíe un correo electrónico a:",
      "de": "Wenn Ihr Unterstützerkonto nicht aktiviert ist, senden Sie eine E-Mail an:"
    },
    "_whydonatetext":{
      "en": "OurAnime is free to use, but supported by ads and affiliate links; however, ads are not enough to support the site and we don't want to fill our pages with countless ads that can ruin your experience. Your donations will allow us to cover our server payments, development costs and keep the sites running long into the future. :)",
      "pt": "NossoAnime é de uso gratuito, mas suportado por anúncios e links afiliados; no entanto, os anúncios não são suficientes para sustentar o site e não queremos encher nossas páginas com inúmeros anúncios que podem arruinar sua experiência. Suas doações nos permitirão cobrir os pagamentos de nossos servidores, custos de desenvolvimento e manter os sites funcionando por muito tempo no futuro. :)",
      "es": "NuestoAnime es de uso gratuito, pero está respaldado por anuncios y enlaces de afiliados; sin embargo, los anuncios no son suficientes para respaldar el sitio y no queremos llenar nuestras páginas con innumerables anuncios que puedan arruinar su experiencia. Sus donaciones nos permitirán cubrir los pagos de nuestro servidor, los costos de desarrollo y mantener los sitios funcionando por mucho tiempo en el futuro. :)",
      "de": "Die Nutzung von UnserAnime ist kostenlos, wird jedoch durch Werbung und Affiliate-Links unterstützt; Allerdings reichen Anzeigen nicht aus, um die Website zu unterstützen, und wir möchten unsere Seiten nicht mit unzähligen Anzeigen füllen, die Ihr Erlebnis ruinieren können. Mit Ihren Spenden können wir unsere Serverzahlungen und Entwicklungskosten decken und die Websites auch in Zukunft am Laufen halten. :)"
    },
    "_whatigettext":{
      "en": "Donators receive access to exclusive features and bonuses, such as badges, animated avatars and lots more! Check out each donation tier for their specific perks.",
      "pt": "Os doadores recebem acesso a recursos e bônus exclusivos, como emblemas, avatares animados e muito mais! Confira cada nível de doação para ver suas vantagens específicas.",
      "es": "¡Los donadores reciben acceso a funciones y bonificaciones exclusivas, como insignias, avatares animados y mucho más! Consulte cada nivel de donación para conocer sus ventajas específicas.",
      "de": "Spendenwerter erhalten Zugriff auf exklusive Funktionen und Boni, wie z. B. Badges, animierte Avatare und vielem mehr! Überprüfe jeden Spenderniveau, um seiner spezifischen Verpflichtungen."
    },
    "Links Patrocinados":{
      "en": "Sponsored links",
      "pt": "-",
      "es": "Enlaces patrocinados",
      "de": "Gesponserte Links"
    },
    "_sponsoredlinkstext":{
      "en": "Sponsored links are product links that, when purchased, provide us with a commission worth between 1% and 6% of the purchase. When you make a purchase of any of the products you find on our pages, you help the platform to continue existing and evolving!",
      "pt": "Links patrocinados são links de produtos que ao serem adquiridos nos propocionam uma comissão com um valor entre 1% e 6% da compra. Ao efetuar uma compra de algum dos produtos que você encontra em nossas páginas você ajuda a plataforma a continuar existindo e evoluindo!",
      "es": "Los enlaces patrocinados son enlaces de productos que, al comprarlos, nos aportan una comisión por valor de entre el 1% y el 6% de la compra. Cuando realizas una compra de cualquiera de los productos que encuentras en nuestras páginas, ¡ayudas a que la plataforma siga existiendo y evolucionando!",
      "de": "Gesponserte Links sind Produktlinks, die uns beim Kauf eine Provision in Höhe von 1 % bis 6 % des Kaufpreises einbringen. Wenn Sie eines der Produkte kaufen, die Sie auf unseren Seiten finden, helfen Sie der Plattform, weiter zu bestehen und sich weiterzuentwickeln!"
    },
    "Apoie o NossoAnime!":{
      "en": "Support OurAnime!",
      "pt": "-",
      "es": "Apoya NuestroAnime!",
      "de": "Unterstützen Sie UnserAnime!"
    },
    "Meta Financeira":{
      "en": "Financial Goal",
      "pt": "-",
      "es": "-",
      "de": "Finanzielles Ziel"
    },
    "Atingida":{
      "en": "Achieved",
      "pt": "-",
      "es": "Alcanzada",
      "de": "Erreicht"
    },
    "Usuários Apoiadores":{
      "en": "Patrons",
      "pt": "-",
      "es": "Partidarios",
      "de": "Unterstützer"
    },
    "Arrecadação":{
      "en": "Raised",
      "pt": "-",
      "es": "Arrecicado",
      "de": "Gesammelt"
    },
    "Meta Atual":{
      "en": "Current Goal",
      "pt": "-",
      "es": "Objetivo actual",
      "de": "Aktuelles Ziel"
    },
    "Mês":{
      "en": "Month",
      "pt": "-",
      "es": "Mes",
      "de": "Monat"
    },
    "Semana":{
      "en": "Week",
      "pt": "-",
      "es": "Semana",
      "de": "Woche"
    },
    "Hoje":{
      "en": "Today",
      "pt": "-",
      "es": "Hoy",
      "de": "Heute"
    },
    "Esperado":{
      "en": "Anticipated",
      "pt": "-",
      "es": "Esperado",
      "de": "Erwartet"
    },
    "Apoie":{
      "en": "Become a Supporter!",
      "pt": "Seja um Apoiador!",
      "es": "¡Seja un Apoyador!",
      "de": "Werden Sie Unterstützer!"
    },
    "SIM":{
      "en": "YES",
      "pt": "-",
      "es": "SI",
      "de": "JA"
    },
    "NÃO":{
      "en": "NO",
      "pt": "-",
      "es": "NO",
      "de": "NEIN"
    },
    "Saiba Mais":{
      "en": "Find Out More...",
      "pt": "-",
      "es": "Sepa mas...",
      "de": "Mehr wissen..."
    },
    "#patrocionado":{
      "en": "#sponsored",
      "pt": "-",
      "es": "#patrocinado",
      "de": "#gesponsert"
    },
    "Desde":{
      "en": "Since",
      "pt": "-",
      "es": "Desde",
      "de": "Seit"
    },
    "Abençoados Apoiadores":{
      "en": "Beloved Patrons",
      "pt": "-",
      "es": "Amados Patrocinadores",
      "de": "Geliebte Gönner"
    },
    "404 Land":{
      "en": "-",
      "pt": "Terra do 404",
      "es": "Tierra de 404",
      "de": "-"
    },
    "If you are here, it probably means you are lost!":{
      "en": "-",
      "pt": "Se você está aqui, provavelmente significa que está perdido!",
      "es": "¡Si estás aquí, probablemente significa que estás perdido!",
      "de": "Wenn Sie hier sind, bedeutet das wahrscheinlich, dass Sie verloren sind!"
    },
    "If you are not lost, then it means a Panda ate this Bamboo Page!":{
      "en": "-",
      "pt": "Se você não está perdido, significa que um Panda comeu esta página de bambu!",
      "es": "Si no estás perdido, ¡significa que un panda se comió esta página de bambú!",
      "de": "Wenn Sie sich nicht verlaufen haben, bedeutet das, dass ein Panda diese Bambusseite gefressen hat!"
    },
    "Either way, this is not the page you were looking for.":{
      "en": "-",
      "pt": "De qualquer forma, esta não é a página que você estava procurando.",
      "es": "De cualquier manera, esta no es la página que estabas buscando.",
      "de": "In jedem Fall ist dies nicht die Seite, nach der Sie gesucht haben."
    },
    "Go Home":{
      "en": "-",
      "pt": "Ir para o Inicio",
      "es": "Ir al Inicio",
      "de": "Zur Startseite"
    },
    "Or Find Something Else:":{
      "en": "-",
      "pt": "Os busque alguma coisa:",
      "es": "O busca algo más:",
      "de": "Oder finden Sie etwas anderes:"
    },
    "Pandas are Eating Bamboo Made Pages":{
      "en": "-",
      "pt": "Pandas estão comendo páginas de bamboo",
      "es": "Los pandas comen páginas hechas con bambú",
      "de": "Pandas fressen aus Bambus gefertigte Seiten"
    },
    "trailer":{
    "en": "Trailer",
    "pt": "Trailer",
    "es": "Trailer",
    "de": "Trailer"
    },
    "cover image":{
    "en": "Cover Image",
    "pt": "Capa",
    "es": "Imagen de Cubierta",
    "de": "Deckblattkunst"
    },
    "banner": {
      "en": "Banner",
      "pt": "Banner",
      "es": "El Banner",
      "de": "Banner"
    },
    "Link inválido":{
      "en": "Invalid Link",
      "pt": "Link inválido",
      "es": "Enlace inválido",
      "de": "Ungültiger Link"
    },
    "Erro ao deletar imagem!":{
      "en": "Error deleting image!",
      "pt": "-",
      "es": "Error al eliminar la imagen!",
      "de": "Fehler beim Löschen des Bildes!"
    },
    "Tudo":{
      "en": "All",
      "pt": "-",
      "es": "Todos",
      "de": "Alles"
    },
    "Trailers Recentes":{
      "en": "Recent Trailers",
      "pt": "-",
      "es": "Trailers Recientes",
      "de": "Neueste Trailers"
    },
    "Top Animes":{
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "-"
    },
    "Top Mangas":{
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "-"
    },
    "PV":{
      "en": "Promotional video",
      "pt": "Vídeo promocional",
      "es": "Vídeo promocional",
      "de": "Promotionelle Video"
    },
    "CM":{
      "en": "Commercial video",
      "pt": "Vídeo comercial",
      "es": "Vídeo comercial",
      "de": "Kommerzielle Video"
    },
    "TV Special":{
      "en": "-",
      "pt": "-",
      "es": "-",
      "de": "-"
    },
    "Mangas/Novels":{
      "en": "-",
      "pt": "Mangas/Romances",
      "es": "Mangas/Romances",
      "de": "Mangas/Romantik"
    },
    "Criterio":{
      "en":"Criteria",
      "pt":"Critério",
      "es":"Criterio",
      "de":"Kriterium"
    },
    "criterio":{
      "en":"criteria",
      "pt":"criterio",
      "es":"criterio",
      "de":"kriterium"
    },
    "Popularidade":{
      "en":"Popularity",
      "pt":"Popularidade",
      "es":"Popularidad",
      "de":"Popularität"
    },
    "Periodo":{
      "en":"Period",
      "pt":"Período",
      "es":"Periodo",
      "de":"Periode"
    },
    "periodo":{
      "en":"period",
      "pt":"periodo",
      "es":"periodo",
      "de":"periode"
    },
    "Filtro":{
      "en":"Filter",
      "pt":"Filtro",
      "es":"Filtro",
      "de":"Filtern"
    },
    "music":{
      "en":"music",
      "pt":"musica",
      "es":"musica",
      "de":"musik"
    },
    "character":{
      "en":"character",
      "pt":"personagem",
      "es":"personaje",
      "de":"charakter"
    },
    "Atividade":{
      "en":"Activity",
      "pt":"Atividade",
      "es":"Actividad",
      "de":"Aktivität"
    },
    "Scroll to load more (you may have reached end of content)":{
      "pt":"Scroll para carregar mais (você pode ter chegado ao final do conteúdo)",
      "es":"Desplazar para cargar más (puedes haber alcanzado el final del contenido)",
      "de":"Scrolle zum Laden mehr (du hast das Ende des Inhalts erreicht)",
      "en":"Scroll to load more (you may have reached end of content)"
    },
    "Atualizar Video":{
      "en":"Update Video",
      "pt":"Atualizar Vídeo",
      "es":"Actualizar Vídeo",
      "de":"Video aktualisieren"
    },
    "Buscar Usuários":{
      "en":"User Search",
      "pt":"Buscar Usuários",
      "es":"Buscar Usuários",
      "de":"Benutzer Suchen"
    },
    "Descubra Usuários":{
      "en":"User Discovery",
      "pt":"Descubra Usuários",
      "es":"Descubra Usuários",
      "de":"Benutzer Erkunden"
    },
    "Buscar Música":{
      "en":"Music Search",
      "pt":"Buscar Música",
      "es":"Buscar Música",
      "de":"Musik Suchen"
    },
    "Descubra Músicas":{
      "en":"Music Discovery",
      "pt":"Descubra Músicas",
      "es":"Descubra Músicas",
      "de":"Musik Erkunden"
    },
    "Músicas":{
      "en":"Musics",
      "pt":"Músicas",
      "es":"Músicas",
      "de":"Musik"
    },
    "Programação":{
      "en":"Schedule",
      "pt":"Programação",
      "es":"Programación",
      "de":"Programmplan"
    },
    "_programacao":{
      "en":"schedule",
      "pt":"programacao",
      "es":"programacion",
      "de":"programmplan"
    },
    "Links Externos e Streams":{
      "en":"External Links and Streams",
      "pt":"Links Externos e Streams",
      "es":"Enlaces Externos e Streams",
      "de":"Externe Links und Streams"
    },
    "Links Externos":{
      "en":"External Links",
      "pt":"Links Externos",
      "es":"Enlaces Externos",
      "de":"Externe Links"
    },
    "Oficiais":{
      "en":"Official",
      "pt":"Oficiais",
      "es":"Oficiales",
      "de":"Offiziell"
    },
    "Informações":{
      "en":"Information",
      "pt":"Informações",
      "es":"Información",
      "de":"Informationen"
    },
    "Streaming":{
      "en":"Streaming",
      "pt":"Streaming",
      "es":"Streaming",
      "de":"Streaming"
    },
    "Read Online":{
      "en":"Read Online",
      "pt":"Ler Online",
      "es":"Leer Online",
      "de":"Lesen Online"
    },
    "Oficial Site":{
      "en":"Official Site",
      "pt":"Site Oficial",
      "es":"Sitio Oficial",
      "de":"Offizielle Seite"
    },
    "hq":{
      "en":"Head Quarters",
      "pt":"Sede Central",
      "es":"Sede Central",
      "de":"Hauptgebiets"
    },
    "_pt":{
      "en":"Portuguese",
      "pt":"Portugues",
      "es":"Portugés",
      "de":"Portugiesisch"
    },
    "_en":{
      "en":"English",
      "pt":"Ingles",
      "es":"Ingles",
      "de":"Englisch"
    },
    "_es":{
      "en":"Spanish",
      "pt":"Espanhol",
      "es":"Español",
      "de":"Spanisch"
    },
    "_de":{
      "en":"German",
      "pt":"Alemao",
      "es":"Aleman",
      "de":"Deutsch"
    },
    "Language":{
      "en":"Language",
      "pt":"Idioma",
      "es":"Idioma",
      "de":"Sprache"
    },
    "Do Not Show Again":{
      "en":"Do Not Show Again",
      "pt":"Não Mostrar Novamente",
      "es":"No Mostrar de Nuevo",
      "de":"Wieder nicht anzeigen"
    },
    "Go to":{
      "en":"Go to",
      "pt":"Ir para",
      "es":"Ir a",
      "de":"Gehe zu"
    },
    "O planejamento, data de lançamento e horário dos próximos episódios desta semana!":{
      "en":"The planning, release date and time of the next episodes of this week!",
      "pt":"O planejamento, data de lançamento e horário dos primeiros episódios desta semana!",
      "es":"La planificación, fecha de lanzamiento y hora de los primeros episódios de esta semana!",
      "de":"Die Planung, Release-Datum und -zeit der ersten Episoden dieser Woche!"
    },
    "Previous Week":{
      "en":"Previous Week",
      "pt":"Semana Anterior",
      "es":"Semana Anterior",
      "de":"Vorherige Woche"
    },
    "Next Week":{
      "en":"Next Week",
      "pt":"Próxima Semana",
      "es":"Semana Siguiente",
      "de":"Nächste Woche"
    },
  "Audio Original":{
    "en":"Original Audio",
    "pt":"Audio Original",
    "es":"Audio Original",
    "de":"Original Audio"
  },
  "Portuguese":{
    "en":"Portuguese",
    "pt":"Português",
    "es":"Portugés",
    "de":"Portugiesisch"
  },
  "English":{
    "en":"English",
    "pt":"Inglês",
    "es":"Ingles",
    "de":"Englisch"
  },
  "Spanish":{
    "en":"Spanish",
    "pt":"Espanhol",
    "es":"Español",
    "de":"Spanisch"
  },
  "German":{
    "en":"German",
    "pt":"Alemão",
    "es":"Aleman",
    "de":"Deutsch"
  },
  "Tags":{
    "en":"Tags",
    "pt":"Tags",
    "es":"Tags",
    "de":"Tags"
  },
  "Show Spoilers":{
    "en":"Show Spoilers",
    "pt":"Mostrar Spoilers",
    "es":"Mostrar Spoilers",
    "de":"Zeige Spoilers"  
  },
  "Hide Spoilers":{
    "en":"Hide Spoilers",
    "pt":"Esconder Spoilers",
    "es":"Esconder Spoilers",
    "de":"Verstecke Spoilers"
  },
  "Watch":{
    "en":"Watch",
    "pt":"Assistir",
    "es":"Ver",
    "de":"Schaue"
  },
  "watch":{
    "en":"watch",
    "pt":"assistir",
    "es":"ver",
    "de":"schaue"
  },
  "Reddit Discussion":{
    "en":"Reddit Discussion",
    "pt":"Discussão no Reddit",
    "es":"Discusión en Reddit",
    "de":"Reddit-Diskussion"
  },
  "Tocar no Spotify":{
    "en":"Play on Spotify",
    "pt":"Tocar no Spotify",
    "es":"Tocar en Spotify",
    "de":"Spotify-Spiel"
  },
  "Tocar na Apple Music":{
    "en":"Play on Apple Music",
    "pt":"Tocar na Apple Music",
    "es":"Tocar en Apple Music",
    "de":"Apple Music-Spiel"
  },
  "Tocar na Amazon Music":{
    "en":"Play on Amazon Music",
    "pt":"Tocar na Amazon Music",
    "es":"Tocar en Amazon Music",
    "de":"Amazon Music-Spiel"
  },
  "Adicionado a lista de reprodução":{
    "en":"Added to playlist",
    "pt":"Adicionado a lista de reprodução",
    "es":"Agregado a lista de reproducción",
    "de":"Zur Wiedergabeliste hinzugefügt"
  },
  "Lyrics":{
    "en":"Lyrics",
    "pt":"Letra",
    "es":"Letra",
    "de":"Liedtexte"
  },
  "Merge Anime":{
    "en":"Merge Anime",
    "pt":"Fusionar Animes",
    "es":"Fusionar Animes",
    "de":"Anime verbinden"
  },
  "Merge Manga":{
    "en":"Merge Manga",
    "pt":"Fusionar Mangas",
    "es":"Fusionar Mangas",
    "de":"Manga verbinden"
  },
  "Seja um Membro!":{
    "en":"Become a Member!",
    "pt":"Seja um Membro!",
    "es":"Seja un Miembro!",
    "de":"Werden Sie Mitglied!"
  },
  "Agradecemos sua Parceria":{
    "en":"We appreciate your support!",
    "pt":"Agradecemos sua parceria!",
    "es":"¡Agradecemos su apoyo!",
    "de":"Wir freuen uns, dass Sie helfen!"
  },
  "You will be considered a supporter for as many months as many coffees you buy.":{
    "en":"You will be considered a supporter for as many months as many coffees you buy.",
    "pt":"Você será considerado um apoiador pela mesma quantidade de meses que cafezinhos você comprar.",
    "es":"Sará considerado un apoyador por tantos cafés * meses que usted compre.",
    "de":"Sie bleiben so viele Monate lang Unterstützer, wie Sie Kaffee kaufen."
  },
  "Or if you become a 'member' at BuyMeACoffee, you will be considered a supporter for as long as you are a member":
  {
    "en":"Or if you become a 'member' at BuyMeACoffee, you will be considered a supporter for as long as you are a member",
    "pt":"Ou se você se tornar um 'membro' em BuyMeACoffee, você será considerado um apoiador por quantos meses você é membro.",
    "es":"O bien si usted se convierte en 'miembro' en BuyMeACoffee, usted se considerará un apoyador por tantos meses como usted sea miembro.",
    "de":"Oder, wenn Sie im BuyMeACoffee 'Mitglied' werden, werden Sie als Unternehmer betrachtet, solange Sie Mitglied sind."
  },
  "Your Local Anime&Manga Platform":{
    "en":"Your Local Anime&Manga Platform",
    "pt":"Sua Plataforma Local de Anime&Manga",
    "es":"Su Plataforma Local de Animes y Mangas ",
    "de":"Ihre lokale Anime&Manga-Plattform"
  },
  "Create music playlists with your favorite openings&endings":{
    "en":"Create music playlists with your favorite openings&endings",
    "pt":"Crie playlists com suas aberturas&encerramentos favoritos",
    "es":"Cree playlists de música con sus aperturas&cierres favoritas",
    "de":"Erstelle Music-Playlists mit deinen favoriten Oeffnungen&Endungen"
  },
  "Discover your obsessions, find out the best animes and mangas from each season and year":{
    "en":"Discover your obsessions, find out the best animes and mangas from each season and year",
    "pt":"Descubra suas obsessões, encontre os melhores animes e mangas de cada temporada e ano",
    "es":"Descubra sus obsesiones, descubra los mejores animes y mangas de cada estación y año",
    "de":"Entdecke deine Beobachter, finden Sie die besserten Anime und Mangas für jede Saison und Jahr"
  },
  "Expose your opinions. Leave reviews and impressions for others to see":{
    "en":"Expose your opinions. Leave reviews and impressions for others to see",
    "pt":"Divulgue suas opiniões. Deixe resenhas e impressões para outros verem",
    "es":"Exponga sus opiniones. Deja reseñas y impresiones para otros verem",
    "de":"Sichtbarkeit Ihrer Meinungen. Verlasse Bewertungen und Bewertungen für andere zu sehen"
  },
  "Create custom lists about everything! Anime, Manga, Characters, Companies, Magazines, & People!":{
    "en":"Create custom lists about everything! Anime, Manga, Characters, Companies, Magazines & People!",
    "pt":"Crie listas personalizadas sobre tudo! Animes, Mangas, Personagens, Empresas, Revistas & Pessoas!",
    "es":"Cree listas personalizadas sobre todo! Animes, Mangas, Personajes, Empresas, Revistas & Pessoas!",
    "de":"Erstelle individuelle Listen für alles! Anime, Manga, Charakters, Unternehmen, Zeitschriften & Menschen!"
  },
  "Crie listas de tudo!(inclusive músicas) Exponha suas opiniões. Descubra suas obssesões!":{
    "en":"Make lists of everything! (including music) Express your opinions. Discover your obsessions!",
    "pt":"-",
    "es":"¡Crea listas de todo! (incluida la música) ¡Expresa tus opiniones! ¡Descubre tus obsesiones!",
    "de":"Erstellen Sie Listen mit allem (einschließlich Musik). Äußern Sie Ihre Meinung. Entdecken Sie Ihre Obsessionen!"
  },
  "Forum":{
    "en":"Forum",
    "pt":"Fórum",
    "es":"Foro",
    "de":"Forum"
  },
  "seconds ago":{
    "en":"second(s) ago",
    "pt":"segundo(s) atrás",
    "es":"segundo(s) atrás",
    "de":"Sekunde(n) zuvor"
  },
  "minutes ago":{
    "en":"minute(s) ago",
    "pt":"minuto(s) atrás",
    "es":"minuto(s) atrás",
    "de":"Minute(n) zuvor"
  },
  "hours ago":{
    "en":"hour(s) ago",
    "pt":"hora(s) atrás",
    "es":"hora(s) atrás",
    "de":"Stunde(n) zuvor"
  },
  "days ago":{
    "en":"day(s) ago",
    "pt":"dia(s) atrás",
    "es":"día(s) atrás",
    "de":"Tag(e) zuvor"
  },
  "months ago":{
    "en":"month(s) ago",
    "pt":"mese(s) atrás",
    "es":"mese(s) atrás",
    "de":"Monat(e) zuvor"
  },
  "years ago":{
    "en":"year(s) ago",
    "pt":"ano(s) atrás",
    "es":"año(s) atrás",
    "de":"Jahr(e) zuvor"
  },
  "Contains Spoilers":{
    "en":"Contains Spoilers",
    "pt":"Contém Spoilers",
    "es":"Contiene Spoilers",
    "de":"Enthaltet Spoilers"
  },
  "NSFW Content":{
    "en":"NSFW",
    "pt":"NSFW",
    "es":"NSFW",
    "de":"NSFW"
  },
  "Contains Inappropriate Content":{
    "en":"Inappropriate Content",
    "pt":"Conteúdo Inapropriado",
    "es":"Contenido Inapropiado",
    "de":"Enthaltet unangemessenes Inhalts"
  },
  "Reveal Content":{
    "en":"Reveal Content",
    "pt":"Revelar Conteúdo",
    "es":"Revelar Contenido",
    "de":"Inhalt anzeigen"
  },
  "Latest Discussions":{
    "en":"Latest Discussions",
    "pt":"Discussões Recentes",
    "es":"Discusiones Recientes",
    "de":"Neueste Diskussionen"
  },
  "New Discussions":{
    "en":"New Discussions",
    "pt":"Novas Discussões",
    "es":"Nuevas Discusiones",
    "de":"Neue Diskussionen"
  },
  "Social Options":{
    "en":"Social Options",
    "pt":"Opções Sociais",
    "es":"Opciones Sociales",
    "de":"Sozial Optionen"
  },
  "Configuração Salva":{
    "en":"Configuration Saved",
    "pt":"Configuração Salva",
    "es":"Configuración Guardada",
    "de":"Konfiguration gespeichert"
  },
 "Disable Spoiler Protection":{
    "en":"Disable Spoiler Protection",
    "pt":"Desativar Proteção de Spoilers",
    "es":"Desactivar Protección de Spoilers",
    "de":"Spoiler-Schutz deaktivieren"
 },
 "_SpoilerExplanation":{
    "en":"Spoilers are content that would spoil your experience regarding a manga, anime, or book, usually containing reveals of things not shown in the media you are following, but already shown in another type of media.",
    "pt":"Spoilers são conteúdos que podem estragar sua experiencia com um manga, anime ou livro, geralmente contendo revelações de coisas não mostradas na midia que voce segue, mas ja mostradas em outro tipo de midia.",
    "es":"Los spoilers son contenidos que arruinarían tu experiencia con respecto a un manga, anime o libro, y generalmente contienen revelaciones de cosas que no se muestran en los medios que estás siguiendo, pero que ya se muestran en otro tipo de medios.",
    "de":"Spoiler sind Inhalte, die Ihr Erlebnis mit einem Manga, Anime oder Buch verderben würden. Sie enthalten in der Regel Enthüllungen von Dingen, die in den Medien, denen Sie folgen, nicht, aber bereits in anderen Medientypen gezeigt werden."
 },
 "Disable NSFW Protection":{
    "en":"Disable NSFW Protection",
    "pt":"Desativar Proteção NSFW",
    "es":"Desactivar Protección NSFW",
    "de":"NSFW-Schutz deaktivieren"
 },
 "_NSFWExplanation":{
    "en":"NSFW content is content that is dangerous to be seen in public places like work, school and libraries. Such as porn or gore",
    "pt":"Conteúdo NSFW é aquele que é perigoso de ser ver em locais públicos como trabalho, escola e bibliotecas, como pornografia ou gore",
    "es":"El contenido NSFW es el contenido que es peligroso de ser visto en lugares públicos como trabajo, escuela e bibliotecas, como pornografia o gore",
    "de":"NSFW-Inhalt ist Inhalt, das gefaehrlich ist, dass es in oeffentlichen Orten wie Arbeit, Schulen und Bibliotheken. Pornographie oder Gore gesehen werden kann"
  },
 "Disable Inapropriate Content Protection":{
    "en":"Disable Inapropriate Content Protection",
    "pt":"Desativar Proteção de Conteúdo Inapropriado",
    "es":"Desactivar Protección de Contenido Inapropiado",
    "de":"Unangemessenes Inhalts-Schutz deaktivieren"
 },
 "_InapropriateContentExplanation":{
    "en":"Inappropriate content is content that might not be allowed by law in some countries or lives in a legal grey area, content that could endanger the platform if seen here. Such as links to torrents, fansubs, etc",
    "pt":"Conteúdo Inapropriado é aquele que pode não ser permitido pela lei em alguns países, ou que exista em uma zona legal cinzenta, conteúdos que podem causar problemas para a plataforma se encontrados aqui. Como links para torrents, fansubs, etc",
    "es":"El contenido inapropiado es contenido que podría no estar permitido por la ley en algunos países o que se encuentra en un área legal gris, contenido que podría poner en peligro la plataforma si se ve aquí. Como enlaces a torrents, fansubs, etc.",
    "de":"Unangemessene Inhalte sind Inhalte, die in manchen Ländern möglicherweise gesetzlich nicht zulässig sind oder sich in einer rechtlichen Grauzone befinden. Inhalte, die die Plattform gefährden könnten, wenn sie hier gesehen würden. Zum Beispiel Links zu Torrents, Fansubs usw."
  },
  "Criar uma Thread":{
    "en":"Create a Thread",
    "pt":"Criar uma Thread",
    "es":"Crear un Hilo",
    "de":"Thread Erstellen"
  },
  "Contéudo da Thread":{
    "en":"Thread Content",
    "pt":"Conteúdo da Thread",
    "es":"Contenido del Hilo",
    "de":"Thread-Inhalt"
  },
  "_warningThreadText":{
    "en":"We are a free speech platform and we don't delete content, but we put sensitive content in a 'veil' to protect our community. Select below if your content fits some of these categories:",
    "pt":"Nós somos uma plataforma de livre fala e não deletamos contéudo, mas colocamos conteúdos sensíveis em um 'véu' para proteger nossa comunidade. Selecione abaixo se seu contéudo se enquadra em algumas dessas categorias:",
    "es":"Somos una plataforma de libre expresión y no eliminamos contenido, pero ponemos contenido sensible en un 'velo' para proteger a nuestra comunidad. Seleccione a continuación si su contenido se ajusta a algunas de estas categorías:",
    "de":"Wir sind eine Plattform der freien Meinungsäußerung und löschen keine Inhalte, aber wir legen sensible Inhalte in einen 'Schleier' um, um unsere Community zu schützen. Wählen Sie unten aus, ob Ihr Inhalt in einige dieser Kategorien fällt:"
  },
  "Criar Thread":{
    "en":"Create Thread",
    "pt":"Criar Thread",
    "es":"Crear Hilo",
    "de":"Thread Erstellen"
  },
  "Titulo da Thread":{
    "en":"Thread Title",
    "pt":"Titulo da Thread",
    "es":"Titulo del Hilo",
    "de":"Thread-Titel"
  },
  "Enter tags, separated by commas":{
    "en":"Enter tags, separated by commas",
    "pt":"Digite tags, separadas por virgulas",
    "es":"Ingrese etiquetas, separadas por comas",
    "de":"Tags eingeben, durch Kommas getrennt"
  },
  "Create Thread":{
    "en":"Create Thread",
    "pt":"Criar Thread",
    "es":"Crear Hilo",
    "de":"Thread Erstellen"
  },
  "You need to write a title and content to create a Thread.":{
    "en":"You need to write a title and content to create a Thread.",
    "pt":"Você precisa escrever um titulo e um conteúdo para criar um Thread.",
    "es":"Necesitas escribir un titulo y un contenido para crear un Hilo.",
    "de":"Sie müssen einen Titel und einen Inhalt eingeben, um einen Thread zu erstellen."
  },
  "You need to select a section create a Thread.":{
    "en":"You need to select a section create a Thread.",
    "pt":"Você precisa selecionar uma seção para criar um Thread.",
    "es":"Necesitas seleccionar una seccion para crear un Hilo.",
    "de":"Sie sollten eine Sektion auswhlen, um einen Thread zu erstellen."
  },
  "Creating Thread...":{
    "en":"Creating Thread...",
    "pt":"Criando Thread...",
    "es":"Creando Hilo...",
    "de":"Thread wird erstellt..."
  },
  "Thread Created, redirecting!":{
    "en":"Thread Created, redirecting!",
    "pt":"Thread Criada, redirecionando!",
    "es":"Hilo creado, redireccionando!",
    "de":"Thread erstellt, umleiten!"
  },
  "Houve um erro na criação da Thread, tente novamente!":{
    "en":"There was an error creating the Thread, try again!",
    "pt":"Houve um erro na criação da Thread, tente novamente!",
    "es":"Hubo un error al crear el Hilo, vuelva a intentarlo!",
    "de":"Es gab einen Fehler beim Erstellen des Threads, versuche es erneut!"
  },
  "Update Successful":{
    "en":"Update Successful",
    "pt":"Atualização Completa",
    "es":"Actualización Completa",
    "de":"Aktualisierung erfolgreich"
  },
  "Update Failed, try again later":{
    "en":"Update Failed, try again later",
    "pt":"Atualização Falhou, tente novamente mais tarde",
    "es":"Actualización Fallida, vuelva a intentarlo mas tarde",
    "de":"Aktualisierung fehlgeschlagen, versuche es erneut"
  },
  "Deletar Thread e Respostas(Permanente)":{
    "en":"Delete Thread and Replies(Permanent)",
    "pt":"Deletar Thread e Respostas(Permanente)",
    "es":"Borrar Hilo y Respuestas(Permanente)",
    "de":"Thread und Antworten löschen(Permanent)"
  },
  "Deletion Successful":{
    "en":"Deletion Successful",
    "pt":"Remoção Completa",
    "es":"Eliminación Completa",
    "de":"Löschen erfolgreich"
  },
  "Delete Failed, try again later":{
    "en":"Delete Failed, try again later",
    "pt":"Remoção Falhou, tente novamente mais tarde",
    "es":"Eliminación Fallida, vuelva a intentarlo mas tarde",
    "de":"Löschen fehlgeschlagen, versuche es erneut"
  },
  "Thread Deleted":{
    "en":"(((Thread Deleted)))",
    "pt":"(((Thread Excluída)))",
    "es":"(((Hilo Eliminado)))",
    "de":"(((Thread gelöscht)))"
  },
  "Contéudo do Post":{
    "en":"Add a Comment",
    "pt":"Adicionar um Comentario",
    "es":"Agregar un Comentario",
    "de":"Kommentar hinzufügen"
  },
  "Comentário Criado com Sucesso!":{
    "en":"Comment Created Successfully!",
    "pt":"Comentario Criado com Sucesso!",
    "es":"Comentario Creado con Exito!",
    "de":"Kommentar erfolgreich erstellt!"
  },
  "Ver mais comentários":{
    "en":"See more comments",
    "pt":"Ver mais comentários",
    "es":"Ver mas comentarios",
    "de":"Mehr Kommentare sehen"
  },
  "Highlighted Comment":{
    "en":"Highlighted Comment",
    "pt":"Comentario Destacado",
    "es":"Comentario Destacado",
    "de":"Hervorgehobener Kommentar"
  },
  "Pesquisar":{
    "en":"Search",
    "pt":"Pesquisar",
    "es":"Buscar",
    "de":"Suchen"
  },
  "No Search Query Entered":{
    "en":"No Search Query Entered",
    "pt":"Nenhuma Pesquisa Inserida",
    "es":"Ninguna Busqueda Ingresada",
    "de":"Keine Suchanfrage eingegeben"
  },
  "Unpin":{
    "en":"Unpin",
    "pt":"Despinar",
    "es":"Despinar",
    "de":"Unpin"
  },
  "Pin":{
    "en":"Pin",
    "pt":"Destacar",
    "es":"Destacar",
    "de":"Hervorheben"
  },
  "Select a section":{
    "en":"Select a section",
    "pt":"Selecione uma seção",
    "es":"Seleccione una sección",
    "de":"Sektion auswhlen"
  },
  "Link Resources to Thread":{
    "en":"Link Resources to Thread",
    "pt":"Conectar Páginas a Thread",
    "es":"Enlazar Recursos al Hilo",
    "de":"Ressourcen zu einem Thread verlinken"
  },
  "Próximo":{
    "en":"Next",
    "pt":"Próximo",
    "es":"Siguiente",
    "de":"Weiter"
  },
  "Anterior":{
    "en":"Previous",
    "pt":"Anterior",
    "es":"Anterior",
    "de":"Zurück"
  }

  }