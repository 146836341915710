import React, { Component } from 'react';
import "./ThreadCard.css";
import WYSIWYGWriter from './WYSIWYGWriter';
import { genRandId } from './WYSIWYGWriterUtils';
import {lng} from '../ulanguages/language';
import MyAppPool from './../AppPool';
import ThreadComment from './ThreadCommentOld';
import ButtonsPanel from './ButtonsPanel';
import { PropTypes } from 'prop-types';
import CSSTransition2 from './CSSTransition2';
import HtmlToReact from 'html-to-react';
import { getCurrentDomain, getCurrentPageTitleUrlEncoded, getCurrentUrlUrlEncoded } from './../util/windowUtil';

function delay(t) {
    return new Promise(function(resolve) { 
        setTimeout(resolve, t)
    });
 }

class ThreadCard extends Component {

    constructor(props) {
        super(props);
        this.state = {editing:false,commentsOpen: false,editorEditId:genRandId('the-'), editorId:genRandId('tae-'), respWriterVisible:false};
        this.toggleAnswer = this.toggleAnswer.bind(this);
        this.toggleRespWriter = this.toggleRespWriter.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.sendRespWriterContent = this.sendRespWriterContent.bind(this);
    }
  componentDidMount()
  {
      
  }

  toggleAnswer()
  {
    this.setState({
        commentsOpen: !this.state.commentsOpen
      });
  }
  toggleRespWriter()
  {
    this.setState({
        respWriterVisible: !this.state.respWriterVisible
      });
  }
  toggleEditing()
  {
    this.setState({
        editing: !this.state.editing
      });
  }

  async sendRespWriterContent(content){
      //let a = await delay(3000); 
      return Promise.reject('error:'+content);
  }
  async sendEditContent(content){
    //let a = await delay(3000); 
    return Promise.reject('error:'+content);
  }
  deletePost()
  {
     // console.log("Potato");
  }

  render(){
    let {thread,currentUserId,currentUserRoles,ready} = this.props;
    let showEditIcons = false;
    let shareUrl = encodeURIComponent(thread.url);
    let siteName = encodeURIComponent('Meus Animes Brasili');
    let hashtags = encodeURIComponent('anime , mab , meusanimesbrasili');
    let date = thread.date;
    if (currentUserId===thread.author.userid || currentUserRoles.indexOf('mabmod')!==-1)
      showEditIcons=true;

    let displayContent = thread.content;
    if (typeof displayContent ==='string')
        displayContent.replaceAll("<img ", "<img alt=\""+lng("User uploaded Image")+"\" ");

    return (
        <CSSTransition2 
        in={ready}
        timeout={300}
        classNames="thread-node"
        unmountOnExit>
    <div className="comp_thread">
    <div className="uk-card uk-card-default comp_thread_style">
        <div className="uk-flex uk-flex-row flexAlign"> 
        <img className="userimg" src='https://animesdai.stream/imgs/uploadedAvatar/a9065.jpg?m=1576431748'/>
            <div>
                <span className="author">{thread.author.name} <span className="threadLocale smalllink">{lng('em')} {thread.title} - {date}</span> </span>
            </div>
        </div>
        <div>
        {!this.state.editing?
        <p className="postContent" dangerouslySetInnerHTML={{__html: displayContent}}>
        </p>
        :
        <WYSIWYGWriter id={this.state.editorEditId} hasSendButton={1}
        placeHolder={lng("O que esta pensando ?")}
        actions={
            [
            'superscript',
            'link',
            'image',
            'youtube',
            'emoji',
            'spoiler',
            'insertOrderedList',
            'insertUnorderedList'
            ]} 
            actionClasses={{
                insertOrderedList:'list-ol',
                insertUnorderedList:'list-ul',
                table:'border-all',
                spoiler: 'eye-slash',
                emoji:'grin-squint-tears',
                youtube:'fab fa-youtube'
                }}
            actionTags={{
                link:'<a ',
                spoiler:'<span class="spoiler">'
            }}
            onCloseClick = {this.toggleEditing}
            onSendClick = {this.sendEditContent}
            startContent = {thread.content}
                />
        }
        </div>
        <div>
        <div className="uk-align-left removeMargin">
            {thread.upvotes}
            <span className="fas fa-arrow-up clickable arrowButton"/>
            <span className="fas fa-arrow-down clickable arrowButton"/>
        </div>
        <div className="rightIcons">
            <span onClick={this.toggleAnswer} className="iconButton clickable smalllink">  <span className="fas fa-comments"/> {thread.comments.length}. </span>
            <span className="iconButton clickable smalllink">  <span className="fas fa-share"/> {lng("Compartilhar")}. </span>
            <div data-uk-drop="pos: top-center; mode:click" className="uk-card uk-card-default sharePop">
            <ButtonsPanel
                buttons={[
                    {tooltip:lng('Compartilhar no Facebook'),ukicon:'facebook', url:"https://www.facebook.com/share.php?u="+ MyAppPool.currentUrlEncoded }
                    ,{tooltip:lng('Compartilhar no Twitter'),ukicon:'twitter', url:"http://twitter.com/share?related="+getCurrentDomain()+"&via=meusanimesbrasili&url="+getCurrentUrlUrlEncoded()+"&text="+getCurrentPageTitleUrlEncoded()+"&hashtags=anime,nani,"+lng('meusanimesbrasili')}
                    ,{tooltip:lng('Compartilhar no Reddit'),ukicon:'reddit', url:"http://reddit.com/submit?url="+getCurrentUrlUrlEncoded()+"&title="+getCurrentPageTitleUrlEncoded()}
                    ,{tooltip:lng('Compartilhar no Tumblr'),ukicon:'tumblr', url:"http://www.tumblr.com/share/link?url="+getCurrentUrlUrlEncoded()+"&name="+getCurrentPageTitleUrlEncoded()}             
                ,{tooltip:lng('Copiar para à Area de Colagem'),ukicon:'link', onClick: (e) => {e.preventDefault(); MyAppPool.cTextInteraction.copyToClipBoard(shareUrl); }}
                ]}/>
            </div>
            <span onClick={()=>this.toggleRespWriter()} className="iconButton clickable smalllink">  <span className="fas fa-reply"/> {lng("Resp")}. </span>
            {showEditIcons?
            <span onClick={()=>this.toggleEditing()} className="fas fa-pen iconButton clickable"/>
            :<></>}
            {showEditIcons?
            <span onClick={()=>MyAppPool.cModal.set('Titulo','Tem certeza?',this.deletePost)} className="fas fa-trash-alt iconButton clickable"/>
            :<></>}
            </div>
        </div>  
    </div>
    <CSSTransition2
            in={this.state.respWriterVisible}
            timeout={300}
            classNames="write-node"
            unmountOnExit
    >
        <WYSIWYGWriter id={this.state.editorId} hasSendButton={1} 
        placeHolder={lng("O que esta pensando ?")}
        actions={
            [
            'superscript',
            'link',
            'image',
            'youtube',
            'emoji',
            'spoiler',
            'insertOrderedList',
            'insertUnorderedList'
            ]} 
            actionClasses={{
                insertOrderedList:'list-ol',
                insertUnorderedList:'list-ul',
                table:'border-all',
                spoiler: 'eye-slash',
                emoji:'grin-squint-tears',
                youtube:'fab fa-youtube'
                }}
            actionTags={{
                link:'<a ',
                spoiler:'<span class="spoiler">'
            }}
            onCloseClick = {this.toggleRespWriter}
            onSendClick = {this.sendRespWriterContent}
                />
        </CSSTransition2>    

        <CSSTransition2
            in={this.state.commentsOpen}
            timeout={300}
            classNames="my-node"
            unmountOnExit
        >
        <div style={{width:'100%'}}>
            {
                thread.comments.map((element, index) => {
                return <ThreadComment key={index} thread={element} />
                })
            }
        </div>
    </CSSTransition2>

</div>
</CSSTransition2>
    );
    }

}

ThreadCard.propTypes = {
    ready: PropTypes.number.isRequired,
    thread: PropTypes.shape({
    id:PropTypes.number.isRequired,
    subid:PropTypes.number.isRequired,
    threadid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    upvotes:PropTypes.number.isRequired,
    date:PropTypes.string.isRequired,
    comments:PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        userid: PropTypes.number.isRequired,
        avatarurl: PropTypes.string
      }).isRequired
    }).isRequired,
    currentUserId: PropTypes.number.isRequired,
    currentUserRoles: PropTypes.arrayOf(PropTypes.string)
  };

ThreadCard.defaultProps = {
    ready:0,
    thread: {
        threadid:'',
        id:0,
        title:'',
        url: '',
        comments:[],
        upvotes:0,
        content: 'BlabBla <img src="https://i.imgur.com/kjndS9P.jpg"> Bla',
        date:'',
        author: {
            name: '',
            userid: 1,
            avatarurl: ''
        }},
        currentUserId: 0,
        currentUserRoles: ['guest']
  };

export default ThreadCard;
