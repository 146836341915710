
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { lng } from '../../ulanguages/language';
import { animeFastResourceType, contentTypeToFastType, getIfIsSetElems, isSet, mangaFastResourceType } from '../../util/typeu';
import RelatedWorks from '../../view_component/RelatedWorks';
import MyAppPool from './../../AppPool';
import { isMobile } from './../../util/mobile';
import ColumnCard from './../../view_component/ColumnCard';
import ScrollToTopButtom from './../../view_component/ScrollToTopButton';
import SkeletonBlock from './../../view_component/SkeletonBlock';
import './UserGeralView.css';
import { toJS } from 'mobx';
import ChartCategoryHorizontal from '../../view_component/ChartCategoryHorizontal';
import ChartVerticalBar from '../../view_component/ChartVerticalBar';
import User from '../user';
import ReviewGroup, { animeCats, mangaCats } from './../../view_component/ReviewGroup';
import ReviewItemHome from '../../view_component/ReviewItemHome';
import ReactionItemHome from '../../view_component/ReactionItemHome';
import InfiniteScrollBlock from '../../view_component/InfiniteScrollBlock';
import ThreadReplyWriter from '../../view_component/ThreadReplyWriter';
import ThreadReplyWriterUserPage from '../../view_component/ThreadReplyWriterUserPage';
import ThreadComment from '../../view_component/ThreadComment';
import { getRouteParamAtIndex } from '../../util/text';
import { getCurrentUrl } from '../../util/windowUtil';
import ThreadPreview from '../../view_component/ThreadPreview';

@observer
class UserGeralView extends Component {

  constructor(props) {
    super(props);
    this.state = {currentWallPage:0,disableNextWall:false };
    }
    loadNextWallPage = async () => {
        let {current} = this.props;
        let {currentWallPage,disableNextWall} = this.state;
        if (disableNextWall===true)
            return Promise.resolve();

        let nextPage = currentWallPage+1;
        if (current.ready)
        {
            let userId=current.id;
            let responseLength = await MyAppPool.cLatestContent.getUserWall(userId,nextPage,User.getUserWallPageSize());
            //neocomu indexes start at 1 instead of 0
            let userThreadReplies = await MyAppPool.cNeoComu.getUserThreadRepliesWithHotRanking(userId,nextPage+1,User.getUserWallPageSize());
            let userCreatedThreads = await MyAppPool.cNeoComu.getThreadsByUserId(userId,nextPage+1,User.getUserWallPageSize());
            responseLength += userThreadReplies.length;
            responseLength += userCreatedThreads.length;

            if (responseLength>0)
                this.setState({currentWallPage:nextPage});
            else
                this.setState({disableNextWall:true});
        }
        
        return Promise.resolve();
    }

    render()
    {
        let {current} = this.props;
        let {currentWallPage} = this.state;
        
        let verticalChartValues = [];
        let followCountChart = [];
        let verticalChartValuesManga = [];
        let followCountChartManga = [];
        

        let pageId=getRouteParamAtIndex(getCurrentUrl(),2);
        if (isNaN(Number(pageId)))
            pageId=0;
        let pageContentName='';
        let resourceType = contentTypeToFastType('user');
        let wallPageContent =[];
        let comurContent =[];
        let postsContent = [];
        let threadsContent = [];
        if (current.ready)
        {
            let tabBaseUrl = current.baseUrl;
            pageId=current.id;

            let wall = getIfIsSetElems(MyAppPool.session.usersWallItems,[pageId,User.getUserWallPageSize()]);
                    if (isSet(wall))
                comurContent = Object.values(wall).flat();

            wallPageContent = comurContent;
            

            pageContentName = current.fullName;
            verticalChartValues = 
            [
                {value:current.animeGrades[5],text:'<50-59',gradient:"linear-gradient(#FF6A00, #FF0000)"},
                {value:current.animeGrades[6],text:'60-69',gradient:"linear-gradient(#FFD800, #FF6A00)"},
                {value:current.animeGrades[7],text:'70-79',gradient:"linear-gradient(#B6FF00, #FFD800)"},
                {value:current.animeGrades[8],text:'80-89',gradient:"linear-gradient(#00FF21, #99ff00)"},
                {value:current.animeGrades[9],text:'90-100',gradient:"linear-gradient(#00FF21, #4CFF00)"}
            ];
            followCountChart = [
                {text:lng("Quer"), value:current.countwantAnime, color:"linear-gradient(#6600ff, #9999ff)"},
                {text:lng("Assiste"), value:current.countcurrentAnime, color:"linear-gradient(#0099ff, #66ccff)"},
                {text:lng("Concluiu"), value:current.countfinishedAnime, color:"linear-gradient(#4dd520, #99ff99)"},
                {text:lng("Largou"), value:current.countdroppedAnime, color:"linear-gradient(#669999, #d6f0f0)"}
              ]
              verticalChartValuesManga = 
              [
                  {value:current.mangaGrades[5],text:'<50-59',gradient:"linear-gradient(#FF6A00, #FF0000)"},
                  {value:current.mangaGrades[6],text:'60-69',gradient:"linear-gradient(#FFD800, #FF6A00)"},
                  {value:current.mangaGrades[7],text:'70-79',gradient:"linear-gradient(#B6FF00, #FFD800)"},
                  {value:current.mangaGrades[8],text:'80-89',gradient:"linear-gradient(#00FF21, #99ff00)"},
                  {value:current.mangaGrades[9],text:'90-100',gradient:"linear-gradient(#00FF21, #4CFF00)"}
              ];
              followCountChartManga = [
                  {text:lng("Quer"), value:current.countwantManga, color:"linear-gradient(#6600ff, #9999ff)"},
                  {text:lng("Lê"), value:current.countcurrentManga, color:"linear-gradient(#0099ff, #66ccff)"},
                  {text:lng("Concluiu"), value:current.countfinishedManga, color:"linear-gradient(#4dd520, #99ff99)"},
                  {text:lng("Largou"), value:current.countdroppedManga, color:"linear-gradient(#669999, #d6f0f0)"}
                ]
        } 
        
        if (!isMobile.any)//DESKTOP LAYOUT
            return (<div className='user_geral_view'>
        <div className="contentArea uk-flex uk-flex-row">
        
        <div className="leftContent">
                
        </div>

        <div className="middleContent">
            {current.ready? 
            <div>
                <div className="flexDrop">
                <div  style={{width:"49%",marginBottom:"15px",marginLeft:"1%"}}>
                <ChartCategoryHorizontal 
                ready={current.ready}
                items = {followCountChartManga}
                title = {lng('Leitura Stats')}
                />
                </div>
                <div  style={{width:"49%",marginBottom:"15px",marginLeft:"1%"}}>
                <ChartCategoryHorizontal 
                ready={current.ready}
                items = {followCountChart}
                title = {lng('Anime Stats')}
                />
                </div>
                <div  style={{width:"49%",marginBottom:"15px",marginLeft:"1%"}}>
                <ChartVerticalBar
                ready={current.ready}
                items = {verticalChartValuesManga}
                title = {lng('Notas - Leitura')}
                />
                </div>
                <div  style={{width:"49%",marginLeft:"0px",marginLeft:"1%"}}>
                <ChartVerticalBar
                ready={current.ready}
                items = {verticalChartValues}
                title = {lng('Notas - Anime')}
                />
                </div>
                </div>
            <div className="uk-flex uk-flex-row">
                
                
            </div>
            <h2>{lng("Atividade")}</h2>

            <ThreadReplyWriterUserPage userId={pageId} pageSize={User.getUserWallPageSize()} />

            <div>
            <InfiniteScrollBlock fetchFunction={this.loadNextWallPage}>
            {wallPageContent.map((item, index) => {
                let mycats = undefined;
                switch(item.type)
                {
                    case 'review':{
                        let itemData = MyAppPool.session.reviewData[item.id];
                        if (itemData.resourcetype===animeFastResourceType)
                            mycats=animeCats;
                        if (itemData.resourcetype===mangaFastResourceType)
                            mycats=mangaCats;
                        return <div className="wallItem" key={index}><ReviewItemHome
                        currentUserId={MyAppPool.currentUser.userid}
                        currentUserRoles={MyAppPool.currentUser.roles}
                        reviewItem={itemData}
                        cats={mycats}
                        /></div>;
                    }
                    case 'impression':{
                        return <div className="wallItem" key={index}><ReactionItemHome
                            reactionItem={MyAppPool.session.reactionData[item.id]}
                            currentUserRoles={MyAppPool.currentUser.roles}
                            currentUserId={MyAppPool.currentUser.userid}
                            bigResourceImage={true}/></div>;
                    }
                    case 'reply':{
                        return <div className="wallItem" key={index} >
                            <div className="firstReplyPadding">
                            <ThreadComment showThread={true} pageSize={User.getUserWallPageSize()} reply={MyAppPool.session.neocomu.replies[item.id]} key={index} />
                            </div>
                        </div>;
                    }
                    case 'thread':{
                        return <div className="wallItem" key={index} >
                            <div className="firstReplyPadding">
                            <ThreadPreview pageSize={User.getUserWallPageSize()} thread={MyAppPool.session.neocomu.threads[item.id]} key={index} />
                            </div>
                        </div>;
                    }
                }
                })}
                </InfiniteScrollBlock>
            </div>
            </div>
            :
            <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            </div>}
            

        </div>
        
        <div className="rightContent">
            <div style={{width:"100%"}}>
            
            <div  style={{marginTop:"15px"}}>
                <ScrollToTopButtom />
            </div>
            </div>
        </div>
        

        </div>
        </div>);

    if (isMobile.any)//MOBILE LAYOUT
        return (<div className='user_geral_view'>
        <div className="contentArea uk-flex uk-flex-row">

        <div className="middleContent">
        {current.ready? 
            <div>
                <div className="flexDrop">
                <div  style={{width:"100%",marginBottom:"15px"}}>
                <ChartCategoryHorizontal 
                ready={current.ready}
                items = {followCountChart}
                title = {lng('Anime Stats')}
                />
                </div>
                <div  style={{width:"100%",}}>
                <ChartVerticalBar
                ready={current.ready}
                items = {verticalChartValues}
                title = {lng('Notas - Anime')}
                />
                </div>
                <div  style={{width:"100%",marginBottom:"15px"}}>
                <ChartCategoryHorizontal 
                ready={current.ready}
                items = {followCountChartManga}
                title = {lng('Leitura Stats')}
                />
                </div>
                
                <div  style={{width:"100%",marginBottom:"15px"}}>
                <ChartVerticalBar
                ready={current.ready}
                items = {verticalChartValuesManga}
                title = {lng('Notas - Leitura')}
                />
                </div>
                
                </div>
            <div className="uk-flex uk-flex-row">
                
                
            </div>
            <h2>{lng("Atividade")}</h2>
            <div>
                <InfiniteScrollBlock fetchFunction={this.loadNextWallPage}>
                    {wallPageContent.map((item, index) => {
                    let mycats = undefined;
                    switch(item.type)
                    {
                        case 'review':{
                            let itemData = MyAppPool.session.reviewData[item.id];
                            if (itemData.resourcetype===animeFastResourceType)
                                mycats=animeCats;
                            if (itemData.resourcetype===mangaFastResourceType)
                                mycats=mangaCats;
                            return <div className="wallItem" key={index}><ReviewItemHome
                            currentUserId={MyAppPool.currentUser.userid}
                            currentUserRoles={MyAppPool.currentUser.roles}
                            reviewItem={itemData}
                            cats={mycats}
                            /></div>;
                        }
                        case 'impression':{
                            return <div className="wallItem" key={index}><ReactionItemHome
                                reactionItem={MyAppPool.session.reactionData[item.id]}
                                currentUserRoles={MyAppPool.currentUser.roles}
                                currentUserId={MyAppPool.currentUser.userid}
                                bigResourceImage={true}/></div>;
                        }
                    }
                    })}
                </InfiniteScrollBlock>
            </div>
            </div>
            :
            <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            </div>}
        </div>

        </div>
        </div>);


    }

    
}

export default UserGeralView;