import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
import './ThreadComment.css'; // Ensure this file exists for styling
import { isSet, mobToObj, replyFastResourceType, userFastResourceType } from '../util/typeu';
import MyAppPool from '../AppPool';
import { getImgAddress, getSiteShortName } from '../util/text';
import { getUserAvatar } from '../util/icon';
import { observer } from 'mobx-react';
import htmlToReact from 'html-to-react';
import { lng } from '../ulanguages/language';
import { delay, timeSinceString } from '../util/timeUtil';
import ThreadTag from './ThreadTag';
import LinkV2 from './LinkV2';
import { getForumSectionPath, getForumTagPath, getForumThreadPage, getUserRoute } from '../store/AppRoutes';
import TitleBadge from './TitleBadge';
import YoutubeVideo from './YoutubeVideo';
import { genRandId } from './WYSIWYGWriterUtils';
import WYSIWYGWriter from './WYSIWYGWriter';
import ButtonsPanel from './ButtonsPanel';
import { ThreadContent } from './ThreadContent';
import { getCurrentDomain, getCurrentPageTitleUrlEncoded, getCurrentUrlUrlEncoded } from '../util/windowUtil';
import { toJS } from 'mobx';
import CSSTransition2 from './CSSTransition2';
import Spinner from './Spinner';


function getPreview(displayContent) {
  const text = displayContent.replace(/<[^>]+>/g, '');
  const preview = text.substring(0, 200);
  return preview.length === 200 ? preview + '...' : preview;
}

@observer
class ThreadComment extends Component {
  // Helper function to format the relative time
  constructor(props) {
    super(props);
    this.state = {
      revealContent: false,
      revealHovered: false,
      editing:false,
      editorEditId:genRandId('the-'), 
      editorId:genRandId('tae-'), 

      title: '',
      content: '',
      isNSFW: false,
      isInappropriate: false,
      isSpoiler: false,
      respWriterVisible: false,
      
      respContent: '',
      respIsNSFW: false,
      respIsInappropriate: false,
      respIsSpoiler: false,
      fetchingMore:false
    };
  }

  toggleRespWriter = (editorText) =>
  {
    const { reply } = this.props;

    if (MyAppPool.cAuth.isGuest())
    {
      MyAppPool.cAuth.activateGlobalLoginBox();
      return;
    }
    
    this.setState({
      respWriterVisible: !this.state.respWriterVisible
    });
    if (typeof editorText =='string' && editorText!=='')
      this.setState({
        respWriterVisible: !this.state.respWriterVisible,
        respContent: editorText
      });
  }

  toggleEditing = () =>
  {
    const { reply } = this.props;
    const { content,isNSFW,isInappropriate,isSpoiler } = reply.reply.properties;

    this.setState({
      editing: !this.state.editing,
      content: content,
      isNSFW: isNSFW,
      isInappropriate: isInappropriate,
      isSpoiler: isSpoiler
    });
  }

  sendEditContent= async (content) =>{
    const { reply } = this.props;
    const { isNSFW,isInappropriate,isSpoiler } = this.state;
    const { id } = reply.reply;

    
    return MyAppPool.cNeoComu.updateReply(content,id,{isNSFW,isInappropriate,isSpoiler})
    .then(()=>{
      MyAppPool.cAlert.notifyUser(lng("Update Successful")+"!","",200);
      this.setState({
        editing: false
      });
      return MyAppPool.cNeoComu.getReply(id);
    })
    .catch((e) => {
      console.error(e);
      MyAppPool.cAlert.notifyUser(lng("Update Failed, try again later")+"!","",500);
    });
    
  }
  deletePost = () => {
    const { reply } = this.props;
    const { id } = reply.reply;
    
    MyAppPool.cNeoComu.deleteReply(id).then(()=>{
      MyAppPool.cAlert.notifyUser(lng("Deletion Successful")+"!","",200);
      return MyAppPool.cNeoComu.getReply(id);

    }).catch((err)=>{
      console.error(err);
      MyAppPool.cAlert.notifyUser(lng("Delete Failed, try again later")+"!","",500);
    });
  }

  sendRespWriterContent = async (content) =>{
    let {respIsNSFW, respIsInappropriate, respIsSpoiler} = this.state;
    const { reply,pageSize } = this.props;
    const { id } = reply.reply;

    

    let depth = 3;
    let data = await MyAppPool.cNeoComu.createReplyToReply(content,id , {isNSFW:respIsNSFW, isInappropriate:respIsInappropriate, isSpoiler:respIsSpoiler});
    let p2 = delay(100).then(()=>MyAppPool.cNeoComu.getReply(id,1, pageSize, depth));
    this.toggleRespWriter();
    MyAppPool.cAlert.notifyOk(lng("Comentário Criado com Sucesso!"));
    return data;
  }

  handleCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  handleCheckboxChangeResp = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  loadReplies = () => {
    const { reply,pageSize } = this.props;
    let {fetchingMore} = this.state;
    const { id } = reply.reply;

    this.setState({fetchingMore:true});
    
    return MyAppPool.cNeoComu.loadMoreReplies(id,pageSize).then(()=>{this.setState({fetchingMore:false});}).catch((e)=>{
      console.error(e);
      this.setState({fetchingMore:false});
      });

  }

  render() {
    const { reply,pageSize,showThread } = this.props;
    
    const { title,votes, content, datecreated, repliesAmount, views,isNSFW,isInappropriate,isSpoiler } = reply.reply.properties;
    const { id } = reply.reply;
    const { user } = reply; 

    let threadTitle = '';
    let threadId = reply.threadId;
    if (isSet(reply.thread))
    {
      if (isSet(reply.thread.properties))
        threadTitle = reply.thread.properties.title;
    }
    
    let {revealContent, revealHovered,respContent,fetchingMore} = this.state;
    const {disableNSFWProtection, disableSpoilerProtection, disableInapropriateContentProtection} = MyAppPool.session.browserOptions;
    
    let thread = MyAppPool.session.neocomu.threads[''+reply.threadId];
    let threadName = '';
    let section ={id:-1};
    if (isSet(thread))
    {
      threadName = thread.thread.properties.title;
      section = thread.section;
    }
    
    let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+userFastResourceType][''+user.id]);
    sampleAuthor.titles = sampleAuthor.titles.slice(0,2);
    let useName = sampleAuthor.name.slice(0,32);
    if (useName.length !== sampleAuthor.name.length)
      useName+='...';
    const displayContent = htmlToReact.Parser().parse(content);
    
    let hideContent = '';
    let why='';
    if ((isSpoiler && disableSpoilerProtection)
    || (isNSFW && disableNSFWProtection)
    || (isInappropriate && disableInapropriateContentProtection)
    )
    {
      revealContent=true;
    }

    if (isSpoiler && revealContent===false)
    {
      hideContent='hideSpoiler';
      why = lng('Contains Spoilers');
    }
    if (isNSFW && revealContent===false)
    {
      
      hideContent='hideSpoiler';
      why = lng('NSFW Content');
    }
    if (isInappropriate && revealContent===false)
    {
      hideContent='';
      why = lng('Contains Inappropriate Content');
    }

    let userVote = MyAppPool.session.user.votes[''+replyFastResourceType][''+id];
    let userVotePositiveClass = '';
    let userVoteNegativeClass = '';
    if (userVote===1)
      userVotePositiveClass=' activePositiveVote';
    if (userVote===-1)
      userVoteNegativeClass=' activeNegativeVote';

    let showEditIcons = false;
    let shareUrl = encodeURIComponent(getCurrentDomain()+ getForumThreadPage(reply.threadId,threadName,reply.reply.id,"#threadTop"));
    let date = datecreated;
    let showDelete = false;
    if (MyAppPool.currentUser.userid===user.id || MyAppPool.cNeoComu.isModeratorOf(section.id))
    {
      showEditIcons=true;
      showDelete=true;
    }
    
    if (isSet(reply.userPage))
      if (Number(reply.userPage)===Number(MyAppPool.currentUser.userid))
        showDelete=true;

    console.log("REPLYID:",reply.reply.id,reply.userPage,MyAppPool.currentUser.userid,showDelete);
    let remainingReplies = repliesAmount-reply.repliesIds.length;

    return (
      <div id={"c_"+id} className="compthread-comment uk-card uk-card-default">
        
        <div className="thread-header">
          
          <LinkV2 to={getUserRoute(sampleAuthor.id,sampleAuthor.name,"#top")} className="user-area">
          <div className="thread-author">
            <img className="author-avatar" src={getUserAvatar(sampleAuthor.img,sampleAuthor.hash)} alt="Author Avatar" />
            <div className="author-info">
              <span className="author-name" data-uk-tooltip={sampleAuthor.name}>{useName}</span>
              <div className="author-titles">
                {sampleAuthor.titles.map((title, index) => <TitleBadge key={index} tag={title}/>)}</div>
              {showThread && threadTitle!==''?[
              lng("em"),
              <LinkV2 to={getForumThreadPage(threadId,threadTitle,reply.reply.id,"#threadTop")} >{threadTitle}</LinkV2>
              ]:<></>}
              
            </div>
          </div>
          </LinkV2>
        </div>
        
        <div className={"thread-content-section"} 
        onMouseEnter={() => this.setState({ revealHovered: true })}
        onMouseLeave={() => this.setState({ revealHovered: false })}
        onClick={()=>{
          if (isNSFW || isInappropriate || isSpoiler)
            this.setState({ revealContent: true });
        }}
        >
        {((isNSFW || isInappropriate || isSpoiler) && !revealContent)?
          <div className={`borderedText reveal-content-text ${revealHovered ? 'show' : ''}`}>
          {lng("Reveal Content")} ({why})
          </div> :<></>
        } {!isInappropriate || (isInappropriate  && revealContent)?
          
          <div className={"thread-content "+hideContent}>
          {!this.state.editing?
        <p className="postContent" >
            <ThreadContent content={content} />
        </p>
        :
        <div>
          <div className="radio-group-flex">
            <label><input type="checkbox" name="isNSFW" checked={this.state.isNSFW} onChange={this.handleCheckboxChange} /> {lng("NSFW Content")} </label>
            <label><input type="checkbox" name="isInappropriate" checked={this.state.isInappropriate} onChange={this.handleCheckboxChange} /> {lng("Contains Inappropriate Content")} </label>
            <label><input type="checkbox" name="isSpoiler" checked={this.state.isSpoiler} onChange={this.handleCheckboxChange} /> {lng("Contains Spoilers")} </label>
          </div>
        <WYSIWYGWriter id={this.state.editorEditId} hasSendButton={1}
        placeHolder={lng("O que esta pensando ?")}
        actions={
            [
            'superscript',
            'link',
            'image',
            'youtube',
            'emoji',
            'spoiler',
            'insertOrderedList',
            'insertUnorderedList'
            ]} 
            actionClasses={{
                insertOrderedList:'list-ol',
                insertUnorderedList:'list-ul',
                table:'border-all',
                spoiler: 'eye-slash',
                emoji:'grin-squint-tears',
                youtube:'fab fa-youtube'
                }}
            actionTags={{
                link:'<a ',
                spoiler:'<span class="spoiler">'
            }}
            hasCloseButton={1}
            onCloseClick = {this.toggleEditing}
            onSendClick = {this.sendEditContent}
            startContent = {content}
                />
          </div>
        }
          
          </div> : <div style={{"height":"60px"}}></div>}
          
        </div>
        <div className="thread-footer">
          <div className="thread-counters">
          <span className="thread-replies"><span className="fas fa-comments"/> {repliesAmount}  </span>
          <span className="thread-date">{timeSinceString(datecreated * 1000)}</span>
          </div>
          
          </div>

        <div>

        <div className="thread-footer-extras">
            { userVote==-1? <span className="votesText activeNegativeVote">{votes}</span> 
            : userVote==1? <span className="votesText activePositiveVote">{votes}</span> 
            : <span className="votesText">{votes}</span> 
            }
            <span aria-label={"+1"} className={"fas fa-arrow-up clickable arrowButton "+userVotePositiveClass } onClick={()=>MyAppPool.cVotes.sendNeoComuResourceVote(replyFastResourceType,id, 1).then((data)=>{
              delay(500).then(() => {
                MyAppPool.cNeoComu.getReply(id);
              })
            })}/>
            <span aria-label={"-1"} className={"fas fa-arrow-down clickable arrowButton "+userVoteNegativeClass } onClick={()=>MyAppPool.cVotes.sendNeoComuResourceVote(replyFastResourceType,id,-1).then((data)=>{
              delay(500).then(() => {
                MyAppPool.cNeoComu.getReply(id);
              })
            })}/>
            
            <span className="iconButton clickable smalllink">  <span className="fas fa-share"/> {lng("Compartilhar")}. </span>

            <div data-uk-drop="pos: top-center; mode:click" className="uk-card uk-card-default sharePop">
            
            <ButtonsPanel
                buttons={[
                    {tooltip:lng('Compartilhar no Facebook'),ukicon:'facebook', url:"https://www.facebook.com/share.php?u="+ MyAppPool.currentUrlEncoded }
                    ,{text:"X",tooltip:lng('Compartilhar no Twitter'), url:"http://twitter.com/share?related="+getCurrentDomain()+"&via="+getSiteShortName()+"&url="+getCurrentUrlUrlEncoded()+"&text="+getCurrentPageTitleUrlEncoded()+"&hashtags=anime,nani,"+lng('meusanimesbrasili')}
                    ,{tooltip:lng('Compartilhar no Reddit'),ukicon:'reddit', url:"http://reddit.com/submit?url="+getCurrentUrlUrlEncoded()+"&title="+getCurrentPageTitleUrlEncoded()}
                    ,{tooltip:lng('Compartilhar no Tumblr'),ukicon:'tumblr', url:"http://www.tumblr.com/share/link?url="+getCurrentUrlUrlEncoded()+"&name="+getCurrentPageTitleUrlEncoded()}             
                ,{tooltip:lng('Copiar para à Area de Colagem'),ukicon:'link', onClick: (e) => {e.preventDefault(); MyAppPool.cTextInteraction.copyToClipBoard(shareUrl); }}
                ]}/>
            </div>
            <span onClick={()=>this.toggleRespWriter()} className="iconButton clickable smalllink">  <span className="fas fa-reply"/> {lng("Resp")}. </span>
            
            {showEditIcons?
            <span onClick={()=>this.toggleEditing()} className="fas fa-pen iconButton clickable"/>
            :<></>}
            {showDelete?<span onClick={()=>MyAppPool.cModal.set(lng('Deletar'),lng('Tem certeza?'),this.deletePost)} className="fas fa-trash-alt iconButton clickable"/>:<></>}
            
            </div>

            <CSSTransition2
            in={this.state.respWriterVisible}
            timeout={100}
            classNames="write-node"
            unmountOnExit>
              <div className="radio-group-flex">
                <label><input type="checkbox" name="respIsNSFW" checked={this.state.respIsNSFW} onChange={this.handleCheckboxChange} /> {lng("NSFW Content")} </label>
                <label><input type="checkbox" name="respIsInappropriate" checked={this.state.respIsInappropriate} onChange={this.handleCheckboxChange} /> {lng("Contains Inappropriate Content")} </label>
                <label><input type="checkbox" name="respIsSpoiler" checked={this.state.respIsSpoiler} onChange={this.handleCheckboxChange} /> {lng("Contains Spoilers")} </label>
              </div>
              <WYSIWYGWriter id={this.state.editorId} 
              hasSendButton={true}
              placeHolder={lng("O que esta pensando ?")}
              actions={
                  [
                  'superscript',
                  'link',
                  'image',
                  'youtube',
                  'emoji',
                  'spoiler',
                  'insertOrderedList',
                  'insertUnorderedList'
                  ]} 
                  actionClasses={{
                      insertOrderedList:'list-ol',
                      insertUnorderedList:'list-ul',
                      table:'border-all',
                      spoiler: 'eye-slash',
                      emoji:'grin-squint-tears',
                      youtube:'fab fa-youtube'
                      }}
                  actionTags={{
                      link:'<a ',
                      spoiler:'<span class="spoiler">'
                  }}
                  hasCloseButton={1}
                  onCloseClick = {this.toggleRespWriter}
                  onSendClick = {this.sendRespWriterContent}
                  startContent = {this.state.respContent}
                      />
            </CSSTransition2>    

            <div className="subComments" style={{"marginLeft":"10px","paddingLeft":"6px"}}>
                {
                    reply.repliesIds.map((replyId,index)=>
                    {
                        return <ThreadComment pageSize={pageSize} reply={MyAppPool.session.neocomu.replies[replyId]} key={index} />
                    })
                }
            </div>
            {remainingReplies>0?
            <button onClick={this.loadReplies} className="link-button clickable">  <span className="fas fa-plus"/> {"("+lng("Ver mais comentários")+" ("+remainingReplies+"))"}. {fetchingMore?<Spinner/>:<></>} </button>
            :
            <></>
            }
            

          
        </div>
      </div>
    );
  }
}

ThreadComment.propTypes = {
  showThread: PropTypes.bool,
  pageSize:PropTypes.number.isRequired,
  reply: PropTypes.shape({
    reply: PropTypes.shape({
    id: PropTypes.number.isRequired,
    threadId: PropTypes.number.isRequired,
        properties: PropTypes.shape({
            content: PropTypes.string.isRequired,
            datecreated: PropTypes.number.isRequired,
            repliesAmount: PropTypes.number.isRequired,
            views: PropTypes.number.isRequired,
            votes: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
ThreadComment.defaultProps = {
  pageSize:10,
  showThread:false
}

export default ThreadComment;