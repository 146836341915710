import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { lng } from '../ulanguages/language';
import BannerReaction from '../view_component/BannerReaction';
import BreadCumbs from '../view_component/BreadCumbs';
import CapaSection from '../view_component/CapaSection';
import Erro404 from '../view_component/Erro404';
import SkeletonBlock from '../view_component/SkeletonBlock';
import SubNavigation from '../view_component/SubNavigation';
import TagList from '../view_component/TagList';
import TextContentBox from '../view_component/TextContentBox';
import { isMobile } from './../util/mobile';
import { getCurrentUrl, isServer } from './../util/windowUtil';
import CSSTransition2 from './../view_component/CSSTransition2';

import ContentBannerSetter from '../view_component/ContentBannerSetter';

import './forum.css';
import ForumHome from './forum/home';
import { getImgAddress, getRouteParamAtIndex } from '../util/text';
import { getForumCreateThreadPage, getForumHomePage, getForumSearchPath, getForumSectionPath, getForumTagPath, getForumThreadPage } from '../store/AppRoutes';
import ForumSection from './forum/section';
import MyAppPool from '../AppPool';
import { isSet } from '../util/typeu';
import ThreadTag from '../view_component/ThreadTag';
import { toJS } from 'mobx';
import LinkV2 from '../view_component/LinkV2';
import ForumThread from './forum/thread';
import ForumThreadsByTag from './forum/forumThreadsByTag';
import ForumSearch from './forum/forumSearch';


@observer
class Forum extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchQuery:""
    };
  }

  componentDidMount()
  {
    Forum.getInitialValues();
  }
  static async getInitialValues()
  {
    let baseSection = MyAppPool.cNeoComu.getBaseSection();
    let p4 = MyAppPool.cNeoComu.getSection(baseSection);
    return p4;
  }
  componentWillReceiveProps()
  {
    Forum.getInitialValues();
  }

  render(){
    let {searchQuery} = this.state;
    let meta = {title:lng('Forum')+' | NossoAnime',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    let url = getCurrentUrl();
    let sectionArgForThreadCreation="";
    if (url.indexOf("section")!==-1)
    {
      let sectionId = getRouteParamAtIndex(url,3);
      sectionArgForThreadCreation = "?section="+sectionId;
    }
    let section = MyAppPool.cNeoComu.getBaseSection();
    let sectionData = MyAppPool.session.neocomu.sections[section];
    if (!isSet(sectionData)){
      sectionData = {
        subsections:[],
        admins: [],
        mods: [],
        owner: 0
      };
    };
    let baseName = lng('Forum');
    if (isSet(sectionData.properties))
       baseName = sectionData.properties.name;
    
    
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div id="threadTop" className="contentpage">
            <div  className=' contentArea'>
            <ContentBannerSetter />
            <div className="threadsSection">
            <LinkV2 to={getForumCreateThreadPage(sectionArgForThreadCreation,"#top")} className="create-thread-button">{lng("Criar uma Thread")}</LinkV2>
            <div className="searchBoxForum">
              <input type="text" className="searchForum" placeholder={lng("Pesquisar")} value={searchQuery} 
              onKeyPress={(evt)=>{
                if (evt.key === 'Enter' && searchQuery.length>0) {
                  MyAppPool.cLocation.updateUrl(getForumSearchPath(searchQuery,"#threadTop"));
                }
              }}
              onChange={(evt)=> this.setState({searchQuery:evt.target.value})
                }/>
                <LinkV2 to={searchQuery.length>0?getForumSearchPath(searchQuery,"#threadTop"):""} className="uk-button uk-button-primary searchSendForum"><i className="fas fa-search"></i></LinkV2>
            </div>
            <div style={{marginBottom:"4px"}}>
            <ThreadTag backgroundColor={"#33ccff"} name={baseName} url={getForumHomePage("#threadTop")}/>
              {sectionData.subsections.map((elemId,index)=>{
                return <ThreadTag backgroundColor={"#33ccff"} name={elemId.properties.name} url={getForumSectionPath(elemId.id,elemId.properties.name,"#threadTop")}/>
              })}
            </div>
            </div>

            <Route render={({ location }) => {
            const { pathname, key } = location;
            return (
              <div style={{position:'relative'}}>
                <CSSTransition2
                  key={key}
                  classNames="switch-node"
                  timeout={{enter: 750, exit: 0}}
                >
                  <div>
                  

                  <Switch>
                    <Route path={getForumHomePage("")} exact={true}>
                      <ForumHome/>
                    </Route>
                    <Route path={getForumThreadPage(":tid",":tname","","")}>
                      <ForumThread/>
                    </Route>
                    <Route path={getForumSearchPath("")}>
                      <ForumSearch/>
                    </Route>
                    <Route path={getForumSectionPath(":sid",":name","")}>
                      <ForumSection/>
                    </Route>  
                    <Route path={getForumTagPath(":sid",":name","")}>
                      <ForumThreadsByTag/>
                    </Route>                   
                    <Route path={'/forum/home'}>
                      <ForumHome/>
                    </Route>
                    <Route path="*" render={() => <Erro404 />} />
                  </Switch>
                  </div>
                </CSSTransition2>
              </div>
          )
        }}/>
            </div>
          </div>
        </div>
      );
}

}

export default Forum;
